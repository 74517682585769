/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { connectAutoDispatch } from "redux/reduxConnect";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { withStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";
import { apiGetUserData } from "api/user";
import { userEditMemberRequest } from "redux/actions/UserActions";
import { ModalConfirm } from "components/forms";
import shallowEqual from "utils/shallowEqual";
import { withRouter } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

const styles = theme => ({
  inner: {
    [theme.breakpoints.down(1000)]: {
      maxWidth: "50%",
      flexBasic: "unset"
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: "80%",
      flexBasic: "unset"
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: "100%",
      padding: theme.spacing(2),
      flexBasic: "unset"
    }
  },
  inputField: {
    margin: "8px 0px"
  },
  buttonCancel: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none"
    }
  },
  buttonSave: {
    backgroundColor: theme.palette.colorLogoPrimary,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.colorLogoPrimary,
      color: "white"
    }
  }
});

class EditMemberContainer extends React.Component {
  constructor(props) {
    super(props);

    const { params } = this.props.match || {};
    const extension = params && params.extension ? params.extension.toUpperCase() : "AZINSIGHT";
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      repassword: "",
      disabledCalculatorSettings: false,
      accessHistory: false,
      accessGoogleAddon: false,
      accessProducts: false,
      accessSeller: false,
      id: "",
      openConfirm: false,
      extension,
      errors: {},
      showPassword: false,
      showRepassword: false
    };
  }

  componentDidMount() {
    // window.analytics.page("ModifyMember");
    const { id = null } = this.props.match.params;
    if (!id) {
      return this.handleCancel();
    }
    apiGetUserData({ id })
      .then(
        ({
          firstName,
          lastName,
          email,
          _id,
          disabledCalculatorSettings,
          accessHistory,
          accessGoogleAddon,
          accessProducts,
          accessSeller
        }) =>
          this.setState({
            id: _id,
            firstName,
            lastName,
            email,
            _id,
            disabledCalculatorSettings,
            accessHistory,
            accessGoogleAddon,
            accessProducts,
            accessSeller
          })
      )
      .catch(error =>
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          autoHideDuration: 9000
        })
      );
  }

  handleChangeInput = keyChange => e => {
    this.setState({ [keyChange]: e.target.value }, this._comparePassword);
  };

  _comparePassword = () => {
    const { password, repassword } = this.state;
    if (password && repassword && repassword !== password) {
      this.setState({ errors: { repassword: "Password and repassword don't match" } });
    } else {
      this.setState({ errors: {} });
    }
  };

  handleSubmit = () => {
    if (this.props.callingEditMember) return;
    this.setState({ openConfirm: false });
    const {
      firstName,
      lastName,
      email,
      password,
      repassword,
      disabledCalculatorSettings,
      accessHistory,
      accessGoogleAddon,
      accessProducts,
      accessSeller,
      id,
      extension
    } = this.state;
    this.props.userEditMemberRequest({
      firstName,
      lastName,
      email,
      password,
      repassword,
      disabledCalculatorSettings: extension === "AZINSIGHT" ? disabledCalculatorSettings : false,
      accessHistory: extension === "AZINSIGHT" ? accessHistory : false,
      accessGoogleAddon: extension === "AZINSIGHT" ? accessGoogleAddon : false,
      accessProducts: extension === "AZINSIGHT" ? accessProducts : false,
      accessSeller: extension === "AZINSIGHT" ? accessSeller : false,
      id
    });
  };

  handleCancel = () => {
    this.props.history.push(`/asin-zen-insight/account-management?tab=${this.state.extension}`);
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.editMemberSuccess &&
      this.props.editMemberSuccess !== prevProps.editMemberSuccess
    ) {
      enqueueSnackbar("Member information saved", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
      this.handleCancel();
    }

    const { errorAddMember } = this.props;
    if (
      !shallowEqual(prevProps.errorAddMember, this.props.errorAddMember) &&
      ["EXCEPTION"].indexOf(errorAddMember.code) > -1
    ) {
      enqueueSnackbar("Has error when add new member, contact with administrator please", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 9000
      });
    }
  }

  handleCheckboxChange = (e, key) => {
    const { checked } = e.target;
    this.setState({
      [key]: checked
    });
  };

  handleOpenConfirm = () => {
    this.setState(prevState => ({ openConfirm: !prevState.openConfirm }));
  };

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  toggleRepassword = () => {
    const { showRepassword } = this.state;
    this.setState({
      showRepassword: !showRepassword
    });
  };

  render() {
    const {
      extension,
      firstName,
      lastName,
      email,
      password,
      repassword,
      disabledCalculatorSettings,
      accessHistory,
      accessGoogleAddon,
      accessProducts,
      accessSeller,
      errors
    } = this.state;
    const {
      classes,
      errorAddMember: { message }
    } = this.props;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item md={4} className={classes.inner}>
          <Card>
            <CardContent>
              <Typography component="h2" variant="h5">
                Edit Member Information
              </Typography>

              <TextField
                error={!!message.firstName}
                helperText={message.firstName || ""}
                className={classes.inputField}
                fullWidth
                label="First name"
                value={firstName}
                onChange={this.handleChangeInput("firstName")}
              />

              <TextField
                error={!!message.lastName}
                helperText={message.lastName || ""}
                className={classes.inputField}
                fullWidth
                label="Last name"
                value={lastName}
                onChange={this.handleChangeInput("lastName")}
              />

              <TextField
                disabled
                className={classes.inputField}
                fullWidth
                label="Email"
                type="email"
                value={email}
              />

              <TextField
                error={!!message.password}
                helperText={message.password || ""}
                className={classes.inputField}
                fullWidth
                label="Password"
                type={this.state.showPassword ? "text" : "password"}
                value={password}
                onChange={this.handleChangeInput("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={this.togglePassword}>
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <TextField
                error={!!(message.repassword || errors.repassword)}
                helperText={message.repassword || errors.repassword}
                className={classes.inputField}
                fullWidth
                label="Confirm password"
                type={this.state.showRepassword ? "text" : "password"}
                value={repassword}
                onChange={this.handleChangeInput("repassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={this.toggleRepassword}>
                        {this.state.showRepassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <Typography style={{ fontStyle: "italic" }}>
                If you don’t enter a new password and click save the password will remain the same
              </Typography>
              {extension === "AZINSIGHT" ? (
                <>
                  <FormControlLabel
                    checked={disabledCalculatorSettings}
                    control={
                      <Checkbox
                        onChange={e => this.handleCheckboxChange(e, "disabledCalculatorSettings")}
                        className="checkbox"
                        value={disabledCalculatorSettings}
                        color="primary"
                      />
                    }
                    label="Disable the Calculator Settings menu for this sub-account."
                  />
                  <FormControlLabel
                    checked={accessHistory}
                    control={
                      <Checkbox
                        onChange={e => this.handleCheckboxChange(e, "accessHistory")}
                        className="checkbox"
                        value={accessHistory}
                        color="primary"
                      />
                    }
                    label="Grant permission to access the History for this sub-account."
                  />
                  <FormControlLabel
                    checked={accessGoogleAddon}
                    control={
                      <Checkbox
                        onChange={e => this.handleCheckboxChange(e, "accessGoogleAddon")}
                        className="checkbox"
                        value={accessGoogleAddon}
                        color="primary"
                      />
                    }
                    label="Grant permission to access the Google Add-on for this sub-account."
                  />
                  {/* <FormControlLabel
                    checked={accessProducts}
                    control={
                      <Checkbox
                        onChange={e => this.handleCheckboxChange(e, "accessProducts")}
                        className="checkbox"
                        value={accessProducts}
                        color="primary"
                      />
                    }
                    label="Grant permission to access Products for this sub-account."
                  />
                  <FormControlLabel
                    checked={accessSeller}
                    control={
                      <Checkbox
                        onChange={e => this.handleCheckboxChange(e, "accessSeller")}
                        className="checkbox"
                        value={accessSeller}
                        color="primary"
                      />
                    }
                    label="Grant permission to access Seller for this sub-account."
                  /> */}
                </>
              ) : null}
            </CardContent>
            <CardActions style={{ justifyContent: "flex-end" }}>
              <Button
                onClick={this.handleCancel}
                variant="contained"
                className={classes.buttonCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={this.handleOpenConfirm}
                variant="contained"
                className={classes.buttonSave}
              >
                Save & Close
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <ModalConfirm
          open={this.state.openConfirm}
          title="Confirm update member information"
          onClose={this.handleOpenConfirm}
          onConfirm={this.handleSubmit}
          Content={() => <div>Do you want to update member information ?</div>}
        />
      </Grid>
    );
  }
}

EditMemberContainer.defaultProps = {
  callingEditMember: false,
  editMemberSuccess: false,
  errorAddMember: {
    code: "",
    message: {}
  },
  userEditMemberRequest: null
};

EditMemberContainer.propTypes = {
  callingEditMember: PropTypes.bool,
  editMemberSuccess: PropTypes.bool,
  errorAddMember: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.shape({})
  }),
  userEditMemberRequest: PropTypes.func
};

export default withRouter(
  connectAutoDispatch(
    ({ userState }) => ({
      callingEditMember: userState.callingEditMember,
      editMemberSuccess: userState.editMemberSuccess,
      errorAddMember: userState.errorAddMember
    }),
    { userEditMemberRequest }
  )(withStyles(styles)(EditMemberContainer))
);
