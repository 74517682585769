import React, { PureComponent } from "react";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

export default class SettingsCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StyledCard>
        <CardHeader
          title={this.props.title}
          subheader={this.props.subheader}
          action={this.props.action}
        />
        <Divider />
        <CardContent>{this.props.children}</CardContent>
      </StyledCard>
    );
  }
}
