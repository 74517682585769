import React from "react";
import HelpIcon from "@mui/icons-material/HelpOutline";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import RemoveIcon from "@mui/icons-material/DeleteForever";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

export const styles = theme => {
  return {
    modalConfirmSelect: {
      width: 450,
      [theme.breakpoints.down(700)]: {
        width: "100%",
        padding: theme.spacing(1)
      }
    },
    buttonActive: {
      color: theme.palette.colors.blue
    },
    buttonUnActive: {
      color: theme.palette.colors.red
    },
    root: {
      width: "100%",
      marginTop: theme.spacing(3)
    },
    table: {
      minWidth: 1020
    },
    tableWrapper: {
      overflowX: "auto"
    },
    btnDelete: {
      color: theme.palette.colors.red,
      cursor: "pointer"
    }
  };
};

const rows = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sort: true
  },
  {
    id: "disabledCalculatorSettings",
    numeric: false,
    disablePadding: true,
    label: "Disable Calculator Setting",
    tabValue: "AZINSIGHT",
    sort: false,
    helper:
      "When you check in the box to disable calculator settings, the sub-account will inherit and mirror the calculator settings from the main account"
  },
  {
    id: "accessHistory",
    numeric: false,
    disablePadding: true,
    label: "Access History",
    tabValue: "AZINSIGHT",
    sort: false,
    helper: "Allow your team member to access the web portal history"
  },
  {
    id: "accessGoogleAddon",
    numeric: false,
    disablePadding: true,
    label: "Access Google addon",
    tabValue: "AZINSIGHT",
    sort: false,
    helper:
      "Allow your team member to access Google Addon. Note: The main account needs to log into the Google add-on first so that sub-accounts are able to login."
  },
  // {
  //   id: "accessProducts",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Access Products",
  //   tabValue: "AZINSIGHT",
  //   sort: false,
  //   helper: "Allow your team member to access to Products"
  // },
  // {
  //   id: "accessSeller",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Access Seller",
  //   tabValue: "AZINSIGHT",
  //   sort: false,
  //   helper: "Allow your team member to access to Seller"
  // },
  {
    id: "status",
    label: "Status",
    tabValue: "AZINSIGHT",
    disablePadding: true,
    sort: true,
    helper:
      "Sub-accounts become inactive when a sub-account plan expires or the number of sub-accounts is decreased on the plan. Once an account becomes inactive it can no longer log in."
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date created",
    sort: true
  },
  {
    id: "",
    numeric: false,
    disablePadding: true,
    label: "Actions",
    sort: false
  }
];

export class ListMemberMobile extends React.Component {
  renderRecord = id => {
    const {
      record,
      editMember,
      selectMarketplace,
      handleGetUserData,
      handleDeleteMember,
      sendEmailInstructions,
      tabValue,
      classes
    } = this.props;
    let content = "";
    switch (id) {
      case "fullName":
        content = `${record.firstName} ${record.lastName}`;
        break;
      case "email":
        content = record.email;
        break;
      case "disabledCalculatorSettings":
      case "accessHistory":
      case "accessGoogleAddon":
      case "accessProducts":
      case "accessSeller":
        content =
          tabValue === "AZINSIGHT" ? (
            <FormControlLabel
              checked={record[id]}
              control={
                <Checkbox
                  onChange={e => editMember(e, record)}
                  className={classes.checkbox}
                  value={record[id]}
                  color="primary"
                />
              }
              label=""
            />
          ) : null;
        break;
      case "status":
        content =
          tabValue === "AZINSIGHT" ? (
            <span style={{ textTransform: "capitalize" }}>{record.status}</span>
          ) : null;
        break;
      case "createdAt":
        content = record.createdAt ? moment(record.createdAt).format("ll") : "";
        break;
      case "":
        content = (
          <>
            {tabValue === "AZINSIGHT" ? (
              <Tooltip title="Clone the Calculator Settings from the main account to this account">
                <ShoppingBasketIcon
                  onClick={() => selectMarketplace(record)}
                  style={{ cursor: "pointer", marginRight: "5px" }}
                />
              </Tooltip>
            ) : null}
            <EditIcon
              onClick={handleGetUserData(record)}
              style={{ cursor: "pointer", marginRight: "5px" }}
            />
            <RemoveIcon
              onClick={handleDeleteMember(record)}
              style={{ cursor: "pointer", marginRight: "5px", color: "red" }}
              className={classes.btnDelete}
            />
            {tabValue === "AZINSIGHT" ? (
              <MailOutlineIcon
                onClick={() => sendEmailInstructions(record)}
                style={{ cursor: "pointer", marginRight: "5px" }}
              />
            ) : null}
          </>
        );
        break;
      default:
        "";
    }
    return content;
  };

  render() {
    const { tabValue, classes, index } = this.props;

    return (
      <Grid className={classes.listMemberMobileInner}>
        <strong className={classes.rowMobile} key="index">
          # {index + 1}
        </strong>
        {rows
          .filter(r => !r.tabValue || r.tabValue === tabValue)
          .map(
            row => (
              <div className={classes.rowMobile} key={row.id}>
                <strong>{row.label} </strong>
                {row.id === "disabledCalculatorSettings" || row.id === "status" ? (
                  <Tooltip
                    title={
                      row.id === "disabledCalculatorSettings"
                        ? "When you check in the box to disable calculator settings, the sub-account will inherit and mirror the calculator settings from the main account"
                        : row.id === "status"
                        ? "Sub-accounts become inactive when a sub-account plan expires or the number of sub-accounts is decreased on the plan. Once an account becomes inactive it can no longer log in."
                        : null
                    }
                    placement="right"
                    enterDelay={300}
                  >
                    <HelpIcon style={{ fontSize: 16 }} />
                  </Tooltip>
                ) : null}
                <span>: {this.renderRecord(row.id)}</span>
              </div>
            ),
            this
          )}
      </Grid>
    );
  }
}
