/*eslint-disable*/
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import {
  userSignupRequest,
  userUpdateMwsRequest,
  userUpdateInfoWithoutMwsRequest
} from "redux/actions/UserActions";

import { getProductPlanRequest } from "redux/actions/PlanActions";
import { userInitState } from "redux/reducers/UserReducer";
import { connectAutoDispatch } from "redux/reduxConnect";
import queryString from "query-string";
import isEmail from "validator/lib/isEmail";
import { checkExtensionInstalled, checkHAZExtensionInstalled, isMyScriptLoaded } from "utils";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconEmail from "@mui/icons-material/Email";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ImageZoom from "react-medium-image-zoom";
import ImageFull1 from "static/img/1full-min.png";
import ImageFull2 from "static/img/2full-min.png";
import ImageFull3 from "static/img/3full-min.png";
import ImageFullUK1 from "static/img/mwsprocessukpic1.png";
import ImageFullUK2 from "static/img/mwsprocessukpic2.png";
import ImageFullUK3 from "static/img/mwsprocessukpic3.png";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import { SettingsCard } from "components";
import Asinzenmainlogo from "static/img/asinzen2019.png";
import { UserStyles, UlStyled } from "./User.styled";
import PlansUI from "components/plans/PlansUI";
import constants from "utils/constants";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import AlertDialogSlide from "components/AlertDialogSlide";

const arrayTitleStepsDef = ["Your Account Details were recieved"];
const arrayMwsManual = [
  "Add asinzen as a developer on Amazon Seller Central to generate an MWS Token required for the signup process",
  `<span>
    Download the Bulk Scan Pro Desktop App or access the Browser app by
    <a target="_blank" href="https://get.bulkscanpro.com/getapp">
      clicking here.
    </a>
  </span>`
];

const MarketplaceImages = {
  US: {
    image1: ImageFull1,
    image2: ImageFull2,
    image3: ImageFull3
  },
  UK: {
    image1: ImageFullUK1,
    image2: ImageFullUK2,
    image3: ImageFullUK3
  }
};

class SignUpContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      contactId,
      inf_contact_key,
      inf_custom_plan,
      inf_field_Email,
      inf_field_FirstName,
      inf_field_LastName,
      inf_field_Password,
      is_force_to_insight,
      user_haz_type,
      error
    } = queryString.parse((location.search || "?").substring(1));
    const isOpenPassword = inf_field_Password === undefined && inf_field_LastName === undefined;
    let errorOnStep1 = null;
    let userMsg = null;

    if (error === "DUPLICATE") {
      errorOnStep1 =
        "A sub-account with this email address already exists. If you would like to create a main account. Please go back to the";
    }

    if (error === "EMAIL_DISPOSABLE") {
      errorOnStep1 =
        "Sorry but we do not accept registrations from disposable or invalid email addresses. Please go back to the";
    }

    this.state = {
      firstName: this.handleTrimTxt(inf_field_FirstName || ""),
      lastName: this.handleTrimTxt(inf_field_LastName || ""),
      email: this.handleTrimTxt(inf_field_Email || ""),
      ownerEmail: "",
      contactId: this.handleTrimTxt(contactId || ""),
      contactKey: this.handleTrimTxt(inf_contact_key || ""),
      plan: this.handleTrimTxt(inf_custom_plan || ""),
      planURL: null,
      password: this.handleTrimTxt(inf_field_Password || ""),
      confirmPassword: this.handleTrimTxt(inf_field_Password || ""),
      upgradeToInsight: is_force_to_insight === "true",
      userHazType: user_haz_type || null,
      isOpenPassword,
      sellerId: "",
      mwsAuthToken: "",
      marketplace: "US",
      showPassword: false,
      isBtnClick: false,

      isOpenSnack: false,
      verticalSnack: "top",
      horizontalSnack: "right",
      messageSnack: "",
      isOnlyScoutView: false,
      isOpenDialogNotSigninSeller: false,
      errorOnStep1,
      errors: {},
      userMsg: "",
      activeStep: null,
      isNextButtonDisabled: true,
      isExistedUser: false,
      wayAddMws: "manualGuide",
      arrayTitleSteps: [...arrayTitleStepsDef, ...arrayMwsManual],
      showPopup: false,
      showPlans: false,
      showType: false,
      confirmShowType: false,
      typeRegister: "",
      isCheckConfirm: false,
      error
    };
  }

  async componentDidMount() {
    // window.analytics.page("SignUp");
    const scripts = [
      "https://fast.wistia.com/assets/external/E-v1.js",
      "https://fast.wistia.com/embed/medias/fbvm4bb56s.jsonp"
    ];
    scripts.forEach(src => {
      if (!isMyScriptLoaded(src)) {
        const script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        script.async = true;
        document.body.appendChild(script);
      }
    });

    const {
      email,
      contactId,
      firstName,
      lastName,
      password,
      contactKey,
      isOpenPassword,
      upgradeToInsight,
      plan
    } = this.state;
    const isInstalledExt = await checkExtensionInstalled();
    const isHazInstallExt = await checkHAZExtensionInstalled();
    this.setState({ isInstalledExt, isHazInstallExt });

    this.props.userSignupRequest({
      email,
      contactId,
      firstName,
      lastName,
      password,
      contactKey,
      upgradeToInsight
    });
  }

  componentDidUpdate(prevProps) {
    const { userState } = this.props;

    if (
      prevProps.userState.callingSignUp &&
      !userState.callingSignUp &&
      !userState.errorMessage.code
    ) {
      const {
        sellerId,
        mwsAuthToken,
        isCanceledAccount,
        banAllAccess,
        isExistedInHaz,
        plansURL,
        lastName,
        productType
      } = userState.userInfo;
      const { isInstalledExt, isHazInstallExt, isOpenPassword } = this.state;
      if (isOpenPassword && !lastName) {
        this.setState({ activeStep: 0, isNextButtonDisabled: false });
      } else if (!banAllAccess && !isCanceledAccount && !sellerId && !mwsAuthToken) {
        this.setState({ activeStep: 1, isNextButtonDisabled: false, isExistedUser: true });
      } else if (sellerId && mwsAuthToken && banAllAccess === true && isCanceledAccount === true) {
        this.setState({ isNextButtonDisabled: true });
        window.open("https://goto.asinzen.com/marketplace", "_self");
      } else if (sellerId && mwsAuthToken && banAllAccess === false) {
        // if (productType === null) {
        //   return this.setState({ showPlans: true });
        // }
        let strExisted =
          "Opps looks like this email was already used to sign up for an account. Are you trying to sign up for another FREE trial? Please contact support for assistance.";
        if (isExistedInHaz && isOpenPassword === true) {
          strExisted = `Your email address already exists.`;
          if (isHazInstallExt) {
            strExisted = strExisted + " Please remove HAZability extension.";
          }
          if (!isInstalledExt) {
            strExisted =
              strExisted +
              (isHazInstallExt ? " And then" : " Let") +
              " install AZInsight extension.";
          }
          if (isInstalledExt && !isHazInstallExt) {
            strExisted = strExisted + " Let sign in AZInsight extension.";
          }
        }
        this.setState({ userMsg: strExisted });
      } else if (banAllAccess === true) {
        // if (productType === null) {
        //   return this.setState({ showPlans: true });
        // }
        this.setState({
          userMsg:
            "Look like this email address has already been signed up and completed the 10 days Free Trial.",
          plansURL
        });
      }
    }

    //process request step 0
    if (this.state.activeStep === 0) {
      this._processRequestStep0(prevProps);
    }

    //process request step 1
    if (this.state.activeStep === 1) {
      this._processRequestStep1(prevProps);
    }
  }

  _processRequestStep0 = prevProps => {
    const { userState } = this.props;
    if (
      prevProps.userState.callingUpdateInfoWithoutMWS &&
      !userState.callingUpdateInfoWithoutMWS &&
      userState.errorMessage.code
    ) {
      return this.setState({
        errorOnStep1: userState.errorMessage.message,
        errors: userState.errorMessage.errors,
        isNextButtonDisabled: false
      });
    }

    if (prevProps.userState.callingUpdateInfoWithoutMWS && !userState.callingUpdateInfoWithoutMWS) {
      this.setState({ activeStep: 1, isNextButtonDisabled: false, userMsg: "" });
    }
  };

  _processRequestStep1 = prevProps => {
    const { userState } = this.props;
    if (
      prevProps.userState.callingUpdateMws &&
      !userState.callingUpdateMws &&
      userState.errorMessage.code
    ) {
      const invalidMWS = (
        <>
          <div style={{ maringTop: 10, marginBottom: 10 }}>
            {userState.errorMessage.code === "INVALID" && (
              <>
                Error: 109 - Please verify you have entered your Amazon SellerID and MWS Auth Token
                properly. If you continue experiencing issues please{" "}
                <span
                  style={{ cursor: "pointer", "text-decoration": "underline" }}
                  onClick={this.showChat}
                >
                  click here
                </span>{" "}
                to chat with a customer champion.
              </>
            )}
            {userState.errorMessage.code === "INVALID_DUPLICATE" && (
              <>
                Error: 110 - Your Amazon seller account has already linked with another AZInsight
                account {userState.userInfo.email}. If you forgot your password, click{" "}
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() =>
                    window.open(
                      "https://support.asinzen.com/portal/en/kb/articles/how-do-i-change-or-reset-my-password",
                      "_blank"
                    )
                  }
                >
                  here
                </span>{" "}
                to learn how to reset it. If you have any other issues please click{" "}
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => window.open("https://asinzen.com/contactus", "_blank")}
                >
                  here
                </span>{" "}
                to chat with a Customer Champion.
              </>
            )}
          </div>
        </>
      );
      return this.setState({
        userMsg: invalidMWS,
        isNextButtonDisabled: false
      });
    }

    if (prevProps.userState.callingUpdateMws && !userState.callingUpdateMws) {
      this.setState({ activeStep: 2, isNextButtonDisabled: false, userMsg: "" });
    }
  };

  handleTrimTxt = txt => {
    if (!txt || typeof txt !== "string") return "";
    return (txt && txt.trim && txt.trim()) || "";
  };

  handleChange = prop => event => {
    const { errors } = this.state;
    this.setState({ [prop]: event.target.value });
    if (event.target.value && errors[prop]) {
      delete errors[prop];
      this.setState({ errors: { ...errors } });
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  validateForm() {
    const { firstName, lastName, email, password, confirmPassword } = this.state;

    const errors = {};

    if (!firstName) {
      errors.firstName = "Please enter a first name!";
    }

    if (!lastName) {
      errors.lastName = "Please enter a last name!";
    }

    if (!email) {
      errors.email = "Please enter an email address!";
    }

    if (!isEmail(email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!password) {
      errors.password = "Please enter your password";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please re-enter your re-password";
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match try again. Try again.";
    }

    return errors;
  }

  handleShowSnackBar = reason => {
    this.setState({
      isBtnClick: false,
      isOpenSnack: true,
      verticalSnack: "top",
      horizontalSnack: "right",
      messageSnack: reason
    });
  };

  handleCheckExtendsion = async () => {
    if (await checkHAZExtensionInstalled()) {
      this.handleShowSnackBar(`Please remove HAZability extension to continue the next step.`);
      return false;
    } else {
      if (!(await checkExtensionInstalled())) {
        this.handleShowSnackBar(`Please install AZInsight extension to continue the next step.`);
        return false;
      } else if (this.state.wayAddMws !== "manualGuide" || this.state.wayAddMws !== "ezGuide") {
        return this.setState(state => ({
          activeStep: state.activeStep + 1
        }));
      }
    }
    return true;
  };

  handleStopSnackBar = () => {
    this.setState({ isOpenSnack: false });
  };

  handleNext = async () => {
    let {
      activeStep,
      email,
      ownerEmail,
      firstName,
      lastName,
      contactId,
      contactKey,
      plan,
      password,
      upgradeToInsight,
      userHazType,
      sellerId,
      mwsAuthToken,
      marketplace,
      // errorOnStep1,
      isInstalledExt,
      wayAddMws,
      confirmPassword
    } = this.state;
    let errors = {};
    if (activeStep === 0) {
      this.setState({ isNextButtonDisabled: true });
      this.props.userUpdateInfoWithoutMwsRequest({
        email,
        firstName,
        lastName,
        contactId,
        contactKey,
        password,
        confirmPassword
      });
    } else if (activeStep === 1 && wayAddMws === "manualGuide") {
      this.setState({ isNextButtonDisabled: true });
      this.props.userUpdateMwsRequest({ sellerId, mwsAuthToken, marketplace });
    } else if (activeStep === 2 && wayAddMws === "siblingAccount") {
      this.setState({ isNextButtonDisabled: true });
      if (!ownerEmail) {
        errors.ownerEmail = "Please enter an sibling email address!";
      }
      if (!ValidateEmail(ownerEmail)) {
        errors.ownerEmail = "Please enter an sibling email valid email address!";
      }
      if (Object.keys(errors).length > 0) {
        return this.setState({ errors, isNextButtonDisabled: false });
      }
    } else if (activeStep === 3 && wayAddMws === "siblingAccount") {
      return this.handleCheckExtendsion();
    } else {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  getSteps = () => {
    return this.state.arrayTitleSteps;
  };

  getStepContent = step => {
    const { classes } = this.props;
    const {
      errorOnStep1,
      contactId,
      email,
      isOpenPassword,
      wayAddMws,
      activeStep,
      userMsg
    } = this.state;
    const arrStepTwo = [
      // <p key={"p" + 1}>
      //   {`Download and install the chrome extension "`}
      //   <a
      //     target={`_blank`}
      //     rel="noopener noreferrer"
      //     href={`${process.env.CHROME_EXTENSION_DOWNLOAD_LINK}`}
      //   >
      //     <b>here</b>
      //   </a>
      //   {`" to continue`}
      // </p>
    ];
    const urlForToSellerCentral = `${process.env[`ASIN_ZEN_MWS_URL_${this.state.marketplace}`]
      }gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=${process.env[`ASIN_ZEN_MWS_INFO_TYPE_${this.state.marketplace}`]
      }&devMWSAccountId=${process.env[`ASIN_ZEN_MWS_INFO_PHONE_${this.state.marketplace}`]}`;
    switch (activeStep || step) {
      /** last and first name **/
      case 0:
        return (
          <div style={{ width: "90%" }}>
            {/* <Grid container spacing={10}>
              <Grid item xs={12} sm={6}>
                <div>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    error={!!this.state.errors.firstName}
                  >
                    <InputLabel htmlFor="First Name">First Name</InputLabel>
                    <Input
                      id="firstName"
                      placeholder="First Name"
                      value={this.state.firstName}
                      onChange={this.handleChange("firstName")}
                      error={!!this.state.errors.firstName}
                      disabled={true}
                    />
                    <FormHelperText>{this.state.errors.firstName || ""}</FormHelperText>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    error={!!this.state.errors.lastName}
                  >
                    <InputLabel htmlFor="Last Name">Last Name</InputLabel>
                    <Input
                      id="lastName"
                      placeholder="First Name"
                      value={this.state.lastName}
                      onChange={this.handleChange("lastName")}
                      error={!!this.state.errors.lastName}
                      disabled={isOpenPassword === true}
                    />
                    <FormHelperText>{this.state.errors.lastName || ""}</FormHelperText>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <br />
            <FormControl
              fullWidth
              className={classes.formControl}
              error={!!this.state.errors.email}
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                placeholder="Email Address"
                value={this.state.email}
                onChange={this.handleChange("email")}
                error={!!this.state.errors.email}
                startAdornment={
                  <InputAdornment position="start">
                    <IconEmail className={classes.emailIcon} />
                  </InputAdornment>
                }
                disabled={true}
              />
              <FormHelperText>{this.state.errors.email || ""}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              error={!!this.state.errors.password}
              className={classes.formControl}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                placeholder="Your Password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                error={!!this.state.errors.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPasssword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                disabled={isOpenPassword === true ? false : true}
              />
              <FormHelperText>{this.state.errors.password || ""}</FormHelperText>
            </FormControl>
            <br />
            <FormControl
              fullWidth
              error={!!this.state.errors.confirmPassword}
              className={classes.formControl}
            >
              <InputLabel htmlFor="Confirm Password">Confirm Password</InputLabel>
              <Input
                id="confirmPassword"
                placeholder="Your Confirm Password"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.confirmPassword}
                onChange={this.handleChange("confirmPassword")}
                error={!!this.state.errors.confirmPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPasssword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                disabled={isOpenPassword === true ? false : true}
              />
              <FormHelperText>{this.state.errors.confirmPassword || ""}</FormHelperText>
            </FormControl> */}
            {errorOnStep1 ? (
              <p style={{ color: "red" }}>
                {errorOnStep1}{" "}
                <span>
                  <a href={`https://goto.asinzen.com/azinsightsignup`}> Sign Up form</a> and create
                  an account using a valid email.
                </span>
              </p>
            ) : (
              ""
            )}
          </div>
        );
    }
    if (wayAddMws === "ezGuide") {
      switch (activeStep) {
        case 2:
          return arrStepTwo;
        case 3:
          return (
            <div style={{ width: "70%" }}>
              <p>
                {`We've made it easy for you provide your Amazon MWS permissions. `}
                To launch our EZ-Guide: {` `}
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  style={{ backgroundColor: "#ed9b00" }}
                >
                  <a
                    target={"_blank"}
                    href={`${urlForToSellerCentral}&email=${email}&contactId=${contactId}&url=${location}`}
                    style={{ fontSize: 20, textDecoration: "none" }}
                  >
                    Click here
                  </a>
                </Button>
              </p>
            </div>
          );
      }
    } else if (wayAddMws === "siblingAccount") {
      switch (activeStep) {
        case 2:
          return (
            <div style={{ width: "100%" }}>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    error={!!this.state.errors.ownerEmail}
                  >
                    <InputLabel htmlFor="owneremail">Email Address</InputLabel>
                    <Input
                      id="owneremail"
                      placeholder="Email Address"
                      value={this.state.ownerEmail}
                      onChange={this.handleChange("ownerEmail")}
                      error={!!this.state.errors.ownerEmail}
                    />
                    <FormHelperText>{this.state.errors.ownerEmail || ""}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          );
        case 3:
          return arrStepTwo;
      }
    } else {
      switch (activeStep) {
        case 1:
          return (
            <div style={{ width: "100%" }}>
              <UlStyled>
                <li>
                  <div style={{ width: "100%" }}>
                    <Grid container alignItems="center" spacing={0}>
                      <Grid item xs={12} sm={3}>
                        Select Marketplace
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          error={!!this.state.errors.marketplace}
                        >
                          <Select
                            native
                            value={this.state.marketplace}
                            onChange={this.handleChange("marketplace")}
                            inputProps={{
                              name: "age",
                              id: "age-native-simple"
                            }}
                          >
                            <option value="US">US/ Canada/ Mexico</option>
                            <option value="UK">UK/ Germany/ France/ Italy/ Spain</option>
                          </Select>
                          <FormHelperText>{this.state.errors.marketplace || ""}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </li>
                <li>
                  <p>
                    Click
                    <a target={"_blank"} href={urlForToSellerCentral}>
                      <b> HERE </b>
                    </a>
                    to open Amazon Marketplace Web Service page.{" "}
                    <i>(Log into Amazon Seller Central using your master account)</i>
                  </p>
                </li>
                <li>
                  {`Type `}
                  <b>{process.env[`ASIN_ZEN_MWS_INFO_TYPE_${this.state.marketplace}`]}</b>
                  {` in the "Developer Name" field and `}
                  <b>{process.env[`ASIN_ZEN_MWS_INFO_PHONE_${this.state.marketplace}`]}</b>
                  {` in the "Developer Account Number" field.`}
                  <br />
                  <ImageZoom
                    image={{
                      src: MarketplaceImages[this.state.marketplace].image1,
                      style: { width: "400px", height: "auto" }
                    }}
                    zoomImage={{ src: MarketplaceImages[this.state.marketplace].image1 }}
                  />
                </li>
                <li>
                  {`Click Next button to confirm you want to give asinzen access to your Amazon selling account`}
                  <br />
                  <ImageZoom
                    image={{
                      src: MarketplaceImages[this.state.marketplace].image2,
                      style: { width: "400px", height: "auto" }
                    }}
                    zoomImage={{ src: MarketplaceImages[this.state.marketplace].image2 }}
                  />
                  <ImageZoom
                    image={{
                      src: MarketplaceImages[this.state.marketplace].image3,
                      style: { width: "400px", height: "auto" }
                    }}
                    zoomImage={{ src: MarketplaceImages[this.state.marketplace].image3 }}
                  />
                </li>
                <li>
                  {`Then copy and paste your "Seller ID" and "MWS Auth Token" values onto the boxes below and click the "NEXT" button.`}
                  <div style={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          error={!!this.state.errors.sellerId}
                        >
                          <InputLabel htmlFor="sellerId">Seller Id</InputLabel>
                          <Input
                            id="sellerId"
                            placeholder="Seller ID"
                            value={this.state.sellerId}
                            onChange={this.handleChange("sellerId")}
                            error={!!this.state.errors.sellerId}
                          />
                          <FormHelperText>{this.state.errors.sellerId || ""}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          error={!!this.state.errors.mwsAuthToken}
                        >
                          <InputLabel htmlFor="mwsAuthToken">MWS Auth Token</InputLabel>
                          <Input
                            id="mwsAuthToken"
                            placeholder="MWS Auth Token"
                            value={this.state.mwsAuthToken}
                            onChange={this.handleChange("mwsAuthToken")}
                            error={!!this.state.errors.mwsAuthToken}
                          />
                          <FormHelperText>{this.state.errors.mwsAuthToken || ""}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {userMsg && (
                      <div
                        style={{
                          color: "red",
                          fontSize: 18,
                          margin: 0,
                          padding: "10px",
                          fontWeight: "bold"
                        }}
                      >
                        {userMsg}
                      </div>
                    )}
                  </div>
                </li>
              </UlStyled>
            </div>
          );
        case 2:
          return arrStepTwo;
      }
    }
  };

  handleClosePopup = () => {
    this.setState({ showPopup: false });
  };

  renderPopup = () => {
    if (!this.state.showPopup) return null;
    return [
      <div key="popup-overlay" className="popup-overlay" />,
      <div key="popup-content" className="popup-content">
        <div className="popup-container">
          <h2
            style={{
              margin: 0,
              fontSize: "2.7em",
              color: "#333333"
            }}
          >
            Wait! Before you go..
          </h2>
          <h5
            style={{ fontSize: "1.5em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            We wanted to make sure you get a chance to try out AZInsight.
          </h5>
          <h5
            style={{ fontSize: "1.5em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            Tell us how we can make that happen.
          </h5>
          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            <strong>Was this was too confusing?</strong> Click{" "}
            <a target="_blank" rel="" href="http://support.asinzen.com/singup">
              here
            </a>{" "}
            to watch a quick step by step guide on completing the signup process and talk to a
            Customer Champion (when available).
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            <strong>Why do I have to enter my MWS Keys?</strong> Each Amazon Seller account has
            different restrictions and permissions. You may be allowed to sell certain brands and in
            certain categories, but be restricted in others. In order to show accurate,
            account-level user permissions and restrictions to each seller, we need access to the
            "back-end", as it is commonly referred to. Just because it is allowed to be sold on
            Amazon doesn't mean that every account can sell it.
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            The MWS Keys allow developers to access the information necessary to provide accurate
            data that is specific to your account. AZInsight uses the MWS keys to produce the
            following data points: slow moving, Prep guidance, number of FBA and FBM offers, list of
            variations, offers’ details on Stock Checker.
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            If you would like to use AZInsight without providing your MWS Keys, the features listed
            below will still be available: FBA Calculator, limited Stock Checker.
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            If you don’t want to add asinzen as a developer in your Amazon Seller Central, please{" "}
            <a target="_blank" href="http://support.asinzen.com">
              contact a customer Champion
            </a>{" "}
            who can give you a demo account to use temporarily without having to put your Amazon MWS
            keys in.
          </h5>
        </div>
        <span className="popup-button-close">
          <CloseIcon onClick={this.handleClosePopup} style={{ fontSize: "35px" }} />
        </span>
      </div>
    ];
  };

  onCloseDialogNotSigninSeller = () => {
    this.checkCanSellOfProducts = false;
    // this.props.checkSigninSellerCenter();
    this.setState({ isOpenDialogNotSigninSeller: false });
  };

  renderDialogNotSigninSeller = () => {
    return (
      <AlertDialogSlide
        isHtml={true}
        open={true}
        isDisagree={false}
        isAgree={true}
        handleClose={this.onCloseDialogNotSigninSeller}
        handleDisagre={() => { }}
        handleAgree={this.onCloseDialogNotSigninSeller}
        title={"Need to be signed into Amazon Seller Centeral to perform this on your behalf."}
        content={`
        <div>
        	<p>Looks like you are not logged into you Amazon Seller Account. Click <a className='' type='button' href='https://sellercentral.amazon.com/' target='_blank'>here</a> to login to Amazon Seller Central</p>
          <i>
          	<small>
							By clicking this self-guided feature you are agreeing to the Amazon Terms of Service Compliance Statement of asinzen & the LICENSE AND ACCESS clause of the Conditions of Use and Service Terms set forth by Amazon.com. No data is being transmitted from your web browser to the internet from this chrome extension, no data is stored or analyzed and your data is for the sole purpose of conducting business with Amazon seller as a Seller in good standing with a Professional Account.
						</small>
					</i>
        </div>
			`}
        textDisagree={""}
        textAgree={"Ok"}
      />
    );
  };

  handleChangeType = typeRegister => {
    const data = {
      confirmShowType: true,
      showType: false,
      typeRegister: typeRegister.target.value
    };
    if (typeRegister.target.value === "hasMws") {
      this.setState({ openConfirm: true, typeRegister: data });
    } else {
      this.setState({ activeStep: 1, ...data });
    }
  };

  showChat = () => {
    window.ZohoHCAsap && window.ZohoHCAsap.Action("open");
  };

  refAbc = React.createRef(null);

  get = () => {
    return (
      <div ref={this.refAbc}>
        <p>
          Please keep in mind the limited trial will only allow you to evaluate{" "}
          <strong>40 ASINs</strong> during your trial period. So If you use up all your evaluation
          ASINS before the 10 days is over, the trial will expire at that time.
        </p>
        <p>
          If you have an Amazon Seller Pro account, we suggest you choose the unlimited usage trial
          and add asinzen as a developer in your Amazon Seller Central.
        </p>
        <FormControlLabel
          className="checkbox-confirm"
          onChange={() => {
            this.setState({ isCheckConfirm: !this.state.isCheckConfirm });
          }}
          checked={this.state.isCheckConfirm}
          control={<Checkbox className="checkbox" color="primary" />}
          label="I understand I need a Professional Seller Account to use the software after the trial ends "
        />
        {this.state.showMessageConfirm && (
          <p style={{ color: "red" }}>
            You must confirm that you understand that you will need an Amazon Professional Selling
            Account.
          </p>
        )}
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const {
      isOpenSnack,
      verticalSnack,
      horizontalSnack,
      messageSnack,
      email,
      contactId,
      plan,
      plansURL,
      password,
      isOpenDialogNotSigninSeller,
      isExistedUser,
      arrayTitleSteps,
      userHazType,
      openConfirm,
      error
    } = this.state;
    const steps = arrayTitleSteps;
    const { activeStep, isNextButtonDisabled, userMsg, wayAddMws, typeRegister } = this.state;
    return (
      <Grid container spacing={10} justify="center" alignItems="center">
        {this.renderPopup()}
        <Grid item xs={8}>
          <div>
            <p className="img-logo-" style={{ textAlign: "center", height: "15px" }} />
            <SettingsCard
              title={<div>Almost done! Now lets complete the final steps so you can begin. </div>}
            >
              <div style={{ display: "flex" }}>
                <Grid item xs={12}>
                  {userMsg ? (
                    <div style={{ color: "red", fontSize: 18, maringTop: 10, marginBottom: 10 }}>
                      <b>
                        {userMsg}
                        {plansURL ? (
                          <>
                            {" "}
                            Please click <a href={plansURL}>HERE</a> to subscribe. If you would like
                            to extend your trial please contact support{" "}
                            <a href={`https://support.asinzen.com`} target={"_blank"}>
                              here
                            </a>
                            .
                          </>
                        ) : null}
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => {
                      return (
                        <Step key={index}>
                          <StepLabel>
                            <div dangerouslySetInnerHTML={{ __html: label }}></div>
                            {label.indexOf(
                              "as a developer on Amazon Seller Central to generate an M"
                            ) >= 0 && (
                                <div>
                                  <span
                                    className="wistia_embed wistia_async_fbvm4bb56s popover=true popoverContent=link"
                                    style={{ display: "inline", position: "relative" }}
                                  >
                                    Having issues adding asinzen as a developer on Seller Central?{" "}
                                    <a href="#">Click here to watch this short video.</a>
                                  </span>
                                </div>
                              )}
                          </StepLabel>
                          <StepContent>
                            {this.getStepContent(index)}
                            {!error ? (
                              <div className={classes.actionsContainer}>
                                <div>
                                  {activeStep === 1 && typeRegister === "noMws" && (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{ marginRight: 10 }}
                                      onClick={() => this.setState({ activeStep: 2 })}
                                    >
                                      Cancel, I will do the limited trial with SMS verification
                                    </Button>
                                  )}
                                  {activeStep < steps.length - 1 ? (
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={this.handleNext}
                                      style={{ marginRight: 10 }}
                                      disabled={
                                        isNextButtonDisabled ||
                                        (!email && !contactId && !plan && !password ? true : false)
                                      }
                                    >
                                      {activeStep === steps.length - 1 ? "Finish" : "Save & Finish"}
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {/* {activeStep === steps.length - 1 && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                      <Typography style={{ maxWidth: "783px" }}>
                        <p>
                          That’s it, you may now close this page or click{" "}
                          <a
                            href="https://www.amazon.com/dp/B07G9Y3ZMC"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            here to go to an Amazon product page
                          </a>{" "}
                          to start.{" "}
                        </p>

                        <p>
                          Note: The next time you open an amazon product page, AZInsight will
                          automatically load. Please log in using the email address and password you
                          registered with.
                        </p>
                      </Typography>
                    </Paper>
                  )} */}
                </Grid>
              </div>
            </SettingsCard>
          </div>
        </Grid>
        {isOpenSnack ? (
          <SnackBars
            isOpenSnack={isOpenSnack}
            verticalSnack={verticalSnack}
            horizontalSnack={horizontalSnack}
            messageSnack={messageSnack}
            handleStopSnackBar={this.handleStopSnackBar}
          />
        ) : (
          ""
        )}
        {isOpenDialogNotSigninSeller === true ? this.renderDialogNotSigninSeller() : null}
      </Grid>
    );
  }
}

SignUpContainer.defaultProps = {
  userState: userInitState,
  userSignupRequest: null,
  userUpdateInfoWithoutMwsRequest: null,
  userUpdateMwsRequest: null
};

SignUpContainer.propTypes = {
  userState: PropTypes.shape({}),
  userSignupRequest: PropTypes.func,
  userUpdateInfoWithoutMwsRequest: PropTypes.func,
  userUpdateMwsRequest: PropTypes.func
};

export default connectAutoDispatch(
  state => ({ userState: state.userState, planState: state.planState }),
  {
    userSignupRequest,
    userUpdateInfoWithoutMwsRequest,
    userUpdateMwsRequest,
    getProductPlanRequest
  }
)(withStyles(UserStyles)(SignUpContainer));
