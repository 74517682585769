/*eslint-disable*/
import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { FaCreditCard } from "react-icons/fa/index";
import _findIndex from "lodash/findIndex";
import { connectAutoDispatch } from "redux/reduxConnect";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ModalCancelSubscription from "./ModalCancelSubscription";

const stylesG = {
  aCenter: { textAlign: "center" },
  aLeft: { textAlign: "left" },
  colorFf6a30: { color: "#ff6a30" },
  colorFff: { color: "#fff" }
};

const styles = {
  paper: { padding: 3, fontSize: 19 },
  div1: { width: "100%", backgroundColor: "#ff6a30" },
  p1: { margin: 0, lineHeight: "44px" },
  divBtn: { position: "relative", top: -80, textAlign: "center", height: 90 }
};

class PlansAzinsightUI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisable: false,
      isOpenCancelDialog: false,
      openPopupPlus: false,
      reasonCancel: 0
    };
  }

  _DetectSubscibeExtraLogin = () => {
    const index = _findIndex(this.props.pluginsAdded, { isExtraPlugin: true });
    return index >= 0;
  };

  renderPopupPlus = () => {
    const {
      currentUserData: { contactId, firstName, lastName, email },
      item
    } = this.props;
    return (
      <Dialog open={true} fullWidth maxWidth="md" onClose={this.closeReportMissing}>
        <DialogTitle disableTypography style={{ textAlign: "center" }}>
          <strong style={{ fontSize: 20, padding: "8px 16px" }}>
            Are you sure you want to subscribe to the Plus plan?
          </strong>
        </DialogTitle>
        <DialogContent>
          <p style={{ margin: "4px 0px" }}>
            By subscribing to the Plus plan you will NOT have access to the following:
          </p>
          <p style={{ margin: "4px 0px" }}>
            <i className="far fa-times-circle" /> The ability to use the full Amazon FBA and FBM
            Profit calculator
          </p>
          <p style={{ margin: "4px 0px" }}>
            <i className="far fa-times-circle" /> Having the advanced Variations Viewer and Stock
            Checker
          </p>
          <p style={{ margin: "4px 0px" }}>
            <i className="far fa-times-circle" /> Using features such as: Google Sheets integration,
            Alert Settings, My Link, Copy and Export Features
          </p>
          <p style={{ margin: "4px 0px" }}>
            <i className="far fa-times-circle" /> Access to FREE Plugins such as: SearchView, Sales
            and Revenue Estimations
          </p>
          <p style={{ margin: "6px 0px 3px 0px" }}>
            The Plus plan ONLY comes with the following features:
          </p>
          <ul style={{ margin: "3px 0px" }}>
            <li>Seller Restrictions Checker and Hazmat </li>
            <li>Brand IP Notifications</li>
            <li>Slow Moving ASIN Detector</li>
            <li>
              Ability to add the AZNotes, InventoryLab App Integration, add extra sub-account logins
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => this.setState({ openPopupPlus: false })}>
            No, cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              window.open(
                `${item.url}?contactId=${contactId}&inf_field_FirstName=${firstName}&inf_field_LastName=${lastName}&inf_field_Email=${email}`,
                `_blank`
              )
            }
          >
            Yes, I understand
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  onCloseModalCancel = () => this.setState({ isOpenCancelDialog: false });

  render() {
    const {
      gXs,
      gSm,
      currentUserData: {
        contactId,
        firstName,
        lastName,
        email,
        productType,
        nextSubscribeAt,
        isCanceledAccount
      },
      item,
      k,
      handleShowSnackBar,
      detectIsExtraLogin,
      isNotStartTrial = false,
      isInTrial = false,
      isOutOfTrial = false
    } = this.props;
    const { isOpenCancelDialog } = this.state;
    const isDisable = Boolean(item.disable);
    const onLifeCycle =
      isCanceledAccount === true &&
      moment().isBefore(nextSubscribeAt) &&
      ((productType === "hazmat" && item.id === "HAZability_PLAN") ||
        (productType === "basic" && item.id === "AZInsight_PLAN") ||
        (productType === "hazbasic" && item.id === "HAZability_PLAN_BASIC"));
    console.log("debug ", isOutOfTrial, isNotStartTrial, isInTrial);
    return (
      <Grid key={k} item xs={gXs} sm={gSm} className="Maxwidth-450">
        <Paper style={styles.paper} className="pp-plans">
          <div
            style={{
              padding: "20px 20px 10px 20px",
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center"
            }}
          >
            <span style={{ color: "rgb(255, 87, 34)", fontSize: 20 }}>{item.title}</span>
            <span style={{ color: "rgb(255, 87, 34)", fontSize: 16 }}>
              ${item.amount || ""} / {item.interval || ""}
            </span>
          </div>
          <div className="plan-content" style={{ padding: "0 20px", fontSize: 14 }}>
            {item && item.contents && item.contents.length ? <strong>Features</strong> : null}
            <ul
              style={{
                paddingInlineStart: 10,
                marginBlockStart: 0,
                marginBlockEnd: 0
              }}
            >
              {item.contents.map((f, i) => (
                <li style={{ marginLeft: 10, textAlign: "left" }} key={i}>
                  {f || ""}
                </li>
              ))}
            </ul>
          </div>
          <div className="Plan-Footer">
            {!isOutOfTrial && isNotStartTrial && !isInTrial && (
              <Button
                style={{
                  fontSize: 15,
                  color: "#4CAF50",
                  border: `1px solid #4caf50a1`
                }}
                onClick={() =>
                  window.open(
                    "https://chrome.google.com/webstore/detail/azinsight-amazon-fba-prod/gefiflkplklbfkcjjcbobokclopbigfg"
                  )
                }
              >
                Log into AZInsight or ScanEZ to start the free trial
              </Button>
            )}
            {!isOutOfTrial && isInTrial && (
              <Button
                onClick={() => {
                  if (item.id === "HAZability_PLAN" && !isDisable) {
                    return this.setState({ openPopupPlus: true });
                  }
                  if (productType === "hazmat" && detectIsExtraLogin) {
                    return this.props.showAlert();
                  }
                  this.setState({ isDisable: true });
                  window.open(
                    `${item.url}?contactId=${contactId}&inf_field_FirstName=${firstName}&inf_field_LastName=${lastName}&inf_field_Email=${email}`,
                    `_blank`
                  );
                }}
                style={{
                  fontSize: 15,
                  color: "#4CAF50",
                  border: `1px solid #4caf50a1`
                }}
              >
                In free trial. Subscribe now if you want
              </Button>
            )}
            {isOutOfTrial && (
              <Button
                disabled={this.state.isDisable || isDisable}
                style={{
                  fontSize: 15,
                  color: `${isDisable === true ? "#4CAF50" : "#FF5722"}`,
                  border: `1px solid ${isDisable === true ? "#4caf50a1" : "#ff572299"}`
                }}
                onClick={() => {
                  if (item.id === "HAZability_PLAN" && !isDisable) {
                    return this.setState({ openPopupPlus: true });
                  }
                  if (productType === "hazmat" && detectIsExtraLogin) {
                    return this.props.showAlert();
                  }
                  this.setState({ isDisable: true });
                  window.open(
                    `${item.url}?contactId=${contactId}&inf_field_FirstName=${firstName}&inf_field_LastName=${lastName}&inf_field_Email=${email}`,
                    `_blank`
                  );
                }}
                variant={isDisable === true ? "contained" : "outlined"}
                color="primary"
                size="small"
              >
                {isDisable === false ? <FaCreditCard style={{ marginRight: 5 }} /> : ""}{" "}
                {isDisable === true ? "Subscribed" : "Subscribe Now"}
              </Button>
            )}
            {onLifeCycle && (
              <Button
                disabled={this.state.isDisable || isDisable}
                style={{
                  fontSize: 15,
                  color: "#FF5722",
                  border: "#ff572299"
                }}
                variant="contained"
                color="primary"
                size="small"
              >
                Canceled. No additional payments due.
              </Button>
            )}
            {isDisable === true && !onLifeCycle && item.interval !== "Lifetime" && (
              <Button
                style={{
                  fontSize: 15,
                  color: "#FF5722",
                  border: "1px solid #ff572299"
                }}
                onClick={() => this.setState({ isOpenCancelDialog: true })}
                variant="outlined"
                color="primary"
                size="small"
              >
                Cancel Subscription
              </Button>
            )}
          </div>
        </Paper>
        {this.state.openPopupPlus && this.renderPopupPlus()}
        <ModalCancelSubscription
          open={isOpenCancelDialog}
          item={this.props.item}
          onClose={this.onCloseModalCancel}
        />
      </Grid>
    );
  }
}

export default connectAutoDispatch(null, {})(PlansAzinsightUI);
