/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable no-continue */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { apiGetTeamHistory, apiGetAllAccounts } from "api/user";
import { debounce } from "lodash";
import { Button, Flex, Input, Select, Tooltip } from "antd";

const { Search } = Input;

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    if (cellValue == null) return -1;
    const dateAsString = moment(cellValue).format("DD/MM/YYYY");
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  inRangeInclusive: true
};

const textFilterParams = {
  filterOptions: ["contains", "notContains"],
  textFormatter: r => {
    if (r == null) return null;
    return r
      .toLowerCase()
      .replace(/[àáâãäå]/g, "a")
      .replace(/æ/g, "ae")
      .replace(/ç/g, "c")
      .replace(/[èéêë]/g, "e")
      .replace(/[ìíîï]/g, "i")
      .replace(/ñ/g, "n")
      .replace(/[òóôõö]/g, "o")
      .replace(/œ/g, "oe")
      .replace(/[ùúûü]/g, "u")
      .replace(/[ýÿ]/g, "y");
  },
  debounceMs: 200,
  maxNumConditions: 1
};

const RenderAsin = ({ data: { asin, url } }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
      {asin}
    </a>
  );
};

const RenderInsight360Site = ({ data: { insight360Site } }) => {
  if (!insight360Site) return "N/A";
  return (
    <a href={insight360Site} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
      {insight360Site}
    </a>
  );
};

const RenderSourced = ({ data: { sourced } }) => {
  return moment(sourced).format("MMMM Do YYYY, h:mm A");
};

const RenderTooltip = ({ value }) => {
  return (
    <Tooltip title={value ? value : "N/A"} placement="top">
      <span>{value ? value : "N/A"}</span>
    </Tooltip>
  );
};

const RenderTitle = ({ value, data: { url } }) => {
  return (
    <Tooltip title={value ? value : "N/A"} placement="top">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value ? value : "N/A"}
      </a>
    </Tooltip>
  );
};

const RenderImage = ({ value }) => {
  return <img style={{ height: 36 }} src={value} />;
};

const RenderSourcedFrom = ({ value }) => {
  return value === "MOBILE" ? "ScanEZ" : "AZInsight";
};

const listTime = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 30 days", value: "last30days" },
  { label: "All history", value: "all" }
];

const getServerSideDatasource = server => {
  return {
    getRows: params => {
      console.log("[Datasource] - rows requested by grid: ", params.request);
      // get data for request from our fake server
      const response = server.getData(params.request);
      // simulating real server call with a 500ms delay
      setTimeout(() => {
        if (response.success) {
          // supply rows for requested block to grid
          params.success({
            rowData: response.rows,
            rowCount: response.lastRow
          });
        } else {
          params.fail();
        }
      }, 500);
    }
  };
};

const TeamHistory = () => {
  const gridRef = useRef();
  let gridColumnApi = null;
  const limit = 100;

  const [agGridApi, setAgGridApi] = useState();
  const [state, setState] = useState({
    time: "today",
    listAccount: [{ label: "All accounts", value: "all" }],
    account: "all",
    individual: false
  });

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Product Image",
      field: "image",
      width: 140,
      cellRenderer: RenderImage
    },
    {
      headerName: "Product Title",
      field: "title",
      width: 300,
      cellRenderer: RenderTitle,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: "Category",
      field: "category",
      width: 200,
      cellRenderer: RenderTooltip,
      valueFormatter: params => {
        if (!params.value) {
          return "N/A";
        }
      },
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: "BSR",
      field: "bsr",
      width: 100,
      valueFormatter: params => {
        if (!params.value) {
          return "N/A";
        }
      },
      filter: "agNumberColumnFilter"
    },
    {
      headerName: "ASIN",
      field: "asin",
      width: 130,
      cellRenderer: RenderAsin,
      valueFormatter: params => {
        if (!params.value) {
          return "N/A";
        }
      },
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: "Insight360 Site",
      field: "insight360Site",
      width: 300,
      cellRenderer: RenderInsight360Site,
      valueFormatter: params => {
        if (!params.value) {
          return "N/A";
        }
      },
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: "Insight360 Keyword",
      field: "insight360Keyword",
      width: 200,
      valueFormatter: params => {
        if (!params.value) {
          return "N/A";
        }
      },
      cellRendererSelector: RenderTooltip,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: "Marketplace",
      field: "marketplace",
      width: 160,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: "Sourced",
      field: "sourced",
      width: 200,
      filter: "agDateColumnFilter",
      filterParams,
      cellRenderer: RenderSourced
    },
    {
      headerName: "Sourced From",
      field: "source",
      width: 140,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: "Account Email",
      field: "email",
      width: 240,
      cellRenderer: RenderTooltip,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    }
  ]);

  const BtnExportCsv = () => {
    const dataSource = getServerSideDatasource();
    const onClick = () => {
      gridRef.current.api.exportDataAsCsv(dataSource);
    };

    return (
      <div className="ag-status-name-value">
        <Button variant="contained" onClick={onClick}>
          Export CSV
        </Button>
      </div>
    );
  };

  const gridOptions = Object.create({
    rowModelType: "serverSide",
    frameworkComponents: {
      RenderAsin,
      RenderSourced,
      RenderTooltip,
      RenderInsight360Site,
      RenderImage,
      RenderSourcedFrom,
      RenderTitle
    },
    rowSelection: "multiple",
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true // set filtering on for all cols
    },
    isExternalFilterPresent: () => true,
    doesExternalFilterPass: () => true,
    domLayout: "autoHeight",
    cacheBlockSize: limit,
    statusBar: {
      statusPanels: [
        {
          statusPanel: BtnExportCsv,
          align: "left"
        }
      ]
    },
    overlayNoRowsTemplate: "No results found"
  });

  const showListAccounts = allAccounts => {
    const list = [{ label: "All accounts", value: "all" }];
    // eslint-disable-next-line
    for (const account of allAccounts) {
      list.push({ label: account.email, value: account.email });
    }
    setState(prevState => ({
      ...prevState,
      listAccount: list
    }));
  };

  const onGridReady = params => {
    const gridApi = params.api;
    setAgGridApi(gridApi);

    gridApi.setColumnDefs(columnDefs);
    gridColumnApi = params.columnApi;

    gridApi.closeToolPanel();
    onGetProducts();
  };

  const onGetProducts = () => {
    const dataSource = getServerSideDatasource();
    agGridApi?.setServerSideDatasource(dataSource);
  };

  const getServerSideDatasource = () => {
    return {
      getRows: debounce(params => {
        const { time, account, individual } = state;
        const { startRow, sortModel, filterModel } = params.request;
        const page = startRow === 0 || startRow === undefined ? 1 : startRow / limit + 1;
        apiGetTeamHistory({
          page,
          limit,
          sortModel,
          filterModel,
          time,
          account,
          individual
        })
          .then(({ data, totals }) => {
            if (totals === 0) {
              agGridApi.showNoRowsOverlay();
            }
            params.success({
              rowData: data,
              rowCount: totals
            });
          })
          .catch(error => {
            console.error(error);
            params.fail();
          });
      }, 500)
    };
  };

  const getRowNodeId = params => params.data._id;

  const onChangeTime = value =>
    setState(prevState => ({
      ...prevState,
      time: value
    }));

  const onChangeAccount = value =>
    setState(prevState => ({
      ...prevState,
      account: value
    }));

  const onFilterTextBoxChanged = value => {
    setState(prevState => ({
      ...prevState,
      individual: !!value || value !== ""
    }));

    agGridApi?.setFilterModel({
      title: {
        filterType: "text",
        type: "contains",
        filter: value
      },
      category: {
        filterType: "text",
        type: "contains",
        filter: value
      },
      email: {
        filterType: "text",
        type: "contains",
        filter: value
      }
    });
  };

  useEffect(() => {
    onGetProducts();
  }, [state.listAccount, state.time, state.account]);

  useEffect(() => {
    apiGetAllAccounts().then(data => showListAccounts(data));
  }, []);

  return (
    <Flex vertical gap="middle">
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="middle">
          <div>Filter by:</div>
          <div>Time</div>
          <Select
            onChange={onChangeTime}
            value={state.time}
            options={listTime.map(item => ({
              label: item.label,
              value: item.value
            }))}
          />
          <div>and Account email</div>
          <Select
            onChange={onChangeAccount}
            value={state.account}
            options={state.listAccount.map(item => ({
              label: item.label,
              value: item.value
            }))}
          />
        </Flex>
        <Flex align="center" gap="middle">
          <Button type="primary" href="/user/history-usage-statistics" target="_blank">
            Usage Statistics
          </Button>
          <Search
            placeholder="Input to search category or product title or account email"
            onSearch={onFilterTextBoxChanged}
            style={{ width: 200 }}
          />
        </Flex>
      </Flex>
      <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
        <AgGridReact
          style={{ width: "100%", height: "100%" }}
          ref={gridRef}
          animateRows
          enableRangeSelection
          pagination
          paginationPageSize={limit}
          getRowId={getRowNodeId}
          gridOptions={gridOptions}
          sideBar="columns"
          localeText={{ columns: "Configure Columns" }}
          onGridReady={onGridReady}
        />
      </div>
    </Flex>
  );
};

export default TeamHistory;
