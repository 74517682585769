import { generateChildHeadersWithIndex } from "utils/asinzen-api/table";
import { PeriodTimeTitles, TypeTitles, TermTitles } from "./common";

export const listData = {
  general: [
    { name: "info", label: "Info" },
    { name: "hazmat_aspects", label: "Hazmat Aspects" },
    { name: "hazmat_condition", label: "Hazmat Condition" },
    { name: "meltable", label: "Meltable" },
    { name: "azalert", label: "Azalert" }
  ],
  statistical: [
    { name: "root_rank", label: "Root Rank" },
    { name: "sales", label: "Sales" },
    { name: "amazon", label: "Amazon" },
    { name: "amazon_warehouse", label: "Amazon Warehouse" },
    { name: "offers_fba_new", label: "Offers FBA New" },
    { name: "offers_fbm_new", label: "Offers FBM New" },
    { name: "offers_new", label: "Offers New" },
    { name: "lowest_fba_new", label: "Lowest FBA New" },
    { name: "lowest_fbm_new", label: "Lowest FBM New" },
    { name: "lowest_new", label: "Lowest New" },
    { name: "buybox_new", label: "Buybox New" },
    { name: "offers_fba_used", label: "Offers FBA Used" },
    { name: "offers_fbm_used", label: "Offers FBM Used" },
    { name: "offers_used", label: "Offers Used" },
    { name: "lowest_fba_used", label: "Lowest FBA Used" },
    { name: "lowest_fbm_used", label: "lowest FBM Used" },
    { name: "lowest_used", label: "Lowest Used" },
    { name: "buybox_used", label: "Buybox Used" },
    { name: "lowest_used_like_new", label: "Lowest Used Like New" },
    { name: "lowest_used_very_good", label: "Lowest Used Very Good" },
    { name: "lowest_used_good", label: "Lowest Used Good" },
    { name: "lowest_used_acceptable", label: "Lowest Used Acceptable" },
    { name: "review", label: "Review" },
    { name: "rating", label: "Rating" }
  ]
};

export const ProductViewerHeaderTitles = {
  ASIN: "ASIN",
  TITLE: "Title",
  IMAGE_UR_LS: "Image",
  LAUNCH_DATE: "Launch Date",
  MANUFACTURER: "Manufacturer",
  MODEL_NUMBER: "Model Number",
  LIST_PRICE: "List Price",
  ITEM_HEIGHT: "Item Height",
  ITEM_LENGTH: "Item Length",
  ITEM_WIDTH: "Item Width",
  ITEM_WEIGHT: "Item Weight",
  PACKAGE_HEIGHT: "Package Height",
  PACKAGE_LENGTH: "Package Length",
  PACKAGE_WIDTH: "Package Width",
  PACKAGE_WEIGHT: "Package Weight",
  PACKAGE_QUANTITY: "Package Quantity",
  SHIPPING_WEIGHT: "Shipping Weight",
  SIZE_TIER: "Size Tier",
  MAIN_CATEGORY: "Main Category",
  SUB_CATEGORY: "Sub Category",
  VARIATION_THEME: "Variation Theme",
  VARIATION_ASINS: "Variation ASINs",
  MONTHLY_STORAGE_FEE_JAN_TO_SEP_FBA: "Monthly Storage Fee Jan to Sep",
  MONTHLY_STORAGE_FEE_OCT_TO_DEC_FBA: "Monthly Storage Fee Oct to Dec",
  FULFILLMENT_FEE_FBA: "Fulfillment Fee",
  IS_HAZMAT: "Is Hazmat",
  BRAND: "Brand",
  EAN: "EAN",
  UPC: "UPC",
  ISBN: "ISBN",
  PARENT_ASIN: "Parent ASIN",
  LAST_UPDATED: "Last Updated",
  // Statis
  AMAZON_PRICE: "Amazon",
  AMAZON_WAREHOUSE_PRICE: "Amazon Warehouse",
  BUY_BOX_PRICE_NEW: "Buy Box Price New",
  BUY_BOX_PRICE_USED: "Buy Box Price Used",
  LOWEST_PRICE_NEW_FBA: "Lowest Price New FBA",
  LOWEST_PRICE_NEW_FBM: "Lowest Price New FBM",
  LOWEST_PRICE_NEW: "Lowest Price New",
  LOWEST_PRICE_USED: "Lowest Price Used",
  LOWEST_PRICE_USED_FBA: "Lowest Price Used FBA",
  LOWEST_PRICE_USED_FBM: "Lowest Price Used FBM",
  LOWEST_PRICE_USED_ACCEPTABLE: "Lowest Price Used Acceptable",
  LOWEST_PRICE_USED_GOOD: "Lowest Price Used Good",
  LOWEST_PRICE_USED_LIKE_NEW: "Lowest Price Used Like New",
  LOWEST_PRICE_USED_VERY_GOOD: "Lowest Price Used Very Good",
  TOTAL_OFFERS_NEW: "Total Offers New",
  TOTAL_OFFERS_NEW_FBA: "Total Offers New FBA",
  TOTAL_OFFERS_NEW_FBM: "Total Offers New FBM",
  TOTAL_OFFERS_USED: "Total Offers Used",
  TOTAL_OFFERS_USED_FBA: "Total Offers Used FBA",
  TOTAL_OFFERS_USED_FBM: "Total Offers Used FBM",
  RANK_OF_ROOT_CATEGORY: "Rank Of Root Category",
  EST_SALES: "Estimated Sales",
  RATING_COUNT: "Rating Count",
  RATING: "Rating"
};

export const ProductViewerHeaderFields = {
  ASIN: "ASIN",
  TITLE: "general_data.info.title",
  IMAGE_UR_LS: "general_data.info.image_ur_ls",
  LAUNCH_DATE: "general_data.info.launch_date",
  MANUFACTURER: "general_data.info.manufacturer",
  MODEL_NUMBER: "general_data.info.model_number",
  LIST_PRICE: "general_data.info.list_price",
  ITEM_HEIGHT: "general_data.info.item_height",
  ITEM_LENGTH: "general_data.info.item_length",
  ITEM_WIDTH: "general_data.info.item_width",
  ITEM_WEIGHT: "general_data.info.item_weight",
  PACKAGE_HEIGHT: "general_data.info.package_height",
  PACKAGE_LENGTH: "general_data.info.package_length",
  PACKAGE_WIDTH: "general_data.info.package_width",
  PACKAGE_WEIGHT: "general_data.info.package_weight",
  PACKAGE_QUANTITY: "general_data.info.package_quantity",
  SHIPPING_WEIGHT: "general_data.info.shipping_weight",
  SIZE_TIER: "general_data.info.size_tier",
  MAIN_CATEGORY: "general_data.info.main_category",
  SUB_CATEGORY: "general_data.info.sub_category",
  VARIATION_THEME: "general_data.info.variation_theme",
  VARIATION_ASINS: "general_data.info.variation_asins",
  MONTHLY_STORAGE_FEE_JAN_TO_SEP_FBA: "general_data.info.monthly_storage_fee_jan_to_sep_fba",
  MONTHLY_STORAGE_FEE_OCT_TO_DEC_FBA: "general_data.info.monthly_storage_fee_oct_to_dec_fba",
  FULFILLMENT_FEE_FBA: "general_data.info.is_hazmat",
  IS_HAZMAT: "general_data.info.fulfillment_fee_fba",
  BRAND: "general_data.info.brand",
  EAN: "general_data.info.ean",
  UPC: "general_data.info.upc",
  ISBN: "general_data.info.isbn",
  PARENT_ASIN: "general_data.info.parent_asin",
  LAST_UPDATED: "general_data.info.last_updated",
  // Statis
  AMAZON_PRICE: "stats_data.amazon_price",
  AMAZON_WAREHOUSE_PRICE: "stats_data.amazon_warehouse_price",
  BUY_BOX_PRICE_NEW: "stats_data.buy_box_price_new",
  BUY_BOX_PRICE_USED: "stats_data.buy_box_price_used",
  LOWEST_PRICE_NEW_FBA: "stats_data.lowest_price_new_fba",
  LOWEST_PRICE_NEW_FBM: "stats_data.lowest_price_new_fbm",
  LOWEST_PRICE_NEW: "stats_data.",
  LOWEST_PRICE_USED: "stats_data.",
  LOWEST_PRICE_USED_FBA: "stats_data.lowest_price_used_fba",
  LOWEST_PRICE_USED_FBM: "stats_data.lowest_price_used_fbm",
  LOWEST_PRICE_USED_ACCEPTABLE: "stats_data.lowest_price_used_acceptable",
  LOWEST_PRICE_USED_GOOD: "stats_data.lowest_price_used_good",
  LOWEST_PRICE_USED_LIKE_NEW: "stats_data.lowest_price_used_like_new",
  LOWEST_PRICE_USED_VERY_GOOD: "stats_data.lowest_price_used_very_good",
  TOTAL_OFFERS_NEW: "stats_data.total_offers_new",
  TOTAL_OFFERS_NEW_FBA: "stats_data.total_offers_new_fba",
  TOTAL_OFFERS_NEW_FBM: "stats_data.total_offers_new_fbm",
  TOTAL_OFFERS_USED: "stats_data.total_offers_used",
  TOTAL_OFFERS_USED_FBA: "stats_data.total_offers_used_fba",
  TOTAL_OFFERS_USED_FBM: "stats_data.total_offers_used_fbm",
  RANK_OF_ROOT_CATEGORY: "stats_data.rank_of_root_category",
  EST_SALES: "stats_data.est_sales",
  RATING_COUNT: "stats_data.rating_count",
  RATING: "stats_data.rating"
};

export const columnHeaders = [
  {
    checkboxSelection: true,
    field: ProductViewerHeaderFields.ASIN,
    headerName: ProductViewerHeaderTitles.ASIN,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.TITLE,
    headerName: ProductViewerHeaderTitles.TITLE,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.IMAGE_UR_LS,
    headerName: ProductViewerHeaderTitles.IMAGE_UR_LS,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.LAUNCH_DATE,
    headerName: ProductViewerHeaderTitles.LAUNCH_DATE,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.MANUFACTURER,
    headerName: ProductViewerHeaderTitles.MANUFACTURER,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.MODEL_NUMBER,
    headerName: ProductViewerHeaderTitles.MODEL_NUMBER,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.LIST_PRICE,
    headerName: ProductViewerHeaderTitles.LIST_PRICE,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.ITEM_HEIGHT,
    headerName: ProductViewerHeaderTitles.ITEM_HEIGHT,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.ITEM_LENGTH,
    headerName: ProductViewerHeaderTitles.ITEM_LENGTH,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.ITEM_WIDTH,
    headerName: ProductViewerHeaderTitles.ITEM_WIDTH,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.ITEM_WEIGHT,
    headerName: ProductViewerHeaderTitles.ITEM_WEIGHT,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.PACKAGE_HEIGHT,
    headerName: ProductViewerHeaderTitles.PACKAGE_HEIGHT,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.PACKAGE_LENGTH,
    headerName: ProductViewerHeaderTitles.PACKAGE_LENGTH,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.PACKAGE_WIDTH,
    headerName: ProductViewerHeaderTitles.PACKAGE_WIDTH,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.PACKAGE_WEIGHT,
    headerName: ProductViewerHeaderTitles.PACKAGE_WEIGHT,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.PACKAGE_QUANTITY,
    headerName: ProductViewerHeaderTitles.PACKAGE_QUANTITY,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.SHIPPING_WEIGHT,
    headerName: ProductViewerHeaderTitles.SHIPPING_WEIGHT,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.SIZE_TIER,
    headerName: ProductViewerHeaderTitles.SIZE_TIER,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.MAIN_CATEGORY,
    headerName: ProductViewerHeaderTitles.MAIN_CATEGORY,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.SUB_CATEGORY,
    headerName: ProductViewerHeaderTitles.SUB_CATEGORY,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.VARIATION_THEME,
    headerName: ProductViewerHeaderTitles.VARIATION_THEME,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.VARIATION_ASINS,
    headerName: ProductViewerHeaderTitles.VARIATION_ASINS,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.MONTHLY_STORAGE_FEE_JAN_TO_SEP_FBA,
    headerName: ProductViewerHeaderTitles.MONTHLY_STORAGE_FEE_JAN_TO_SEP_FBA,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.MONTHLY_STORAGE_FEE_OCT_TO_DEC_FBA,
    headerName: ProductViewerHeaderTitles.MONTHLY_STORAGE_FEE_OCT_TO_DEC_FBA,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.FULFILLMENT_FEE_FBA,
    headerName: ProductViewerHeaderTitles.FULFILLMENT_FEE_FBA,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.IS_HAZMAT,
    headerName: ProductViewerHeaderTitles.IS_HAZMAT,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.BRAND,
    headerName: ProductViewerHeaderTitles.BRAND,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.EAN,
    headerName: ProductViewerHeaderTitles.EAN,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.UPC,
    headerName: ProductViewerHeaderTitles.UPC,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.ISBN,
    headerName: ProductViewerHeaderTitles.ISBN,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.PARENT_ASIN,
    headerName: ProductViewerHeaderTitles.PARENT_ASIN,
    filter: true,
    enableValue: false
  },
  {
    field: ProductViewerHeaderFields.LAST_UPDATED,
    headerName: ProductViewerHeaderTitles.LAST_UPDATED,
    filter: true,
    enableValue: false
  },
  //Statis
  {
    field: ProductViewerHeaderFields.AMAZON_PRICE,
    headerName: ProductViewerHeaderTitles.AMAZON_PRICE,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.AMAZON_PRICE)
  },
  {
    field: ProductViewerHeaderFields.AMAZON_WAREHOUSE_PRICE,
    headerName: ProductViewerHeaderTitles.AMAZON_WAREHOUSE_PRICE,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.AMAZON_WAREHOUSE_PRICE
    )
  },
  {
    field: ProductViewerHeaderFields.BUY_BOX_PRICE_NEW,
    headerName: ProductViewerHeaderTitles.BUY_BOX_PRICE_NEW,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.BUY_BOX_PRICE_NEW)
  },
  {
    field: ProductViewerHeaderFields.BUY_BOX_PRICE_USED,
    headerName: ProductViewerHeaderTitles.BUY_BOX_PRICE_USED,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.BUY_BOX_PRICE_USED
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_NEW,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_NEW,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.LOWEST_PRICE_NEW)
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_NEW_FBA,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_NEW_FBA,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_NEW_FBA
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_NEW_FBM,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_NEW_FBM,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_NEW_FBM
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_USED,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_USED,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.LOWEST_PRICE_USED)
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_USED_ACCEPTABLE,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_USED_ACCEPTABLE,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_USED_ACCEPTABLE
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_USED_FBA,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_USED_FBA,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_USED_FBA
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_USED_FBM,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_USED_FBM,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_USED_FBM
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_USED_GOOD,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_USED_GOOD,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_USED_GOOD
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_USED_LIKE_NEW,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_USED_LIKE_NEW,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_USED_LIKE_NEW
    )
  },
  {
    field: ProductViewerHeaderFields.LOWEST_PRICE_USED_VERY_GOOD,
    headerName: ProductViewerHeaderTitles.LOWEST_PRICE_USED_VERY_GOOD,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.LOWEST_PRICE_USED_VERY_GOOD
    )
  },
  {
    field: ProductViewerHeaderFields.TOTAL_OFFERS_NEW,
    headerName: ProductViewerHeaderTitles.TOTAL_OFFERS_NEW,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.TOTAL_OFFERS_NEW)
  },
  {
    field: ProductViewerHeaderFields.TOTAL_OFFERS_NEW_FBA,
    headerName: ProductViewerHeaderTitles.TOTAL_OFFERS_NEW_FBA,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.TOTAL_OFFERS_NEW_FBA
    )
  },
  {
    field: ProductViewerHeaderFields.TOTAL_OFFERS_NEW_FBM,
    headerName: ProductViewerHeaderTitles.TOTAL_OFFERS_NEW_FBM,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.TOTAL_OFFERS_NEW_FBM
    )
  },
  {
    field: ProductViewerHeaderFields.TOTAL_OFFERS_USED,
    headerName: ProductViewerHeaderTitles.TOTAL_OFFERS_USED,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.TOTAL_OFFERS_USED)
  },
  {
    field: ProductViewerHeaderFields.TOTAL_OFFERS_USED_FBA,
    headerName: ProductViewerHeaderTitles.TOTAL_OFFERS_USED_FBA,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.TOTAL_OFFERS_USED_FBA
    )
  },
  {
    field: ProductViewerHeaderFields.TOTAL_OFFERS_USED_FBM,
    headerName: ProductViewerHeaderTitles.TOTAL_OFFERS_USED_FBM,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.TOTAL_OFFERS_USED_FBM
    )
  },
  {
    field: ProductViewerHeaderFields.EST_SALES,
    headerName: ProductViewerHeaderTitles.EST_SALES,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.EST_SALES)
  },
  {
    field: ProductViewerHeaderFields.RANK_OF_ROOT_CATEGORY,
    headerName: ProductViewerHeaderTitles.RANK_OF_ROOT_CATEGORY,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(
      TermTitles,
      ProductViewerHeaderFields.RANK_OF_ROOT_CATEGORY
    )
  },
  {
    field: ProductViewerHeaderFields.RATING,
    headerName: ProductViewerHeaderTitles.RATING,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.RATING)
  },
  {
    field: ProductViewerHeaderFields.RATING_COUNT,
    headerName: ProductViewerHeaderTitles.RATING_COUNT,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithIndex(TermTitles, ProductViewerHeaderFields.RATING_COUNT)
  }
];
