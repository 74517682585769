import React from "react";
import ChromeStoreIcon from "static/img/chromeicon.png";
import { CustomPricingTable } from "./plan.style";

export default function AZAlertPlan({ plan, openCheckoutUrl }) {
  if (!plan) {
    return null;
  }
  return (
    // eslint-disable-next-line
    <CustomPricingTable az-alert-container>
      <div className="pricing-table">
        <div className="pricing-plan is-active">
          <div className="plan-header">AZAlert</div>
          <div className="plan-price">
            <span className="plan-price-amount has-text-info">
              <span className="plan-price-currency">$</span>
              {plan.amount}
            </span>
            / Lifetime
          </div>
          <div className="plan-items">
            {plan.contents && plan.contents.length
              ? plan.contents.map((content, index) => (
                  <div key={index} className="plan-item">
                    {content}
                  </div>
                ))
              : null}
          </div>
          <div className="note">
            <strong>Note:</strong> AZAlert and AZInsight are two different tools. AZAlert is a
            standalone Amazon Product Research tool that helps you avoid problematic brands on
            Amazon by alerting you if the brand of product you are on exists in our database that is
            known to file claims. If you already have an AZInsight subscription, you do not need
            this tool as it's already built into AZInsight. Keep in mind AZAlert does NOT include a
            profit calculator, variations viewer, or stock checker.
          </div>
          <div className="plan-footer">
            {plan.subscribed ? (
              <button className="button is-info is-fullwidth" disabled>
                Already purchased
              </button>
            ) : (
              <button className="button is-info is-fullwidth" onClick={() => openCheckoutUrl(plan)}>
                Purchase
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="az-alert-download-wrapper">
        <div>
          <div>
            <strong>Chrome Extension</strong>
          </div>
          <div className="download-icon">
            <a
              href="https://chrome.google.com/webstore/detail/azalert-amazon-brand-rese/akcmigdnnmjdnfhgmhfahecefneifdgg?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ChromeStoreIcon} />
            </a>
          </div>
        </div>
      </div>
    </CustomPricingTable>
  );
}
