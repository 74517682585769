import { LicenseManager } from "ag-grid-enterprise";
import ModuleApp from "./app";
import {
  SignInContainer,
  SignUpContainer,
  AuthorizeMws,
  SignUpBSContainer,
  LoginWithAmazon
} from "./auth/login";
import ResetPasswordContainer from "./auth/resetpassword/ResetPasswordContainer";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-043854 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( asinzen )_is_granted_a_( Single Application )_Developer_License_for_the_application_( asinzen )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( asinzen )_need_to_be_licensed___( asinzen )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 August 2024 )____[v2]_MTcyMzkzNTYwMDAwMA==0a7a38ce949e6b75e3d9bec0ef291742"
);

export {
  ModuleApp,
  SignInContainer,
  SignUpContainer,
  ResetPasswordContainer,
  AuthorizeMws,
  SignUpBSContainer,
  LoginWithAmazon
};
