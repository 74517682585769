/* eslint-disable */
import React from "react";
import { Grid } from "@mui/material";
import numeral from "numeral";
import { CustomPricingTable } from "../../../plans/plan.style";

export default function AZApiPlanOneTime({
  plugins,
  pluginsAdded,
  openRenewConfirmation,
  openStripePortal,
  openCheckoutUrl
}) {
  let availablePlugins = plugins.map(item => {
    const pluginAdded = pluginsAdded.find(
      added => added.pluginId && added.pluginId._id === item._id
    );

    if (pluginAdded) {
      item.added = true;
      item.canceled = pluginAdded.isCancel;
      item.stripeSubId = pluginAdded.stripeSubId;
      item.nextSubscribeAt = pluginAdded.nextSubscribeAt;
    } else {
      item.added = false;
    }

    return item;
  });

  return (
    <CustomPricingTable>
      {(availablePlugins || []).map(item => (
        <div className="pricing-table">
          <div className="pricing-plan is-active">
            <div className="plan-header">One time purchase</div>
            <div className="plan-price">
              <span className="plan-price-amount has-text-info">
                <span className="plan-price-currency">$</span>
                8.00
              </span>
              / package
            </div>
            <div className="note">
              <strong>Note:</strong> You can purchase one-time token any time, any amounts at
              multiple times. The token will be deposited to your account upon purchase successfully
              and it has no expired time.
            </div>
            <div className="plan-footer">
              {/* {item.added ? (
                <button className="button is-info is-fullwidth" disabled>
                  Already purchased
                </button>
              ) : (
                <button
                  className="button is-info is-fullwidth"
                  onClick={() => openCheckoutUrl(item, true)}
                >21
                  Purchase
                </button>
              )} */}
              <button
                className="button is-info is-fullwidth"
                onClick={() => openCheckoutUrl(item, true)}
              >
                Purchase
              </button>
            </div>
          </div>
        </div>
      ))}
    </CustomPricingTable>
  );
}
