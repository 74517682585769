/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import moment from "moment";
import { withStyles } from "@mui/styles";
import { useGoogleLogin } from "@react-oauth/google";

import GoogleBtn from "../../../../../static/img/btn_google.png";

const createQueryParams = params => {
  let str = "";
  Object.keys(params).forEach(value => {
    if (value !== "q") {
      str += `${`${value}=${params[value]}`}&`;
    } else {
      let temp = "";
      Object.keys(params[value]).forEach(key => {
        if (params[value][key]) {
          temp += `${encodeURIComponent(`${key}${params[value][key]}`)} and `;
        }
      });
      str += `${value}=${temp.substring(0, temp.length - 5)}&`;
    }
  });
  return str.length > 0 ? `?${str.substring(0, str.length - 1)}` : "";
};

const styles = () => ({
  btn: {
    backgroundImage: `url(${GoogleBtn})`,
    padding: "20px 82px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    border: "none",

    "&:disabled": {
      opacity: 0.5
    }
  }
});

const GoogleSheets = ({
  onData,
  onError,
  disabled,
  classes,
  openExportModal,
  tableData,
  tableHeaders
}) => {
  const [googleAuth, setGoogleAuth] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [spreadsheets, setSpreadSheets] = useState([]);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState([]);
  const [newSheetName, setNewSheetName] = useState();

  const sheetHeaders = tableHeaders?.map(headerObj => headerObj.headerName);
  const sheetData = tableData?.map(dataObj => Object.values(dataObj));

  const handleCallback = (func, data) => {
    if (typeof func === "function") {
      func(data);
    }
  };

  const googleLogin = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive",
    onSuccess: response => {
      const data = {
        ...response,
        expired_at: moment().add(response.expires_in, "second")
      };
      window.localStorage.setItem("google_auth", JSON.stringify(data));
      setGoogleAuth(data);
    }
  });

  const checkGoogleLogin = () => {
    try {
      const data = JSON.parse(window.localStorage.getItem("google_auth"));
      if (!data || moment(data.expired_at).isBefore(moment())) {
        setGoogleAuth(null);
        setLoaded(true);
      } else {
        setGoogleAuth(data);
        setLoaded(true);
      }
    } catch (e) {
      handleCallback(onError, e);
      setLoaded(true);
    }
  };

  const retrievePageOfFiles = async (results = [], params) => {
    try {
      const { access_token, token_type } = googleAuth;
      const options = {
        method: "GET",
        async: true,
        headers: {
          Authorization: `${token_type} ${access_token}`,
          "Content-Type": "application/json"
        },
        contentType: "json"
      };
      const resp = await fetch(
        `https://www.googleapis.com/drive/v3/files${createQueryParams(params)}`,
        options
      );
      const data = await resp.json();
      const { nextPageToken, files } = data;
      results = results.concat(files);
      if (nextPageToken) {
        params.pageToken = nextPageToken;
        results = await retrievePageOfFiles(results, params);
      }
      return results;
    } catch (e) {
      handleCallback(onError, e);
      return [];
    }
  };

  const handleSelectSpreadsheet = e => {
    setSelectedSpreadsheet(e.target.value);
  };

  const handleSelectSheet = e => {
    setSelectedSheet(e.target.value);
  };

  const getUserSpreadSheets = async () => {
    const files = await retrievePageOfFiles([], {
      q: {
        mimeType: "='application/vnd.google-apps.spreadsheet'"
      },
      includeItemsFromAllDrives: true,
      supportsAllDrives: true
    });
    setSpreadSheets(files);
  };

  const getListSheets = async spreadSheetId => {
    try {
      const { access_token, token_type } = googleAuth;
      const options = {
        method: "GET",
        async: true,
        headers: {
          Authorization: `${token_type} ${access_token}`,
          "Content-Type": "application/json"
        },
        contentType: "json"
      };
      // Set sheet name to Sheet1
      const resp = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadSheetId}`,
        // `https://sheets.googleapis.com/v4/spreadsheets/${spreadSheetId}/values/Sheet1`,
        options
      );
      const data = await resp.json();
      setSheets(data.sheets);
    } catch (e) {
      handleCallback(onError, e);
    }
  };

  const getSheet = async (spreadSheetId, sheetName) => {
    try {
      const { access_token, token_type } = googleAuth;
      const options = {
        method: "GET",
        async: true,
        headers: {
          Authorization: `${token_type} ${access_token}`,
          "Content-Type": "application/json"
        },
        contentType: "json"
      };
      // Set sheet name to Sheet1
      const resp = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadSheetId}/values/${sheetName}`,
        options
      );
      const data = await resp.json();

      onData(data.values);
    } catch (e) {
      handleCallback(onError, e);
    }
  };

  const handleOnClickExport = async () => {
    try {
      const range = `A:${sheetHeaders.length}`;
      const values = [sheetData];
      const sheetName = newSheetName ?? selectedSheet;
      let options = {
        method: "POST",
        async: true,
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json"
        },
        contentType: "json",
        body: JSON.stringify({
          range: `'${sheetName}'!${range}`,
          majorDimension: "ROWS",
          values
        })
      };
      const response = await fetch(
        `https://sheets.googleapis.com/v4/spreadsheets/${selectedSpreadsheet}/values/'${sheetName}'!${range}:append?valueInputOption=USER_ENTERED&insertDataOption=INSERT_ROWS`,
        options
      );
      return true;
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  useEffect(() => {
    checkGoogleLogin();
  }, []);

  useEffect(() => {
    if (googleAuth) {
      getUserSpreadSheets();
    }
  }, [googleAuth]);

  useEffect(() => {
    if (selectedSpreadsheet) {
      getListSheets(selectedSpreadsheet);
    }
  }, [selectedSpreadsheet]);

  useEffect(() => {
    if (selectedSheet) {
      getSheet(selectedSpreadsheet, selectedSheet);
    }
  }, [selectedSheet]);

  return loaded ? (
    googleAuth ? (
      <>
        <FormControl fullWidth>
          <InputLabel id="select-gg-spreadsheet-label">Select a spreadsheet</InputLabel>
          <Select
            disabled={disabled}
            onChange={handleSelectSpreadsheet}
            value={selectedSpreadsheet}
            labelId="select-gg-spreadsheet-label"
            label="Select a spreadsheet"
          >
            {spreadsheets.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <br />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="select-gg-sheet-label">Select a sheet</InputLabel>
          <Select
            disabled={disabled}
            onChange={handleSelectSheet}
            value={selectedSheet}
            labelId="select-gg-sheet-label"
            label="Select a sheet"
            size="small"
          >
            {sheets.map(item => (
              <MenuItem key={item.properties.sheetId} value={item.properties.title}>
                {item.properties.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {openExportModal && (
          <FormControl fullWidth>
            <InputLabel id="select-gg-sheet-label">Input a name for a new sheet</InputLabel>
            <Input onChange={e => setNewSheetName(e.current.value)}></Input>
          </FormControl>
        )}
        {openExportModal && <Button onClick={() => handleOnClickExport()}>Export Now</Button>}
      </>
    ) : (
      <button className={classes.btn} disabled={disabled} onClick={googleLogin} />
    )
  ) : null;
};

export default withStyles(styles)(GoogleSheets);
