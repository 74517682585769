import React from "react";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userChangeEmailRequest } from "redux/actions/UserActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import isEmail from "validator/lib/isEmail";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { withStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { styled } from "@mui/material/styles";

const FormPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

// const signInStyle = theme => ({
//   main: {
//     width: "auto",
//     display: "block", // Fix IE 11 issue.
//     [theme.breakpoints.up(400 + theme.spacing(6))]: {
//       width: 400,
//       marginLeft: "auto",
//       marginRight: "auto"
//     }
//   },
//   paper: {
//     marginTop: theme.spacing(2),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
//   },
//   paperEmail: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: `${theme.spacing(2)}px`
//   },
//   avatar: {},
//   form: {
//     width: "100%" // Fix IE 11 issue.
//   },
//   submit: {
//     marginTop: theme.spacing(3),
//     background: theme.palette.colorLogoPrimary,
//     color: "white",
//     "&:hover": {
//       color: "black"
//     }
//   },
//   label: {
//     "&:focus": {
//       color: "white"
//     }
//   },
//   inputField: {
//     margin: "8px 0px"
//   },
//   focus: {}
// });

class ChangeEmailContainer extends React.Component {
  state = {
    email: "",
    errorMessage: {},
    messageEmail: ""
  };

  constructor(props) {
    super(props);
  }

  submitChangeEmail = () => {
    if (this.props.userState.callingChangeEmail) return;
    if (!isEmail(this.state.email)) {
      return this.setState({ messageEmail: "Email invalid" });
    }
    this.setState({ messageEmail: "" });
    this.props.userChangeEmailRequest({
      email: this.state.email
    });
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.state;
    if (errorMessage.code) {
      return (
        <Grid container spacing={24} justify="center" style={{ color: "red", padding: "15px 0px" }}>
          {errorMessage.message}
        </Grid>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.userState.callingChangeEmail && !this.props.userState.callingChangeEmail) {
      if (this.props.userState.errorMessage.code) {
        enqueueSnackbar(this.props.userState.errorMessage.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          autoHideDuration: 3000
        });
      } else {
        enqueueSnackbar("Change email successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          autoHideDuration: 3000
        });
      }
    }
  }

  handleChangeInput = key => e => {
    this.setState({ [key]: e.target.value }, () => {
      if (key === "repassword" || key === "password") this.comparePassword();
    });
  };

  render() {
    const { email, messageEmail } = this.state;
    const { classes, callingChangeEmail } = this.props;
    return (
      <FormPaper square={false}>
        <Typography variant="body1" textAlign="left" width="100%">
          Update your email
        </Typography>
        {this.renderErrorMessage()}
        <Stack spacing={3} mt={2} sx={{ width: "100%" }}>
          <TextField
            error={!!messageEmail}
            helperText={messageEmail}
            fullWidth
            variant="standard"
            label="New email address"
            type="text"
            value={email}
            onChange={this.handleChangeInput("email")}
          />
        </Stack>
        <SubmitButton
          type="button"
          onClick={this.submitChangeEmail}
          fullWidth
          variant="contained"
          disabled={callingChangeEmail}
        >
          Save
        </SubmitButton>
      </FormPaper>
    );
  }
}

ChangeEmailContainer.defaultProps = {
  userState: userInitState,
  userChangeEmailRequest
};

ChangeEmailContainer.propTypes = {
  userChangeEmailRequest: PropTypes.func,
  userState: PropTypes.shape(userPropTypes)
};

export default connectAutoDispatch(state => ({ userState: state.userState }), {
  userChangeEmailRequest
})(ChangeEmailContainer);
