import React from "react";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userChangePasswordRequest } from "redux/actions/UserActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { withStyles } from "@mui/styles";
import { enqueueSnackbar } from "notistack";
import constants from "utils/constants";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";

import ChangeEmailContainer from "./ChangeEmailContainer";

const FormPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  maxWidth: "400px",
  width: "100%"
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

// const signInStyle = styled(Paper)(({ theme }) => ({
//   main: {
//     width: "auto",
//     display: "block", // Fix IE 11 issue.
//     marginLeft: theme.spacing(3),
//     marginRight: theme.spacing(3),
//     [theme.breakpoints.up(400 + theme.spacing(6))]: {
//       width: 400,
//       marginLeft: "auto",
//       marginRight: "auto"
//     }
//   },
//   paper: {
//     marginTop: theme.spacing(2),
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
//   },
//   paperEmail: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     padding: `${theme.spacing(2)}px`
//   },
//   avatar: {},
//   form: {
//     width: "100%" // Fix IE 11 issue.
//   },
//   submit: {
//     marginTop: theme.spacing(3),
//     background: theme.palette.colorLogoPrimary,
//     color: "white",
//     "&:hover": {
//       color: "black"
//     }
//   },
//   label: {
//     "&:focus": {
//       color: "white"
//     }
//   },
//   inputField: {
//     margin: "8px 0px"
//   },
//   focus: {}
// }));

class ChangePasswordContainer extends React.Component {
  state = {
    repassword: "",
    password: "",
    errorMessage: {},
    messagePassword: "",
    showPassword: false,
    showRepassword: false
  };

  constructor(props) {
    super(props);
  }

  handleSignOut = () => {
    localStorage.removeItem(constants.KEY_LOCAL_TOKEN);
    localStorage.removeItem(constants.KEY_LOCAL_STORAGE);
    this.props.history.push("/sign-in");
  };

  submitChangePassword = () => {
    if (this.props.userState.callingChangePassword) return;
    this.props.userChangePasswordRequest({
      repassword: this.state.repassword,
      password: this.state.password
    });
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.state;
    if (errorMessage.code) {
      return (
        <Grid container spacing={24} justify="center" style={{ color: "red", padding: "15px 0px" }}>
          {errorMessage.message}
        </Grid>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.userState.callingChangePassword && !this.props.userState.callingChangePassword) {
      if (this.props.userState.errorMessage.code) {
        enqueueSnackbar(this.props.userState.errorMessage.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          autoHideDuration: 3000
        });
      } else {
        this.handleSignOut();
        this.props.history.push({
          pathname: "/sign-in",
          state: { resetPasswordSuccess: "Change password successfully" }
        });
      }
    }
  }

  handleChangeInput = key => e => {
    this.setState({ [key]: e.target.value }, () => {
      if (key === "repassword" || key === "password") this.comparePassword();
    });
  };

  comparePassword = () => {
    const { password, repassword } = this.state;
    if (password && repassword) {
      if (password !== repassword) {
        this.setState({
          messagePassword: "Password and repassword don't match"
        });
      } else {
        this.setState({ messagePassword: "" });
      }
    }
  };

  togglePassword = () => {
    const { showPassword } = this.state;
    this.setState({
      showPassword: !showPassword
    });
  };

  toggleRepassword = () => {
    const { showRepassword } = this.state;
    this.setState({
      showRepassword: !showRepassword
    });
  };

  render() {
    const { repassword, password, messagePassword } = this.state;
    console.log(messagePassword);
    const { classes, userState } = this.props;
    return (
      <Container sx={{ marginTop: 2 }}>
        <Stack spacing={2} maxWidth={400} width="100%" m="auto">
          <FormPaper square={false}>
            <Typography variant="h6" style={{ width: "100%", textAlign: "left" }}>
              Email: {userState.userInfo.email}
            </Typography>
          </FormPaper>
          <FormPaper square={false}>
            {this.renderErrorMessage()}
            <Stack spacing={2} sx={{ width: "100%" }}>
              <TextField
                error={!!messagePassword}
                helperText={messagePassword}
                fullWidth
                variant="standard"
                label="Password"
                type={this.state.showPassword ? "text" : "password"}
                value={password}
                onChange={this.handleChangeInput("password")}
                onBlur={this.comparePassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={this.togglePassword}>
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <TextField
                error={messagePassword}
                helperText={messagePassword}
                fullWidth
                variant="standard"
                label="Confirm password"
                type={this.state.showRepassword ? "text" : "password"}
                value={repassword}
                onChange={this.handleChangeInput("repassword")}
                onBlur={this.comparePassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={this.toggleRepassword}>
                        {this.state.showRepassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
            <SubmitButton
              type="button"
              onClick={this.submitChangePassword}
              fullWidth
              variant="contained"
            >
              Change Password
            </SubmitButton>
          </FormPaper>

          <ChangeEmailContainer />
        </Stack>
      </Container>
    );
  }
}

ChangePasswordContainer.defaultProps = {
  userState: userInitState,
  userChangePasswordRequest
};

ChangePasswordContainer.propTypes = {
  userChangePasswordRequest: PropTypes.func,
  userState: PropTypes.shape(userPropTypes)
};

export default connectAutoDispatch(state => ({ userState: state.userState }), {
  userChangePasswordRequest
})(ChangePasswordContainer);
