import React from "react";
import { withStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";

const toolbarStyles = theme => ({
  root: {
    [theme.breakpoints.down(1100)]: {
      flexWrap: "wrap",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.primary,
    flex: "0 0 auto"
  },
  title: {
    flex: "0 0 auto",
    [theme.breakpoints.down(900)]: {
      flex: "unset"
    },
    [theme.breakpoints.down(700)]: {
      flex: "unset",
      "& .MuiTypography-root, & span": {
        fontSize: "1rem",
        lineHeight: 1.2,
        marginTop: 12,
        marginBottom: 12
      }
    }
  },
  buttonPatter: {
    backgroundColor: theme.palette.colorLogoPrimary,
    color: "white",
    "&:hover": {
      color: theme.palette.colorLogoPrimary
    },
    [theme.breakpoints.down(700)]: {
      marginBottom: 12,
      fontSize: "0.75rem"
    }
  }
});

export const styles = theme => {
  return {
    modalConfirmSelect: {
      width: 450,
      [theme.breakpoints.down(700)]: {
        width: "100%",
        padding: theme.spacing(1)
      }
    },
    buttonActive: {
      color: theme.palette.colors.blue
    },
    buttonUnActive: {
      color: theme.palette.colors.red
    },
    root: {
      width: "100%",
      marginTop: theme.spacing(3)
    },
    table: {
      minWidth: 1020
    },
    tableWrapper: {
      overflowX: "auto",
      [theme.breakpoints.down(1100)]: {
        display: "none"
      }
    },
    btnDelete: {
      color: theme.palette.colors.red,
      cursor: "pointer"
    },
    listMemberMobileContainer: {
      [theme.breakpoints.up(1100)]: {
        display: "none"
      },
      [theme.breakpoints.down(1100)]: {
        width: "100%",
        padding: theme.spacing(3)
      }
    },
    listMemberMobileInner: {
      borderTop: "1px solid #000",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    rowMobile: {
      display: "flex",
      alignItems: "center",
      "& >span": {
        wordBreak: "break-word",
        marginLeft: 2
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: 12
    }
  };
};

const TableColtrolBar = props => {
  const { classes, onAddNewMember, userInfo, extension, fieldTotal } = props;
  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Chart of <span style={{ textTransform: "capitalize" }}>{extension}</span>'s Accounts{" "}
          {userInfo && userInfo[fieldTotal] && (
            <span style={{ fontSize: "17px" }}>
              (You can add up to {userInfo[fieldTotal]} active sub accounts). Learn more{" "}
              <a
                href="https://help.asinzen.com/azinsightsubaccounts"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </span>
          )}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        <Button
          onClick={() => onAddNewMember(extension)}
          variant="contained"
          className={classes.buttonPatter}
        >
          <AddIcon /> ADD SUB-ACCOUNT
        </Button>
      </div>
    </Toolbar>
  );
};

export const TableToolBar = withStyles(toolbarStyles)(TableColtrolBar);

const rows = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sort: true
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
    sort: true
  },
  {
    id: "disabledCalculatorSettings",
    numeric: false,
    disablePadding: true,
    label: "Disable Calculator Setting",
    tabValue: "AZINSIGHT",
    sort: false,
    helper:
      "When you check in the box to disable calculator settings, the sub-account will inherit and mirror the calculator settings from the main account"
  },
  {
    id: "accessHistory",
    numeric: false,
    disablePadding: true,
    label: "Access History",
    tabValue: "AZINSIGHT",
    sort: false,
    helper: "Allow your team member to access the web portal history"
  },
  {
    id: "accessGoogleAddon",
    numeric: false,
    disablePadding: true,
    label: "Access Google addon",
    tabValue: "AZINSIGHT",
    sort: false,
    helper:
      "Allow your team member to access Google Addon. Note: The main account needs to log into the Google add-on first so that sub-accounts are able to login."
  },
  // {
  //   id: "accessProducts",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Access Products",
  //   tabValue: "AZINSIGHT",
  //   sort: false,
  //   helper: "Allow your team member to access to Products"
  // },
  // {
  //   id: "accessSeller",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Access Seller",
  //   tabValue: "AZINSIGHT",
  //   sort: false,
  //   helper: "Allow your team member to access to Seller"
  // },
  {
    id: "recordSourcingHistory",
    numeric: false,
    disablePadding: true,
    label: "Record Sourcing History",
    tabValue: "AZINSIGHT"
  },
  {
    id: "status",
    label: "Status",
    tabValue: "AZINSIGHT",
    disablePadding: true,
    sort: true,
    helper:
      "Sub-accounts become inactive when a sub-account plan expires or the number of sub-accounts is decreased on the plan. Once an account becomes inactive it can no longer log in."
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Date created",
    sort: true
  },
  {
    id: "",
    numeric: false,
    disablePadding: true,
    label: "",
    sort: false
  }
];

export class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, tabValue } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">#</TableCell>
          {rows
            .filter(r => !r.tabValue || r.tabValue === tabValue)
            .map(row => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  {row.helper && (
                    <Tooltip
                      title={row.helper}
                      placement={row.numeric ? "bottom-end" : "bottom-start"}
                      enterDelay={300}
                    >
                      <HelpIcon style={{ fontSize: 16 }} />
                    </Tooltip>
                  )}
                  {row.sort ? (
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  ) : (
                    row.label
                  )}
                </Stack>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};
