/*eslint-disable*/
import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { IoIosLogIn } from "react-icons/io";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { withStyles } from "@mui/styles";
import _isEmpty from "lodash/isEmpty";

import { compose } from "redux";
import PropTypes from "prop-types";
import { shallowEqual } from "utils";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userResetPasswordRequest } from "redux/actions/UserActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import Asinzenmainlogo from "static/img/asinzenmainlogo.png";


const styles = theme => ({
  root: {
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      minHeight: 700
    }
  },
  content: {
    minHeight: "100vh"
  },
  center: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f7ff",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 10px",
    height: "unset",
    margin: "0 auto"
  },
  input: {
    overflow: "hidden",
    backgroundColor: "#fff",
    "& input": {
      padding: "12px 16px !important",
      fontSize: 16
    },
    "& fieldset": {
      borderWidth: "1px !important"
    }
  },
  loginBox: {
    backgroundColor: "#fff",
    padding: "20px 4%"
  },
  title: {
    marginTop: 16,
    fontSize: 28
  },
  loginButton: {
    backgroundColor: theme.palette.colors.deepBlue,
    color: theme.palette.colors.white
  },
  loginButtonError: {
    backgroundColor: theme.palette.colors.red,
    color: theme.palette.colors.white
  },
  grayText: {
    fontSize: 16,
    color: theme.palette.colors.grey
  },
  blueText: {
    fontSize: 16,
    color: theme.palette.colors.deepBlue,
    cursor: "pointer"
  },
  item: {
    fontSize: 16
  }
});

class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      password: "",
      isBtnClick: false,
      inputWidth: "unset",
      errorMessage: {},
      messagePassword: "",
      showConfirmPassword: false,
      showPassword: false
    };
  }

  componentDidUpdate() {
    if (this.props.userState.resetPasswordSuccess && _isEmpty(this.props.userState.errorMessage)) {
      this.props.history.push({
        pathname: "/sign-in",
        state: { resetPasswordSuccess: "Reset password successfully" }
      });
    }

    if (!shallowEqual(this.state.errorMessage, this.props.userState.errorMessage)) {
      this.setState({ errorMessage: this.props.userState.errorMessage });
    }
  }

  componentDidMount() {
    const { match: { params = {} } = {} } = this.props;
    if (_isEmpty(params)) {
      this.props.history.push({
        pathname: "/sign-in"
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleForReponsive);
  }

  _handleRedirectLogin = () => {
    this.props.history.push("/sign-in");
  };

  renderErrorMessage = () => {
    const { errorMessage } = this.state;
    if (errorMessage.code === "INVALID") {
      return (
        <Grid container spacing={24} justify="center" style={{ color: "red", padding: "15px 0px" }}>
          {errorMessage.message}
        </Grid>
      );
    }
    if (errorMessage.code && errorMessage.code !== "USER_NOT_AUTHORIZE") {
      return (
        <Grid container spacing={24} justify="center" style={{ color: "red", padding: "15px 0px" }}>
          The server encountered an internal error or misconfiguration and was unable to complete
          your request. Please contact our <a href="https://support.asinzen.com/">Support Team</a>{" "}
          to inform them of the time this error occurred, and the action you performed just before
          this error. Thank you!
        </Grid>
      );
    }
  };

  submitResetPassword = () => {
    const { match: { params = {} } = {} } = this.props;
    if (this.props.userState.callingResetPassword) return;
    this.props.userResetPasswordRequest({
      confirmPassword: this.state.confirmPassword,
      password: this.state.password,
      resetPasswordCode: params.token || ""
    });
  };

  handleChangeInput = key => e => {
    this.setState({ [key]: e.target.value }, () => {
      if (key === "confirmPassword" || key === "password") this.comparePassword();
    });
  };

  comparePassword = () => {
    const { password, confirmPassword } = this.state;
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        this.setState({
          messagePassword: "Password and confirm password don't match"
        });
      } else {
        this.setState({ messagePassword: "" });
      }
    }
  };

  _handleChangeType = key => () => {
    this.setState({ [key]: !this.state[key] });
  };

  render() {
    const { password, isBtnClick, messagePassword, confirmPassword, errorMessage } = this.state;
    const { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid container className={classes.content}>
          <Grid item xs={12} className={classes.center}>
            <Box width="100%" maxWidth="600px" className={classes.loginBox}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <a href="https://asinzen.com/">
                    <img width="300" src={Asinzenmainlogo} />
                  </a>
                </Grid>
                <h1 className={classes.title}>Reset your Password</h1>
                <Grid item xs={12} style={{ minHeight: 28, marginTop: 4 }}>
                  {this.renderErrorMessage()}
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      type={this.state.showPassword ? "text" : "password"}
                      helperText={messagePassword}
                      error={messagePassword}
                      classes={{ root: classes.input }}
                      value={password}
                      onChange={this.handleChangeInput("password")}
                      onBlur={this.comparePassword}
                      placeholder="Password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton size="small" onClick={this._handleChangeType("showPassword")}>
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 12 }}>
                  <FormControl fullWidth>
                    <OutlinedInput
                      type={this.state.showConfirmPassword ? "text" : "password"}
                      helperText={messagePassword}
                      error={!!messagePassword}
                      classes={{ root: classes.input }}
                      value={confirmPassword}
                      onChange={this.handleChangeInput("confirmPassword")}
                      onBlur={this.comparePassword}
                      placeholder="Confirm password"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={this._handleChangeType("showConfirmPassword")}
                          >
                            {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid container justify="center" style={{ marginTop: 8 }}>
                  {messagePassword && <p className="error-text">{messagePassword}</p>}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    disabled={isBtnClick}
                    style={{ marginTop: 24, padding: "8px" }}
                    onClick={this.submitResetPassword}
                    variant="contained"
                    classes={{
                      root:
                        errorMessage?.code === "INVALID"
                          ? classes.loginButtonError
                          : classes.loginButton
                    }}
                  >
                    <IoIosLogIn style={{ marginRight: 5 }} />
                    Reset password
                  </Button>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 12 }}>
                  <span className={classes.blueText} onClick={this._handleRedirectLogin}>
                    Log in
                  </span>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 12 }}>
                  <span className={classes.grayText}>Not registered yet?</span>
                  <span
                    className={classes.blueText}
                    onClick={() => {
                      window.open("https://goto.asinzen.com/azinsightsignup", "self");
                    }}
                  >
                    {" "}
                    Sign Up
                  </span>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ResetPasswordContainer.defaultProps = {
  userState: userInitState,
  userResetPasswordRequest
};

ResetPasswordContainer.propTypes = {
  userResetPasswordRequest: PropTypes.func,
  userState: PropTypes.shape(userPropTypes)
};

const withConect = connectAutoDispatch(state => ({ userState: state.userState }), {
  userResetPasswordRequest
});

export default compose(withConect, withStyles(styles))(ResetPasswordContainer);
