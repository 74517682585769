/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Table, TableBody, TableCell, TableRow, Paper, Select, MenuItem } from "@mui/material";
import { connectAutoDispatch } from "redux/reduxConnect";
import {
  userGetListMemberRequest,
  userDeleteMemberRequest,
  userEditMemberRequest,
  userCopyCalculatorSettingsRequest,
  userSendInstructionsRequest,
  userAccessHistoryRequest,
  userRecordSourcingHistoryRequest
} from "redux/actions/UserActions";
import { enqueueSnackbar } from "notistack";
import { ModalConfirm } from "components/forms";
import shallowEqual from "utils/shallowEqual";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import queryString from "query-string";

import { TableToolBar, EnhancedTableHead, styles } from "./ListMemberContainerStyle";
import ListMember from "./ListMember";
import { ListMemberMobile } from "./ListMemberMobile";
import AZInsightAccounts from "./AZInsightAccounts";
import AZAlertAccounts from "./AZAlertAccounts";

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const marketplaces = ["US", "CA", "MX", "UK", "DE", "IT", "FR", "ES"];

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    const { tab } = queryString.parse((location.search || "?").substring(1));

    this.defaultParams = {
      AZINSIGHT: {
        fieldTotal: "totalPlusToken",
        extension: "AZInsight"
      },
      AZALERT: {
        fieldTotal: "totalPlusTokenAzalert",
        extension: "AZAlert"
      }
      // AZBULKCHECKER: {
      //   fieldTotal: "totalPlusTokenBCP",
      //   extension: "AZBULKCHECKER"
      // }
    };

    this.state = {
      order: "asc",
      orderBy: "calories",
      page: 0,
      rowsPerPage: 5,
      openConfirmDeleteMember: false,
      modalTitle: "",
      modalContent: "",
      selectedMember: null,
      tabValue: this.defaultParams[tab] ? tab : "AZINSIGHT",
      showMarketplace: false,
      subId: null,
      selectedMarketplace: "ALL",
      sendInstructionsConfirm: false,
      instructionsData: null
    };
  }

  componentDidMount() {
    // window.analytics.page("ListMember");
    this._getListMember();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleGetUserData = record => () => {
    this.props.history.push(`/user/edit-member/${record._id}/${this.state.tabValue}`);
  };

  handleSubmitDeleteMember = () => {
    this.props.userDeleteMemberRequest({ id: this.state.selectedMember._id });
    this.setState({ openConfirmDeleteMember: false });
  };

  handleDeleteMember = record => () => {
    this.setState(prevState => ({
      selectedMember: record,
      openConfirmDeleteMember: !prevState.openConfirmDeleteMember,
      modalTitle: `Removing sub-account`,
      modalContent: `Do you really want to delete this sub-account ?`
    }));
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.deleteMemberSuccess && this.props.deleteMemberSuccess) {
      enqueueSnackbar(`Sub-account has been deleted`, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
      this._getListMember();
    }

    const { errorMessage } = this.props;
    if (
      !shallowEqual(prevProps.errorMessage, this.props.errorMessage) &&
      ["EXCEPTION"].indexOf(errorMessage.code) > -1
    ) {
      enqueueSnackbar("Has error when get list member, contact with administrator please", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 9000
      });
    }

    if (
      (this.props.editMemberSuccess &&
        this.props.editMemberSuccess !== prevProps.editMemberSuccess) ||
      (this.props.accessHistoryMemberSuccess &&
        this.props.accessHistoryMemberSuccess !== prevProps.accessHistoryMemberSuccess) ||
      (this.props.recordSourcingHistoryMemberSuccess &&
        this.props.recordSourcingHistoryMemberSuccess !==
          prevProps.recordSourcingHistoryMemberSuccess)
    ) {
      enqueueSnackbar("Member information saved", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
      this._getListMember();
    }

    if (
      this.props.copySettingsSuccess &&
      this.props.copySettingsSuccess !== prevProps.copySettingsSuccess
    ) {
      enqueueSnackbar("Duplication settings successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 1400
      });
    }

    if (
      this.props.sendInstructionsSuccess &&
      this.props.sendInstructionsSuccess !== prevProps.sendInstructionsSuccess
    ) {
      enqueueSnackbar("Send instructions to team member successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 2000
      });
    }
  }

  handleChange = (value, key) => {
    this.setState({ [key]: value }, this._getListMember);
  };

  _getListMember = () => {
    this.props.userGetListMemberRequest({ extension: this.state.tabValue });
  };

  editMember = (e, data, key = null) => {
    if (this.props.callingEditMember) {
      return;
    }
    const { checked } = e.target;
    const { firstName, lastName, _id } = data;

    if (key) data[key] = checked;
    this.props.userEditMemberRequest({
      id: _id,
      firstName,
      lastName,
      disabledCalculatorSettings: data.disabledCalculatorSettings,
      accessHistory: data.accessHistory,
      accessGoogleAddon: data.accessGoogleAddon,
      accessProducts: data.accessProducts,
      accessSeller: data.accessSeller
    });
  };

  accessHistoryMember = (e, data) => {
    if (this.props.callingAccessHistoryMember) {
      return;
    }
    const { checked } = e.target;
    const { _id } = data;
    this.props.userAccessHistoryRequest({
      id: _id,
      accessHistory: checked
    });
  };

  recordSourcingHistoryMember = (e, data) => {
    if (this.props.callingRecordSourcingHistoryMember) {
      return;
    }
    const { checked } = e.target;
    const { _id } = data;
    this.props.userRecordSourcingHistoryRequest({
      id: _id,
      recordSourcingHistory: checked
    });
  };

  selectMarketplace = sub => {
    this.setState({
      subId: sub._id,
      showMarketplace: true
    });
  };

  closeMarketplace = () => {
    this.setState({
      subId: null,
      showMarketplace: false
    });
  };

  marketplaceChange = e => {
    this.setState({
      selectedMarketplace: e.target.value
    });
  };

  handleCopyCalculatorSettings = async () => {
    try {
      if (this.props.callingCopySettings) {
        return;
      }
      const { subId, selectedMarketplace } = this.state;
      this.props.userCopyCalculatorSettingsRequest({
        subId,
        marketplaces: selectedMarketplace === "ALL" ? marketplaces : [selectedMarketplace]
      });
    } catch (e) {
    } finally {
      this.setState({
        showMarketplace: false
      });
    }
  };

  sendEmailInstructions = record => {
    if (this.props.callingSendInstructions) {
      return;
    }
    this.setState({
      sendInstructionsConfirm: true,
      instructionsData: {
        email: record.email,
        password: "[Please ask main account for the password]"
      }
    });
  };

  handleSubmitSendInstructions = () => {
    const { instructionsData } = this.state;
    this.props.userSendInstructionsRequest({
      ...instructionsData
    });
    this.handleCloseSendInstructionsConfirm();
  };

  handleCloseSendInstructionsConfirm = () => {
    this.setState({
      sendInstructionsConfirm: false,
      instructionsData: null
    });
  };

  render() {
    const { classes, userInfo } = this.props;
    const {
      order,
      orderBy,
      openConfirmDeleteMember,
      modalTitle,
      modalContent,
      tabValue,
      showMarketplace,
      selectedMarketplace
    } = this.state;
    const { listMember } = this.props;

    return (
      <Paper className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={(e, tab) => this.handleChange(tab, "tabValue")}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Azinsight" value="AZINSIGHT" />
          <Tab label="Azalert" value="AZALERT" />
          {/* {userInfo.totalPlusTokenBCP > 0 && <Tab label="BULK CHECKER PRO" value="AZBULKCHECKER" />} */}
        </Tabs>

        <TableToolBar
          userInfo={userInfo}
          onAddNewMember={extension => this.props.history.push(`/user/add-new-member/${extension}`)}
          {...this.defaultParams[tabValue]}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              tabValue={tabValue}
            />
            <TableBody>
              {stableSort(listMember, getSorting(order, orderBy)).map((record, index) => (
                <>
                  {tabValue === "AZINSIGHT" && (
                    <AZInsightAccounts
                      record={record}
                      index={index}
                      editMember={this.editMember}
                      selectMarketplace={this.selectMarketplace}
                      handleGetUserData={this.handleGetUserData}
                      sendEmailInstructions={this.sendEmailInstructions}
                      handleDeleteMember={this.handleDeleteMember}
                      classes={classes}
                    />
                  )}
                  {tabValue === "AZALERT" && (
                    <AZAlertAccounts
                      record={record}
                      index={index}
                      handleGetUserData={this.handleGetUserData}
                      handleDeleteMember={this.handleDeleteMember}
                      classes={classes}
                    />
                  )}
                </>
              ))}
              {listMember.length > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className={classes.listMemberMobileContainer}>
          {listMember.map((record, index) => (
            <ListMemberMobile
              tabValue={tabValue}
              record={record}
              index={index}
              editMember={this.editMember}
              selectMarketplace={this.selectMarketplace}
              handleGetUserData={this.handleGetUserData}
              sendEmailInstructions={this.sendEmailInstructions}
              handleDeleteMember={this.handleDeleteMember}
              classes={classes}
            />
          ))}
        </div>

        <ModalConfirm
          open={openConfirmDeleteMember}
          onClose={this.handleDeleteMember(null)}
          onConfirm={this.handleSubmitDeleteMember}
          title={modalTitle}
          btnSaveTitle="YES"
          Content={() => <div>{modalContent}</div>}
        />
        <ModalConfirm
          open={showMarketplace}
          onClose={this.closeMarketplace}
          onConfirm={this.handleCopyCalculatorSettings}
          title={modalTitle}
          btnSaveTitle="OK"
          Content={() => (
            <div className={classes.modalConfirmSelect}>
              <strong style={{ marginBottom: 12, display: "inline-flex" }}>
                Select marketplace of main account to mirror to sub-account
              </strong>
              <Select
                value={selectedMarketplace}
                onChange={this.marketplaceChange}
                inputProps={{
                  name: "marketplace",
                  id: "age-auto-width"
                }}
                fullWidth
              >
                <MenuItem key={"ALL"} value={"ALL"}>
                  All Marketplaces
                </MenuItem>
                {marketplaces.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        />
        <ModalConfirm
          btnSaveTitle="Send"
          btnCancelTitle="No"
          open={this.state.sendInstructionsConfirm}
          title="Send instructions to team member"
          onClose={this.handleCloseSendInstructionsConfirm}
          onConfirm={this.handleSubmitSendInstructions}
          Content={() => (
            <div>
              Would you want to email this team member the email and password to log in with
              Instructions on how to install the extension
            </div>
          )}
        />
      </Paper>
    );
  }
}

EnhancedTable.defaultProps = {
  userGetListMemberRequest: null,
  listMember: [],
  errorMessage: {}
};

EnhancedTable.propTypes = {
  errorMessage: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  }),
  listMember: PropTypes.array,
  userGetListMemberRequest: PropTypes.func,
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired
};

export default connectAutoDispatch(
  ({ userState }) => ({
    callingDeleteMember: userState.callingDeleteMember,
    deleteMemberSuccess: userState.deleteMemberSuccess,
    errorMessage: userState.errorMessage,
    listMember: userState.listMember,
    userInfo: userState.userInfo,
    callingEditMember: userState.callingEditMember,
    callingAccessHistoryMember: userState.callingAccessHistoryMember,
    editMemberSuccess: userState.editMemberSuccess,
    accessHistoryMemberSuccess: userState.accessHistoryMemberSuccess,
    recordSourcingHistoryMemberSuccess: userState.recordSourcingHistoryMemberSuccess,
    callingRecordSourcingHistoryMember: userState.callingRecordSourcingHistoryMember,
    callingCopySettings: userState.callingCopySettings,
    copySettingsSuccess: userState.copySettingsSuccess,
    callingSendInstructions: userState.callingSendInstructions,
    sendInstructionsSuccess: userState.sendInstructionsSuccess
  }),
  {
    userGetListMemberRequest,
    userDeleteMemberRequest,
    userEditMemberRequest,
    userCopyCalculatorSettingsRequest,
    userSendInstructionsRequest,
    userAccessHistoryRequest,
    userRecordSourcingHistoryRequest
  }
)(withStyles(styles)(EnhancedTable));
