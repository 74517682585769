import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connectAutoDispatch } from "redux/reduxConnect";
import { withRouter } from "react-router-dom";
import moment from "moment";
import numeral from "numeral";
import { CustomPricingTable } from "./plan.style";
import { getProductPlanRequest, getCurrentPluginsAddedRequest } from "redux/actions/PlanActions";
import { getUserInfoAppRequest } from "redux/actions/UserActions";
import type { TableColumnsType } from "antd";
import { Flex, Space, Table } from "antd";

interface DataType {
  key: React.Key;
  title: string;
}

interface PriceDataType {
  key: React.Key;
  from: string;
  to: number;
  price: string;
}

const AZINSIGHT_PLUS_ADDITIONAL_LOGIN: TableColumnsType<PriceDataType> = [
  {
    key: "az-plus-login-1",
    from: 1,
    to: 5,
    price: 13
  },
  {
    key: "az-plus-login-2",
    from: 6,
    to: 10,
    price: 12
  },
  {
    key: "az-plus-login-3",
    from: 11,
    to: "∞",
    price: 10
  }
];

const AZINSIGHT_PLUS_ADDITIONAL_LOGIN_MONTHLY: TableColumnsType<DataType> = [
  {
    key: "az-plus-login-monthly-1",
    from: 1,
    to: 5,
    price: 1.7
  },
  {
    key: "az-plus-login-monthly-2",
    from: 6,
    to: 10,
    price: 1.6
  },
  {
    key: "az-plus-login-monthly-3",
    from: 11,
    to: "∞",
    price: 1.4
  }
];

const AZINSIGHT_PRO_ADDITIONAL_LOGIN: TableColumnsType<DataType> = [
  {
    key: "az-pro-login-1",
    from: 1,
    to: 5,
    price: 35
  },
  {
    key: "az-pro-login-2",
    from: 6,
    to: 10,
    price: 30
  },
  {
    key: "az-pro-login-3",
    from: 11,
    to: "∞",
    price: 25
  }
];

const AZINSIGHT_PRO_ADDITIONAL_LOGIN_MONTHLY: TableColumnsType<DataType> = [
  {
    key: "az-pro-login-monthly-1",
    from: 1,
    to: 5,
    price: 4.5
  },
  {
    key: "az-pro-login-monthly-2",
    from: 6,
    to: 10,
    price: 4
  },
  {
    key: "az-pro-login-monthly-3",
    from: 11,
    to: "∞",
    price: 3.5
  }
];

const childColumns: TableColumnsType<DataType> = [
  {
    title: "From",
    dataIndex: "from",
    key: "from"
  },
  {
    title: "To",
    dataIndex: "to",
    key: "to"
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    render: value => `$${value}`
  }
];

const columns: TableColumnsType<DataType> = [
  {
    title: "Tiered quantity discounts",
    children: childColumns
  }
];

const PRICE_TABLES = {
  AZINSIGHT_PLUS_ADDITIONAL_LOGIN,
  AZINSIGHT_PLUS_ADDITIONAL_LOGIN_MONTHLY,
  AZINSIGHT_PRO_ADDITIONAL_LOGIN,
  AZINSIGHT_PRO_ADDITIONAL_LOGIN_MONTHLY
};

const defaultAvailablePlugins = [
  {
    _id: "annually",
    intervalLables: "Annually",
    price: 35,
    interval: "year",
    content:
      "Additional login license for AZInsight Advanced. Each additional login license will allow a user to log in using a unique email and password.",
    code: "AZINSIGHT_PRO_ADDITIONAL_LOGIN"
  },
  {
    _id: "monthly",
    intervalLables: "Monthly",
    price: 4.5,
    interval: "month",
    content:
      "Additional login license for AZInsight Advanced. Each additional login license will allow a user to log in using a unique email and password.",
    code: "AZINSIGHT_PRO_ADDITIONAL_LOGIN_MONTHLY"
  }
];

const AZInsightSubAccounts = ({
  plugins,
  userState,
  planState,
  intervalLables,
  openRenewConfirmation,
  openStripePortal,
  openCheckoutUrl
}) => {
  const [availablePlugins, setAvailablePlugins] = useState([]);
  const [subscribed, setSubscribed] = useState();

  const { userInfo } = userState;
  const { currentPlans, pluginsAdded } = planState;

  useEffect(() => {
    let currentAvailable = plugins.map(item => {
      const pluginAdded = pluginsAdded.find(
        added => added.pluginId && added.pluginId._id === item._id
      );

      if (pluginAdded) {
        item.added = true;
        item.canceled = pluginAdded.isCancel;
        item.stripeSubId = pluginAdded.stripeSubId;
        item.nextSubscribeAt = pluginAdded.nextSubscribeAt;
      } else {
        item.added = false;
      }

      return item;
    });

    const subscribedCurrent = currentPlans.data.find(item => item.id === userInfo.stripePriceKey);

    if (subscribedCurrent && subscribedCurrent.interval.toLowerCase() !== "year") {
      currentAvailable = currentAvailable.filter(
        item => item.interval.toLowerCase() === subscribedCurrent.interval.toLowerCase()
      );
    }

    setAvailablePlugins(() => {
      setSubscribed(subscribedCurrent);
      return currentAvailable;
    });
  }, [pluginsAdded, plugins, userInfo, currentPlans]);

  return (
    <Space style={{ width: "100%", textAlign: "center", justifyContent: "center" }}>
      {!subscribed ? (
        <Flex gap="middle" vertical align="center" justify="center" style={{ width: "100%" }}>
          <Space direction="vertical" size={2} style={{ width: "100%" }}>
            <div>Your plan comes with 2 sub-accounts by default.</div>
            <div>
              Click{" "}
              <a
                href="https://help.asinzen.com/azinsightsubaccounts"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              to learn how to manage your sub-accounts.
            </div>
            <div>
              If you need additional sub-accounts, you need to{" "}
              <strong>first subscribe to a plan</strong>.
            </div>

            <div>
              <Flex className="pricing-table plugin-list" style={{ flexFlow: "row" }}>
                {defaultAvailablePlugins.map(item => (
                  <div
                    key={item._id}
                    className={`pricing-plan${
                      subscribed?.interval.toLowerCase() === item.interval.toLowerCase()
                        ? "is-active"
                        : ""
                    }`}
                  >
                    <div className="plan-header">Add Sub-accounts {item.intervalLables}</div>
                    <div className="plan-price">
                      <span className="plan-price-amount has-text-info">
                        {numeral(item.price).format("0,0.00")}/{item.interval}/sub-account
                      </span>
                    </div>
                    <div className="plan-items">
                      {item.content ? <div className="plan-item">{item.content}</div> : null}
                      <div className="plan-item">
                        <Table
                          columns={columns}
                          dataSource={PRICE_TABLES[item.code]}
                          bordered
                          size="small"
                          pagination={false}
                        />
                        {/* <table className="table is-bordered is-hoverable is-fullwidth">
                          <thead>
                            <tr>
                              <th colSpan={4}>Tiered quantity discounts</th>
                            </tr>
                            <tr className="has-text-left">
                              <th>&nbsp;</th>
                              <th>From</th>
                              <th>To</th>
                              <th className="has-text-right">Per sub-account</th>
                            </tr>
                          </thead>
                          <tbody className="has-text-left">
                            {PRICE_TABLES[item.code].map((item, index) => (
                              <tr key={`key-${index}`}>
                                <td>{index === 0 ? "FOR THE FIRST" : "FOR THE NEXT"}</td>
                                <td>{item.from}</td>
                                <td>{item.to}</td>
                                <td className="has-text-right">
                                  ${numeral(item.price).format("0,0.00")}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table> */}
                      </div>
                    </div>
                  </div>
                ))}
              </Flex>
            </div>
          </Space>
        </Flex>
      ) : (
        <Flex gap="middle" vertical align="center" justify="center" style={{ width: "100%" }}>
          <Space direction="vertical" size={2} style={{ width: "100%" }}>
            <div>
              Current subscribed plan:{" "}
              <strong>
                {subscribed.title} / {intervalLables[subscribed.interval.toLowerCase()]}
              </strong>
            </div>
            <div>
              Total sub-accounts you have: <strong>{userInfo.totalPlusToken}</strong>
            </div>
            <div>
              Click{" "}
              <a
                href="https://gethelp.asinzen.com/manageteam"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              to learn how to manage your sub-accounts.
            </div>
            <div>
              Your plan allows you to purchase additional sub-accounts monthly{" "}
              {subscribed.interval.toLowerCase() === "year" ? "or annually" : null}
            </div>
          </Space>

          <Flex className="pricing-table plugin-list" style={{ flexFlow: "row" }}>
            {availablePlugins.map(item => (
              <div
                key={item._id}
                className={`pricing-plan${
                  subscribed?.interval.toLowerCase() === item.interval.toLowerCase()
                    ? " is-active"
                    : ""
                }`}
                style={{ margin: "1.5em", maxWidth: 600 }}
              >
                <div className="plan-header">
                  Add Sub-accounts {intervalLables[item.interval.toLowerCase()]}
                </div>
                <div className="plan-price">
                  <span className="plan-price-amount has-text-info">
                    {numeral(item.price).format("0,0.00")}/{item.interval}/sub-account
                  </span>
                </div>
                <div className="plan-items">
                  {item.content ? <div className="plan-item">{item.content}</div> : null}
                  <div className="plan-item">
                    <Table
                      columns={columns}
                      dataSource={PRICE_TABLES[item.code]}
                      bordered
                      size="small"
                      pagination={false}
                    />
                  </div>
                  {item.added ? (
                    <>
                      {item.canceled ? (
                        <div className="plan-item">
                          Your subscription is active until{" "}
                          <strong>{moment(item.nextSubscribeAt).format("MMMM DD, YYYY")}</strong>{" "}
                          and will <strong>NOT</strong> auto-renew. If you wish to automatically
                          renew your subscription, please click{" "}
                          <a
                            href="#"
                            onClick={e =>
                              openRenewConfirmation(e, {
                                subId: item.stripeSubId,
                                cancel: false
                              })
                            }
                          >
                            here.
                          </a>
                        </div>
                      ) : (
                        <div className="plan-item">
                          Your subscription is active until{" "}
                          <strong>{moment(item.nextSubscribeAt).format("MMMM DD, YYYY")}</strong>{" "}
                          and will auto-renew. If you wish to cancel auto-renew on your
                          subscription, please click{" "}
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              openStripePortal();
                            }}
                          >
                            here.
                          </a>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="plan-footer">
                  {!item.added ? (
                    <button
                      className="button is-info is-fullwidth"
                      onClick={() => openCheckoutUrl(item)}
                    >
                      Purchase Sub-account Subscription
                    </button>
                  ) : (
                    <button
                      className="button is-info is-fullwidth"
                      onClick={e =>
                        item.canceled
                          ? openRenewConfirmation(e, {
                              subId: item.stripeSubId,
                              cancel: false
                            })
                          : openStripePortal()
                      }
                    >
                      {item.canceled ? "Enable Auto Renew" : "Update or Cancel"} Sub-account
                      Subscription
                    </button>
                  )}
                </div>
              </div>
            ))}
          </Flex>
        </Flex>
      )}
    </Space>
  );
};

AZInsightSubAccounts.propTypes = {
  plugins: PropTypes.string,
  intervalLables: PropTypes.object,
  openRenewConfirmation: PropTypes.func,
  openCheckoutUrl: PropTypes.func,
  openStripePortal: PropTypes.func
};

AZInsightSubAccounts.defaultProps = {
  plugins: null,
  intervalLables: null,
  openRenewConfirmation: null,
  openCheckoutUrl: null,
  openStripePortal: null
};

const withConnect = connectAutoDispatch(
  ({ userState, orderState, planState, stripeState }) => ({
    userState,
    planState
  }),
  {
    getProductPlanRequest,
    getCurrentPluginsAddedRequest,
    getUserInfoAppRequest
  }
);

export default compose(withRouter, withConnect)(AZInsightSubAccounts);
