import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

const styles = theme => ({
  inputField: {
    margin: "8px 0px"
  },
  buttonCancel: {
    color: theme.palette.colorLogoSecondary
  },
  buttonSave: {
    color: theme.palette.colorLogoPrimary
  }
});

class ModalConfirm extends React.Component {
  handleClose = () => {
    this.props.onClose && this.props.onClose(false);
  };

  handleConfirm = () => {
    this.props.onConfirm && this.props.onConfirm();
  };

  render() {
    const { title, Content, classes, btnSaveTitle, btnCancelTitle } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Content />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} className={classes.buttonCancel}>
            {btnCancelTitle}
          </Button>
          <Button onClick={this.handleConfirm} className={classes.buttonSave}>
            {btnSaveTitle}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ModalConfirm.defaultProps = {
  title: "",
  open: false,
  onClose: null,
  onConfirm: null,
  btnSaveTitle: "Save",
  btnCancelTitle: "Cancel",
};
ModalConfirm.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  btnSaveTitle: PropTypes.string,
  btnCancelTitle: PropTypes.string,
};

export default withStyles(styles)(ModalConfirm);
