import React from "react";
import { Modal, Paper, Typography } from "@mui/material";

const ImportDataModal = ({ title, children, isOpen, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Paper
        sx={{
          maxWidth: 800,
          maxHeight: 600,
          margin: "20px auto",
          padding: 3,
          overflowY: "auto"
        }}
      >
        <Typography variant="h5" fontWeight="bold" textAlign="center">
          Import
        </Typography>
        {children}
      </Paper>
    </Modal>
  );
};

export default ImportDataModal;
