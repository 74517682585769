import React from "react";
import { Select, Typography, FormControl, InputLabel, NativeSelect, Paper, Stack, Box } from "@mui/material";
import { spacing } from "@mui/system"

class AZApiAppBar extends React.Component {
  state = {
    marketplace: "US"
  };

  handleChange = event => {
    this.setState({ marketplace: event.target.value });
    this.props?.onChangeMarketplace &&
      this.props.onChangeMarketplace({ marketplace: event.target.value });
  };

  componentDidMount() {
    this.props?.onChangeMarketplace &&
      this.props.onChangeMarketplace({ marketplace: this.state.marketplace });
  }

  render() {
    const { title } = this.props;
    const { marketplace } = this.state;
    return (
      <Paper elevation={0}>
        <Stack px={3} py={1} direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="h5" textAlign="left">
              <b>{title}</b>
            </Typography>
            {this.props.children}
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center">
            <FormControl>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">Marketplace</InputLabel>
              <NativeSelect
                id="demo-simple-select"
                value={marketplace}
                onChange={this.handleChange}
              >
                <option value="US">Amazon.com - US</option>
                <option value="CA">Amazon.ca - CA</option>
                <option value="MX">Amazon.com.mx - MX</option>
                <option value="UK">Amazon.co.uk - UK</option>
                <option value="DE">Amazon.de - DE</option>
                <option value="ES">Amazon.es - ES</option>
                <option value="IT">Amazon.it - IT</option>
                <option value="FR">Amazon.fr - FR</option>
              </NativeSelect>
            </FormControl>
            <Box textAlign="center">
              <Typography fontWeight="bold">Token left:</Typography> 234,567</Box>
          </Stack>
        </Stack>
      </Paper>
    );
  }
}

export default AZApiAppBar
