import React, { useEffect, useState } from "react";
import { Row, Col, Form, Dropdown, Button, InputNumber } from "antd";
import AZApiAppBar from "../AZApiAppBar";
import { MARKETPLACES, MARKETPLACES_CONFIG } from "constants/asinzen-api/common";

import "./style.css";

const initialState = {
  marketplace: "US",
  data: {
    buybox_new_price: {
      min: null,
      max: null
    },
    buybox_used_price: {
      min: null,
      max: null
    },
    amazon_price: {
      min: null,
      max: null
    },
    amazon_warehouse_price: {
      min: null,
      max: null
    },
    lowest_price_new_fba: {
      min: null,
      max: null
    },
    lowest_price_new_fbm: {
      min: null,
      max: null
    },
    lowest_price_used_fba: {
      min: null,
      max: null
    },
    lowest_price_used_fbm: {
      min: null,
      max: null
    },
    lowest_price_used_like_new: {
      min: null,
      max: null
    },
    lowest_price_used_very_good: {
      min: null,
      max: null
    },
    lowest_price_used_good: {
      min: null,
      max: null
    },
    lowest_price_used_acceptable: {
      min: null,
      max: null
    },
    total_offers_new: {
      min: null,
      max: null
    },
    total_offers_used: {
      min: null,
      max: null
    },
    rank_of_root_category: {
      min: null,
      max: null
    },
    review_count: {
      min: null,
      max: null
    },
    rating: {
      min: null,
      max: null
    }
  },
  fields: {
    buybox_new_price: "New, Buy box",
    buybox_used_price: "Used, Buy box",
    amazon_price: "Amazon price",
    amazon_warehouse_price: "Amazon warehouse",
    lowest_price_new_fba: "New, 3rd party FBA",
    lowest_price_new_fbm: "New, 3rd party FBM",
    lowest_price_used_fba: "Used, 3rd party FBA",
    lowest_price_used_fbm: "Used, 3rd party FBM",
    lowest_price_used_like_new: "Used, Likenew",
    lowest_price_used_very_good: "Used, Very Good",
    lowest_price_used_good: "Used, Good",
    lowest_price_used_acceptable: "Used, Acceptable",
    total_offers_new: "New Offer Count",
    total_offers_used: "Used Offer Count",
    rank_of_root_category: "Ranks",
    review_count: "Review count",
    rating: "Rating"
  },
  status: "idle"
};

const ProductTracking = () => {
  const [state, setState] = useState(initialState);
  const [form] = Form.useForm();
  const { fields } = initialState;

  const { iconMoney } = MARKETPLACES_CONFIG[state.marketplace];

  const handleOnChangeMarketplace = mkp =>
    setState(prevState => ({ ...prevState, marketplace: mkp.marketplace }));

  const handleSubmit = values => {
    const criteria = Object.keys(values).map(field => ({
      field,
      min: values[field].min || null,
      max: values[field].max || null
    }));
  };

  return (
    <div>
      <AZApiAppBar title="Product Tracking" onChangeMarketplace={handleOnChangeMarketplace} />
      <Row className="zg-track-product">
        <Col lg={{ span: 16 }}>
          <Form
            form={form}
            name="trackProduct"
            layout="vertical"
            initialValues={state}
            onFinish={handleSubmit}
          >
            <Row>
              {Object.keys(fields).map(field => (
                <Col span={8} key={field}>
                  <Row>
                    <Col xs={24}>
                      <Row>
                        <Col>
                          <strong className="ant-form-label">{fields[field]}</strong>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12}>
                      <Form.Item name={[field, "min"]}>
                        <InputNumber
                          placeholder="Min"
                          style={{ width: "100%" }}
                          prefix={iconMoney}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item name={[field, "max"]}>
                        <InputNumber
                          placeholder="Max"
                          style={{ width: "100%" }}
                          prefix={iconMoney}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              ))}

              <Col span={24}>
                <Form.Item label="&nbsp;">
                  <Button type="primary" htmlType="submit">
                    Start Tracking
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ProductTracking;
