import React from "react";
import ReactDOM from "react-dom";
import "bulma-pricingtable/dist/css/bulma-pricingtable.min.css";
import App from "components/layouts/App";
import "static/style.css";
import "static/fontawesome/css/font-awesome.min.css";

/** detect to redicet to zengraph */
if (window.location.pathname === "/zengraph" || window.location.pathname === "/zengraph/") {
  window.location.href = `https://zengraph.asinzen.com/${window.location.search}`;
} else {
  ReactDOM.render(<App />, document.getElementById("appAsinZen"));
}
