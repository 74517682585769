export const AZApiStyle = () => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  formControl: {
    margin: 4,
    minWidth: 196
  },
  rightBar: {
    display: "flex"
  },
  token: {
    whiteSpace: "nowrap",
    alignSelf: "end",
    marginLeft: 8,
    fontSize: 16
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  }
});
