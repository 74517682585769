import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { FaFileExport, FaFileImport, FaTrash, FaRefresh } from "react-icons/fa";
import { FcRefresh } from "react-icons/fc";

const ToolsTable = ({ onExport, onImport, onDelete, onRefresh }) => {
  const handleOnExportCsv = () => {
    onExport("csv");
  };

  const handleOnExportExcel = () => {
    onExport("excel");
  };

  const handleOnExportGoogleSheet = () => {
    onExport("sheet");
  };

  const handleOnDelete = () => {
    onDelete();
  };

  const handleOnRefresh = () => {
    onRefresh();
  };

  const handleOnImport = () => {
    onImport(true);
  };

  return (
    <Stack direction="row" gap={3}>
      {onImport && (
        <Button sx={{ display: "block", textTransform: "unset" }} onClick={handleOnImport}>
          <FaFileImport />
          <br />
          <Typography sx={{ fontSize: 14 }}>Import</Typography>
        </Button>
      )}
      <Button sx={{ display: "block", textTransform: "unset" }} onClick={handleOnExportCsv}>
        <FaFileExport />
        <br />
        <Typography sx={{ fontSize: 14 }}>Export to CSV</Typography>
      </Button>
      <Button sx={{ display: "block", textTransform: "unset" }} onClick={handleOnExportExcel}>
        <FaFileExport />
        <br />
        <Typography sx={{ fontSize: 14 }}>Export to Excel</Typography>
      </Button>
      <Button sx={{ display: "block", textTransform: "unset" }} onClick={handleOnExportGoogleSheet}>
        <FaFileExport />
        <br />
        <Typography sx={{ fontSize: 14 }}>Export to Google Sheet</Typography>
      </Button>
      <Button sx={{ display: "block", textTransform: "unset" }} onClick={handleOnDelete}>
        <FaTrash />
        <br />
        <Typography sx={{ fontSize: 14 }}>Delete</Typography>
      </Button>
      <Button sx={{ display: "block", textTransform: "unset" }} onClick={handleOnRefresh}>
        <FcRefresh />
        <br />
        <Typography sx={{ fontSize: 14 }}>Refresh data</Typography>
      </Button>
    </Stack>
  );
};

export default ToolsTable;
