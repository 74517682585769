/*eslint-disable*/
import axios from "utils/axios";
import { saveLocalStorage } from "utils";
import constants from "utils/constants";
import _isEmpty from "lodash/isEmpty";
import isEmail from "validator/lib/isEmail";
import CustomError from "./CustomError";
import ProcessError from "./ProcessError";

const baseURL = `${process.env.AZ_API_URL_NEW}/portal`;

const authorizeSpBaseUrl = `${process.env.AZ_API_URL_NEW}/authorize-sp`;

export const apiUserSignin = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: "/sign-in-portal",
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status && status === 200) {
        data.enableAsinTracker = [
          "asinzenteam@asinzen.com",
          "asinzendemo@asinzen.com",
          "upclientele@gmail.com",
          "dkc3@comcast.net",
          "royce@7QProducts.com",
          "matteo@tweetybeauty.com"
        ].includes(data.email);
        await saveLocalStorage(constants.KEY_LOCAL_TOKEN, data.token);
        await saveLocalStorage(constants.KEY_LOCAL_STORAGE, JSON.stringify(data));
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiUserGetListMember = async payload => {
  return axios({
    method: "GET",
    baseURL,
    url: `user/get-list-member/${payload.extension}`
  })
    .then(({ status, data }) => {
      if (status && status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiUserAddMember = async payload => {
  const { firstName, lastName, email, password, repassword } = payload;
  const errors = {
    code: "INVALID",
    message: {}
  };

  if (_isEmpty(firstName)) errors.message.firstName = "First name is require";
  if (_isEmpty(lastName)) errors.message.lastName = "Last name is require";

  if (_isEmpty(email)) {
    errors.message.email = "Email is require";
  } else if (!isEmail(email)) {
    errors.message.email = "Email is require";
  }

  if (_isEmpty(repassword)) {
    errors.message.password = "Password is require";
  }

  if (_isEmpty(repassword)) {
    errors.message.repassword = "Repassword is require";
  } else if (password !== repassword) {
    errors.message.repassword = "Password and repassword don't match";
  }

  if (!_isEmpty(errors.message)) {
    throw new CustomError(errors);
  }

  return axios({
    method: "POST",
    baseURL,
    url: "user/add-member",
    data: payload
  })
    .then(({ status, data }) => {
      if (status && status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: { fullName: "Error can not request server" }
      });
    })
    .catch(ProcessError);
};

export const apiGetUserData = async payload => {
  return axios({
    method: "GET",
    baseURL,
    url: `user/get-user-data/${payload.id}`
  })
    .then(({ status, data }) => {
      if (status && status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiUserEditMember = async payload => {
  const { firstName, lastName, password, repassword } = payload;
  const errors = {
    code: "INVALID",
    message: {}
  };

  if (_isEmpty(firstName)) {
    errors.message.firstName = "First name is required";
  }

  if (_isEmpty(lastName)) {
    errors.message.lastName = "Last name is required";
  }

  if (!_isEmpty(password) || !_isEmpty(repassword)) {
    if (password !== repassword) {
      errors.message.repassword = "Password and repassword don't match";
    }
  }
  if (!_isEmpty(errors.message)) {
    throw new CustomError(errors);
  }

  return axios({
    method: "POST",
    baseURL,
    url: `user/edit-member/${payload.id}`,
    data: payload
  })
    .then(({ status, data }) => {
      if (status && status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: { fullName: "Error can not request server" }
      });
    })
    .catch(ProcessError);
};

export const apiAccessHistoryMember = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: `user/access-history`,
    data: payload
  })
    .then(({ status, data }) => {
      if (status && status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: { fullName: "Error can not request server" }
      });
    })
    .catch(ProcessError);
};

export const apiRecordSourcingHistoryMember = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: `user/record-sourcing-history`,
    data: payload
  })
    .then(({ status, data }) => {
      if (status && status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: { fullName: "Error can not request server" }
      });
    })
    .catch(ProcessError);
};

export const apiUserSignUp = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: "/sign-up-portal",
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        await saveLocalStorage(constants.KEY_LOCAL_TOKEN, data.token);
        await saveLocalStorage(constants.KEY_LOCAL_STORAGE, JSON.stringify(data.userInfo));
        return data.userInfo;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiUserActiveMember = async payload => {
  return axios
    .post(`/user/active-member/${payload.id}`)
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiGetUserInfoApp = async () => {
  return axios({
    method: "GET",
    baseURL,
    url: "/get-user-info-app"
  })
    .then(({ status, data }) => {
      if (status === 200) {
        data.enableAsinTracker = [
          "asinzenteam@asinzen.com",
          "asinzendemo@asinzen.com",
          "royce@7QProducts.com"
        ].includes(data.email);
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiUserResetPassword = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: "user-apply-reset-password",
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({ code: "EXCEPTION", message: "Error can not request server" });
    })
    .catch(ProcessError);
};

export const apiUserForgotPassword = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: "forgot-password",
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiUserDeleteMember = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: `/user/delete-member/${payload.id}`
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiChangePassword = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: "/user/change-password",
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiChangeEmail = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: "/user/change-email",
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiCopyCalculatorSettings = async payload => {
  try {
    return await axios({
      method: "POST",
      baseURL,
      url: "/user/copy-calculator-settings",
      data: payload
    });
  } catch (e) {
    ProcessError(e);
  }
};

export const apiSendInstructions = async payload => {
  try {
    return await axios({
      method: "POST",
      baseURL,
      url: "/user/send-instructions",
      data: payload
    });
  } catch (e) {
    ProcessError(e);
  }
};

export const apiGetUserAuthorizeSpApiStatus = async payload => {
  return axios({
    method: "GET",
    baseURL: authorizeSpBaseUrl,
    url: "/status"
  })
    .then(async ({ status, data }) => {
      if (status === 200) return data;
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiUserDisconnectSpApi = async payload => {
  return axios({
    method: "POST",
    baseURL: authorizeSpBaseUrl,
    url: "/disconnect",
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) return data;
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiGetTeamHistory = async payload => {
  const { page, limit } = payload;
  return axios({
    method: "POST",
    baseURL,
    url: `/user/get-team-history/${page}/${limit}`,
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiExportTeamHistory = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: `/user/export-team-history`,
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiGetHistoryUsageStatistics = async payload => {
  const { page, limit } = payload;
  return axios({
    method: "POST",
    baseURL,
    url: `/user/get-usage-statistics/${page}/${limit}`,
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiGetSummaryHistoryUsageStatistics = async payload => {
  const { page, limit } = payload;
  return axios({
    method: "POST",
    baseURL,
    url: `/user/get-summary-usage-statistics`,
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiGetAllAccounts = async () => {
  return axios({
    method: "GET",
    baseURL,
    url: `/user/get-all-accounts-for-history`
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};

export const apiGetAsinzenTokens = async payload => {
  return axios({
    method: "POST",
    baseURL,
    url: `/user/get-asinzen-tokens`,
    data: payload
  })
    .then(async ({ status, data }) => {
      if (status === 200) {
        return data;
      }
      throw new CustomError({
        code: "EXCEPTION",
        message: "Error can not request server"
      });
    })
    .catch(ProcessError);
};
