import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getSellerMetrics } from "../../../../../api/masterdb/seller";

const initialState = {
  data_items: {},
  headers: [],
  value: 0,
  metrics: {
    summary: [
      { name: "rating", value: null },
      { name: "feedback_count", value: null },
      { name: "tracked_since", value: null },
      { name: "listing_count", value: 0 }
    ],
    category_statistics: []
  },
  sellerName: "",
  sellerId: "",
  marketplace: "US"
};

const initialMetricSummary = [
  { name: "rating", value: null },
  { name: "feedback_count", value: null },
  { name: "tracked_since", value: null },
  { name: "listing_count", value: 0 }
];

const SellerMetrics = ({ sellerIds, marketplace }) => {
  const [metricSummary, setMetricSummary] = useState(initialMetricSummary);
  const [metricCategory, setMetricCategory] = useState([]);

  const retrieveSellerMetrics = async params => {
    const res = await getSellerMetrics(params);
    return res.data;
  };

  const mappingDataItems = dataItems => {
    const metricsData = dataItems[sellerIds[0]];
    const keys = Object.keys(metricsData);

    keys.forEach(k => {
      if (k === "summary") {
        const data = Object.keys(metricsData.summary).map(item => {
          return {
            name: item,
            value: metricsData.summary[item]
          };
        });

        setMetricSummary(data);
      } else if (k === "category_statistics") {
        const data = Object.keys(metricsData.category_statistics).map(item => {
          return {
            name: item,
            listings: metricsData.category_statistics[item].listing_count,
            listingsAmazonOffer: metricsData.category_statistics[item].listing_count_amazon
          };
        });

        setMetricCategory(data);
      }
    });
  };

  const getDataTable = async params => {
    const result = await retrieveSellerMetrics(params);
    mappingDataItems(result);
  };

  useEffect(async () => {
    const params = {
      seller_ids: sellerIds,
      marketplace,
      included_data: [],
      page: "1",
      per_page: 1
    };
    await getDataTable(params);
  }, []);

  return (
    <>
      <Paper variant="outlined">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Rating</TableCell>
              <TableCell align="right">98%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metricSummary.map(row => (
              <TableRow key={row.name}>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Typography
        component="h3"
        variant="h5"
        style={{ marginTop: 16, marginBottom: 8, alignSelf: "flex-start" }}
      >
        Category statistics of seller
      </Typography>
      <Paper variant="outlined">
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell align="right">Listings</TableCell>
              <TableCell align="right">Listings with Amazon offer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metricCategory.length > 0 &&
              metricCategory.map(row => (
                <TableRow key={row.name}>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">{row.listings}</TableCell>
                  <TableCell align="right">{row.listingsAmazonOffer}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default SellerMetrics;
