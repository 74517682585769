/*eslint-disable*/
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import {
  userAuthorizeMwsRequest,
  userAuthorizeMwsUpdateRequest,
  userSendPhoneNumberRequest,
  userVerifyCodeRequest
} from "redux/actions/UserActions";

import { getProductPlanRequest } from "redux/actions/PlanActions";
import { userInitState } from "redux/reducers/UserReducer";
import { connectAutoDispatch } from "redux/reduxConnect";
import queryString from "query-string";
import { checkExtensionInstalled, checkHAZExtensionInstalled, isMyScriptLoaded } from "utils";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ImageZoom from "react-medium-image-zoom";
import ImageFull1 from "static/img/1full-min.png";
import ImageFull2 from "static/img/2full-min.png";
import ImageFull3 from "static/img/3full-min.png";
import ImageFullUK1 from "static/img/mwsprocessukpic1.png";
import ImageFullUK2 from "static/img/mwsprocessukpic2.png";
import ImageFullUK3 from "static/img/mwsprocessukpic3.png";
import Select from "@mui/material/Select";
import { SettingsCard } from "components";
import { UserStyles, UlStyled } from "./User.styled";
import PlansUI from "components/plans/PlansUI";
import constants from "utils/constants";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import AlertDialogSlide from "components/AlertDialogSlide";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const MarketplaceImages = {
  US: {
    image1: ImageFull1,
    image2: ImageFull2,
    image3: ImageFull3
  },
  UK: {
    image1: ImageFullUK1,
    image2: ImageFullUK2,
    image3: ImageFullUK3
  }
};

const errorsSendSms = {
  PHONE_NUMBER_EXISTS: "Phone number exists",
  PHONE_VERIFIED: "User verified",
  PHONE_NUMBER_INVALID: "Phone number invalid",
  USER_NOT_AUTHORIZE: "User not authorize"
};

const errorsVerifySms = {
  SECRET_CODE_NOT_MATCHING: "Secret code not matching",
  SERVER_ERROR: "Server error"
};

class AuthorizeMWS extends React.Component {
  constructor(props) {
    super(props);
    let {
      contactId,
      email,
      registerFrom = "AZINSIGHT",
      accessToken = "",
      step
    } = queryString.parse((location.search || "?").substring(1));

    if (["1", "2"].includes(step)) {
      step = parseInt(step);
    } else {
      step = null;
    }

    this.startedStep = step;

    localStorage.setItem(constants.KEY_LOCAL_TOKEN, accessToken);
    this.state = {
      contactId: this.handleTrimTxt(contactId || ""),
      email: this.handleTrimTxt(email || ""),
      accessToken,
      planURL: null,
      userHazType: null,
      sellerId: "",
      mwsAuthToken: "",
      marketplace: "US",
      showPassword: false,
      isBtnClick: false,
      isOpenSnack: false,
      verticalSnack: "top",
      horizontalSnack: "right",
      messageSnack: "",
      isOnlyScoutView: false,
      isOpenDialogNotSigninSeller: false,
      errorOnStep1: null,
      errors: {},
      userMsg: "",
      activeStep: null,
      isNextButtonDisabled: false,
      isExistedUser: false,
      wayAddMws: "manualGuide",
      arrayTitleSteps: [
        "Your Account Details were recieved",
        "Add asinzen as a developer on Amazon Seller Central to generate an MWS Token required for the signup process",
        registerFrom === "AZINSIGHT"
          ? "Download and Install the Chrome Extension"
          : `<span>
        Download the Bulk Scan Pro Desktop App or access the Browser app by
        <a target="_blank" href="https://get.bulkscanpro.com/getapp">
          clicking here.
        </a>
      </span>`
      ],
      showPopup: false,
      showPlans: false,
      showType: registerFrom === "AZINSIGHT",
      confirmShowType: false,
      typeRegister: "",
      isCheckConfirm: false,
      registerFrom,
      openSmsCheck: false,
      zipcode: "",
      phoneNumberSuffix: "",
      codeVerify: ""
    };
    console.log(this.state);
    this.props.getProductPlanRequest();
  }

  async componentDidMount() {
    // window.analytics.page("SignUp");
    document.addEventListener("mouseout", this.onUnload);
    const scripts = [
      "https://fast.wistia.com/assets/external/E-v1.js",
      "https://fast.wistia.com/embed/medias/fbvm4bb56s.jsonp"
    ];
    scripts.forEach(src => {
      if (!isMyScriptLoaded(src)) {
        const script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        script.async = true;
        document.body.appendChild(script);
      }
    });

    const { accessToken } = this.state;

    this.props.userAuthorizeMwsRequest({
      accessToken
    });
  }

  componentDidUpdate(prevProps) {
    const { userState } = this.props;

    if (
      prevProps.userState.callingSignUp &&
      !userState.callingSignUp &&
      !userState.errorMessage.code
    ) {
      const {
        sellerId,
        mwsAuthToken,
        isCanceledAccount,
        banAllAccess,
        isExistedInHaz,
        plansURL,
        lastName,
        productType
      } = userState.userInfo;
      const { isInstalledExt, isHazInstallExt, isOpenPassword } = this.state;
      const { codeVerified } = userState;
      if (codeVerified) {
        this.setState({ showType: false, openSmsCheck: false, activeStep: 2 });
      } else if (isOpenPassword && !lastName) {
        this.setState({ activeStep: 0, isNextButtonDisabled: false });
      } else if (!banAllAccess && !isCanceledAccount && !sellerId && !mwsAuthToken) {
        this.setState({
          activeStep: this.startedStep,
          isNextButtonDisabled: false,
          isExistedUser: true
        });
      } else if (sellerId && mwsAuthToken && banAllAccess === true && isCanceledAccount === true) {
        this.setState({ isNextButtonDisabled: true });
        window.open("https://goto.asinzen.com/marketplace", "_self");
      } else if (sellerId && mwsAuthToken && banAllAccess === false) {
        // let strExisted =
        //   "Opps looks like this email was already used to sign up for an account. Are you trying to sign up for another FREE trial? Please contact support for assistance.";
        // if (isExistedInHaz && isOpenPassword === true) {
        //   strExisted = `Your email address already exists.`;
        //   if (isHazInstallExt) {
        //     strExisted = strExisted + " Please remove HAZability extension.";
        //   }
        //   if (!isInstalledExt) {
        //     strExisted =
        //       strExisted +
        //       (isHazInstallExt ? " And then" : " Let") +
        //       " install AZInsight extension.";
        //   }
        //   if (isInstalledExt && !isHazInstallExt) {
        //     strExisted = strExisted + " Let sign in AZInsight extension.";
        //   }
        // }
        // this.setState({ userMsg: strExisted });
        this.setState({
          activeStep: this.startedStep,
          isNextButtonDisabled: false,
          isExistedUser: true
        });
      } else if (banAllAccess === true) {
        this.setState({
          userMsg:
            "Look like this email address has already been signed up and completed the 10 days Free Trial.",
          plansURL
        });
      } else {
        this.setState({
          activeStep: this.startedStep,
          isNextButtonDisabled: false,
          isExistedUser: true
        });
      }
    }

    //process request step 0
    if (this.state.activeStep === 1) {
      this._processRequestStep1(prevProps);
    }
  }

  _processRequestStep1 = prevProps => {
    const { userState } = this.props;
    if (
      prevProps.userState.callingUpdateMws &&
      !userState.callingUpdateMws &&
      userState.errorMessage.code
    ) {
      const invalidMWS = (
        <>
          <div style={{ maringTop: 10, marginBottom: 10 }}>
            {userState.errorMessage.code === "INVALID" && (
              <>
                Error: 109 - Please verify you have entered your Amazon SellerID and MWS Auth Token
                properly. If you continue experiencing issues please{" "}
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={this.showChat}
                >
                  click here
                </span>{" "}
                to chat with a customer champion.
              </>
            )}
            {userState.errorMessage.code === "INVALID_DUPLICATE" && (
              <>
                Error: 110 - Your Amazon seller account has already linked with another AZInsight
                account {userState.userInfo.email}. If you forgot your password, click{" "}
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() =>
                    window.open(
                      "https://support.asinzen.com/portal/en/kb/articles/how-do-i-change-or-reset-my-password",
                      "_blank"
                    )
                  }
                >
                  here
                </span>{" "}
                to learn how to reset it. If you have any other issues please click{" "}
                <span
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => window.open("https://asinzen.com/contactus", "_blank")}
                >
                  here
                </span>{" "}
                to chat with a Customer Champion.
              </>
            )}
          </div>
        </>
      );
      return this.setState({
        userMsg: invalidMWS,
        isNextButtonDisabled: false
      });
    }

    if (prevProps.userState.callingUpdateMws && !userState.callingUpdateMws) {
      this.setState({ activeStep: 2, isNextButtonDisabled: false, userMsg: "" });
    }
  };

  handleTrimTxt = txt => {
    if (!txt || typeof txt !== "string") return "";
    return (txt && txt.trim && txt.trim()) || "";
  };

  handleChange = prop => event => {
    const { errors } = this.state;
    this.setState({ [prop]: event.target.value });
    if (event.target.value && errors[prop]) {
      delete errors[prop];
      this.setState({ errors: { ...errors } });
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleShowSnackBar = reason => {
    this.setState({
      isBtnClick: false,
      isOpenSnack: true,
      verticalSnack: "top",
      horizontalSnack: "right",
      messageSnack: reason
    });
  };

  handleCheckExtendsion = async () => {
    if (await checkHAZExtensionInstalled()) {
      this.handleShowSnackBar(`Please remove HAZability extension to continue the next step.`);
      return false;
    } else {
      if (!(await checkExtensionInstalled())) {
        this.handleShowSnackBar(`Please install AZInsight extension to continue the next step.`);
        return false;
      } else if (this.state.wayAddMws !== "manualGuide" || this.state.wayAddMws !== "ezGuide") {
        return this.setState(state => ({
          activeStep: state.activeStep + 1
        }));
      }
    }
    return true;
  };

  handleStopSnackBar = () => {
    this.setState({ isOpenSnack: false });
  };

  handleNext = async () => {
    let {
      activeStep,
      email,
      ownerEmail,
      firstName,
      lastName,
      contactId,
      contactKey,
      plan,
      password,
      upgradeToInsight,
      userHazType,
      sellerId,
      mwsAuthToken,
      marketplace,
      // errorOnStep1,
      isInstalledExt,
      wayAddMws,
      confirmPassword
    } = this.state;
    let errors = {};
    if (activeStep === 0) {
      this.setState({ isNextButtonDisabled: true });
    } else if (activeStep === 1 && wayAddMws === "manualGuide") {
      console.log("step1");
      this.setState({ isNextButtonDisabled: true });
      this.props.userAuthorizeMwsUpdateRequest({ sellerId, mwsAuthToken, marketplace });
    } else if (activeStep === 2 && wayAddMws === "siblingAccount") {
      console.log("step2");
      this.setState({ isNextButtonDisabled: true });
      if (!ownerEmail) {
        errors.ownerEmail = "Please enter an sibling email address!";
      }
      if (!ValidateEmail(ownerEmail)) {
        errors.ownerEmail = "Please enter an sibling email valid email address!";
      }
      if (Object.keys(errors).length > 0) {
        return this.setState({ errors, isNextButtonDisabled: false });
      }
    } else if (activeStep === 3 && wayAddMws === "siblingAccount") {
      console.log("step3");
      return this.handleCheckExtendsion();
    } else {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  getSteps = () => {
    return this.state.arrayTitleSteps;
  };

  getStepContent = step => {
    const { classes } = this.props;
    const {
      errorOnStep1,
      contactId,
      email,
      isOpenPassword,
      wayAddMws,
      activeStep,
      userMsg
    } = this.state;
    console.log("wayAddMws", wayAddMws, activeStep);
    const arrStepTwo = [
      <p key={"p" + 1}>
        {`Download and install the chrome extension "`}
        <a
          target={`_blank`}
          rel="noopener noreferrer"
          href={`${process.env.CHROME_EXTENSION_DOWNLOAD_LINK}`}
        >
          <b>here</b>
        </a>
        {`" to continue`}
      </p>
    ];
    const urlForToSellerCentral = `${
      process.env[`ASIN_ZEN_MWS_URL_${this.state.marketplace}`]
    }gp/mws/registration/register.html?signInPageDisplayed=1&devAuth=1&developerName=${
      process.env[`ASIN_ZEN_MWS_INFO_TYPE_${this.state.marketplace}`]
    }&devMWSAccountId=${process.env[`ASIN_ZEN_MWS_INFO_PHONE_${this.state.marketplace}`]}`;
    if (wayAddMws === "ezGuide") {
      switch (activeStep) {
        case 2:
          return arrStepTwo;
        case 3:
          return (
            <div style={{ width: "70%" }}>
              <p>
                {`We've made it easy for you provide your Amazon MWS permissions. `}
                To launch our EZ-Guide: {` `}
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  style={{ backgroundColor: "#ed9b00" }}
                >
                  <a
                    target={"_blank"}
                    href={`${urlForToSellerCentral}&email=${email}&contactId=${contactId}&url=${location}`}
                    style={{ fontSize: 20, textDecoration: "none" }}
                  >
                    Click here
                  </a>
                </Button>
              </p>
            </div>
          );
      }
    } else if (wayAddMws === "siblingAccount") {
      switch (activeStep) {
        case 3:
          return arrStepTwo;
      }
    } else {
      switch (activeStep) {
        case 1:
          return (
            <div style={{ width: "100%" }}>
              <UlStyled>
                <li>
                  <div style={{ width: "100%" }}>
                    <Grid container alignItems="center" spacing={0}>
                      <Grid item xs={12} sm={3}>
                        Select Marketplace
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          error={!!this.state.errors.marketplace}
                        >
                          <Select
                            native
                            value={this.state.marketplace}
                            onChange={this.handleChange("marketplace")}
                            inputProps={{
                              name: "age",
                              id: "age-native-simple"
                            }}
                          >
                            <option value="US">US/ Canada/ Mexico</option>
                            <option value="UK">UK/ Germany/ France/ Italy/ Spain</option>
                          </Select>
                          <FormHelperText>{this.state.errors.marketplace || ""}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </li>
                <li>
                  <p>
                    Click
                    <a target={"_blank"} href={urlForToSellerCentral}>
                      <b> HERE </b>
                    </a>
                    to open Amazon Marketplace Web Service page.{" "}
                    <i>(Log into Amazon Seller Central using your master account)</i>
                  </p>
                </li>
                <li>
                  {`Type `}
                  <b>{process.env[`ASIN_ZEN_MWS_INFO_TYPE_${this.state.marketplace}`]}</b>
                  {` in the "Developer Name" field and `}
                  <b>{process.env[`ASIN_ZEN_MWS_INFO_PHONE_${this.state.marketplace}`]}</b>
                  {` in the "Developer Account Number" field.`}
                  <br />
                  <ImageZoom
                    image={{
                      src: MarketplaceImages[this.state.marketplace].image1,
                      style: { width: "400px", height: "auto" }
                    }}
                    zoomImage={{ src: MarketplaceImages[this.state.marketplace].image1 }}
                  />
                </li>
                <li>
                  {`Click Next button to confirm you want to give asinzen access to your Amazon selling account`}
                  <br />
                  <ImageZoom
                    image={{
                      src: MarketplaceImages[this.state.marketplace].image2,
                      style: { width: "400px", height: "auto" }
                    }}
                    zoomImage={{ src: MarketplaceImages[this.state.marketplace].image2 }}
                  />
                  <ImageZoom
                    image={{
                      src: MarketplaceImages[this.state.marketplace].image3,
                      style: { width: "400px", height: "auto" }
                    }}
                    zoomImage={{ src: MarketplaceImages[this.state.marketplace].image3 }}
                  />
                </li>
                <li>
                  {`Then copy and paste your "Seller ID" and "MWS Auth Token" values onto the boxes below and click the "NEXT" button.`}
                  <div style={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          error={!!this.state.errors.sellerId}
                        >
                          <InputLabel htmlFor="sellerId">Seller Id</InputLabel>
                          <Input
                            id="sellerId"
                            placeholder="Seller ID"
                            value={this.state.sellerId}
                            onChange={this.handleChange("sellerId")}
                            error={!!this.state.errors.sellerId}
                          />
                          <FormHelperText>{this.state.errors.sellerId || ""}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          error={!!this.state.errors.mwsAuthToken}
                        >
                          <InputLabel htmlFor="mwsAuthToken">MWS Auth Token</InputLabel>
                          <Input
                            id="mwsAuthToken"
                            placeholder="MWS Auth Token"
                            value={this.state.mwsAuthToken}
                            onChange={this.handleChange("mwsAuthToken")}
                            error={!!this.state.errors.mwsAuthToken}
                          />
                          <FormHelperText>{this.state.errors.mwsAuthToken || ""}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {userMsg && (
                      <div
                        style={{
                          color: "red",
                          fontSize: 18,
                          margin: 0,
                          padding: "10px",
                          fontWeight: "bold"
                        }}
                      >
                        {userMsg}
                      </div>
                    )}
                  </div>
                </li>
              </UlStyled>
            </div>
          );
        case 2:
          return arrStepTwo;
      }
    }
  };

  handleClosePopup = () => {
    this.setState({ showPopup: false });
  };

  renderPopup = () => {
    if (!this.state.showPopup) return null;
    return [
      <div key="popup-overlay" className="popup-overlay" />,
      <div key="popup-content" className="popup-content">
        <div className="popup-container">
          <h2
            style={{
              margin: 0,
              fontSize: "2.7em",
              color: "#333333"
            }}
          >
            Wait! Before you go..
          </h2>
          <h5
            style={{ fontSize: "1.5em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            We wanted to make sure you get a chance to try out AZInsight.
          </h5>
          <h5
            style={{ fontSize: "1.5em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            Tell us how we can make that happen.
          </h5>
          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            <strong>Was this was too confusing?</strong> Click{" "}
            <a target="_blank" rel="" href="http://support.asinzen.com/singup">
              here
            </a>{" "}
            to watch a quick step by step guide on completing the signup process and talk to a
            Customer Champion (when available).
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            <strong>Why do I have to enter my MWS Keys?</strong> Each Amazon Seller account has
            different restrictions and permissions. You may be allowed to sell certain brands and in
            certain categories, but be restricted in others. In order to show accurate,
            account-level user permissions and restrictions to each seller, we need access to the
            "back-end", as it is commonly referred to. Just because it is allowed to be sold on
            Amazon doesn't mean that every account can sell it.
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            The MWS Keys allow developers to access the information necessary to provide accurate
            data that is specific to your account. AZInsight uses the MWS keys to produce the
            following data points: slow moving, Prep guidance, number of FBA and FBM offers, list of
            variations, offers’ details on Stock Checker.
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            If you would like to use AZInsight without providing your MWS Keys, the features listed
            below will still be available: FBA Calculator, limited Stock Checker.
          </h5>

          <h5
            style={{ fontSize: "1.1em", margin: "25px 0 0", color: "#716c80", fontWeight: "300" }}
          >
            If you don’t want to add asinzen as a developer in your Amazon Seller Central, please{" "}
            <a target="_blank" href="http://support.asinzen.com">
              contact a customer Champion
            </a>{" "}
            who can give you a demo account to use temporarily without having to put your Amazon MWS
            keys in.
          </h5>
        </div>
        <span className="popup-button-close">
          <CloseIcon onClick={this.handleClosePopup} style={{ fontSize: "35px" }} />
        </span>
      </div>
    ];
  };

  onCloseDialogNotSigninSeller = () => {
    this.checkCanSellOfProducts = false;
    // this.props.checkSigninSellerCenter();
    this.setState({ isOpenDialogNotSigninSeller: false });
  };

  renderDialogNotSigninSeller = () => {
    return (
      <AlertDialogSlide
        isHtml={true}
        open={true}
        isDisagree={false}
        isAgree={true}
        handleClose={this.onCloseDialogNotSigninSeller}
        handleDisagre={() => {}}
        handleAgree={this.onCloseDialogNotSigninSeller}
        title={"Need to be signed into Amazon Seller Centeral to perform this on your behalf."}
        content={`
        <div>
        	<p>Looks like you are not logged into you Amazon Seller Account. Click <a className='' type='button' href='https://sellercentral.amazon.com/' target='_blank'>here</a> to login to Amazon Seller Central</p>
          <i>
          	<small>
							By clicking this self-guided feature you are agreeing to the Amazon Terms of Service Compliance Statement of asinzen & the LICENSE AND ACCESS clause of the Conditions of Use and Service Terms set forth by Amazon.com. No data is being transmitted from your web browser to the internet from this chrome extension, no data is stored or analyzed and your data is for the sole purpose of conducting business with Amazon seller as a Seller in good standing with a Professional Account.
						</small>
					</i>
        </div>
			`}
        textDisagree={""}
        textAgree={"Ok"}
      />
    );
  };

  renderShowPlans() {
    const {
      planState: {
        currentPlans: { data }
      }
    } = this.props;

    return data.map((d, k) => (
      <PlansUI
        gXs={4}
        gSm={4}
        handleShowSnackBar={this.handleShowSnackBar}
        currentUserData={this.state}
        key={k}
        item={d}
        k={k}
      />
    ));
  }

  handleChangeType = typeRegister => {
    const data = {
      confirmShowType: true,
      showType: false,
      typeRegister: typeRegister.target.value
    };
    if (typeRegister.target.value === "hasMws") {
      this.setState({ openConfirm: true, typeRegister: data });
    } else {
      this.setState({ activeStep: 1, ...data });
    }
  };

  showChat = () => {
    window.fcWidget?.open();
  };

  refAbc = React.createRef(null);

  get = () => {
    return (
      <div ref={this.refAbc} className="limited-trial-content">
        <p>
          The limited trial you selected will only allow you to evaluate <strong>40 ASINs</strong>{" "}
          during your trial period no matter how long your trial period is. This is done to prevent
          abuse of the free trial and to maintain the quality of service for all our users.
        </p>
        <p>
          If you have an Amazon Professional Seller account, we recommend going back and choosing
          the unlimited usage trial and add asinzen as a developer in your Amazon Seller Central.
        </p>
        <p>
          Don't have an Amazon Professional Seller? You can evaluate the tool in the "limited trial"
          and when ready you can subscribe to the monthly subscription which comes with a
          no-questions 14-day money-back guarantee and does not require that you have an Amazon
          Professional Seller.
        </p>
        <FormControlLabel
          className="checkbox-confirm"
          onChange={() => {
            this.setState({ isCheckConfirm: !this.state.isCheckConfirm });
          }}
          checked={this.state.isCheckConfirm}
          control={<Checkbox className="checkbox" color="primary" />}
          label={
            <p style={{ fontSize: 14 }}>
              I understand that I've selected the limited trial which will only allow me to evaluate{" "}
              <strong>40 ASINs</strong>.
            </p>
          }
        />
        {this.state.showMessageConfirm && (
          <p style={{ color: "red" }}>You must confirm that you understand to continue.</p>
        )}
      </div>
    );
  };

  handleSendVerificationCode = () => {
    const { zipcode, phoneNumberSuffix } = this.state;
    this.props.userSendPhoneNumberRequest({
      zipcode: `+${zipcode}`,
      phone: phoneNumberSuffix
    });
  };

  handleVerify = () => {
    const { codeVerify } = this.state;
    this.props.userVerifyCodeRequest({
      code: codeVerify
    });
  };

  render() {
    const { classes } = this.props;
    const {
      isOpenSnack,
      verticalSnack,
      horizontalSnack,
      messageSnack,
      email,
      contactId,
      plan,
      plansURL,
      password,
      isOpenDialogNotSigninSeller,
      isExistedUser,
      arrayTitleSteps,
      userHazType,
      showType,
      openConfirm,
      openSmsCheck,
      zipcode,
      phoneNumberSuffix,
      codeVerify
    } = this.state;
    const steps = arrayTitleSteps;
    const { activeStep, isNextButtonDisabled, userMsg, wayAddMws, typeRegister } = this.state;
    const { phoneNumberSentCode, codeVerified, errorMessage } = this.props.userState;

    if (showType) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={10}
          style={{
            width: "inherit",
            paddingTop: "10%"
          }}
        >
          <Grid container spacing={10} justify="center" alignItems="center">
            <Grid
              item
              xs={6}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                background: "#fff"
              }}
            >
              <h2>Almost done setting up your account</h2>
              <p>
                We’ve made it easy for you to give AZInsight a try by offering two types of trials.
                Please select one of the options below to continue.
              </p>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <RadioGroup
                    aria-label="status"
                    name="status"
                    value={typeRegister}
                    onChange={this.handleChangeType}
                  >
                    <FormControlLabel
                      className={classes.formChooseTypeLabel}
                      value="hasMws"
                      control={
                        <Radio
                          color="primary"
                          icon={<RadioButtonUncheckedIcon fontSize="small" />}
                          checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                        />
                      }
                      label={
                        <div>
                          <div style={{ fontWeight: "bold" }}>Limited usage trial</div>
                          <div style={{ fontSize: 14 }}>
                            You do not need to add asinzen as a developer in your Amazon Seller
                            Central. The limited usage trial will only allow you to evaluate 40
                            ASINs during the total time of your trial.
                          </div>
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={classes.formChooseTypeLabel}
                      value="noMws"
                      control={
                        <Radio
                          color="primary"
                          icon={<RadioButtonUncheckedIcon fontSize="small" />}
                          checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                        />
                      }
                      label={
                        <div>
                          <div style={{ fontWeight: "bold" }}>Unlimited usage trial</div>
                          <div style={{ fontSize: 14 }}>
                            You will need to add asinzen as a developer in your Amazon Seller
                            Central account. The unlimited usage trial does not have a limit on the
                            number of ASINs you can evaluate during the total time of your trial.
                          </div>
                        </div>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <p>
                  <span style={{ display: "block" }}>
                    <strong>Why do you need my Amazon SellerID and MWS Token?</strong>
                  </span>
                  <span style={{ display: "block" }}>
                    AZInsight relies on Amazon for product-related information that is obtained from
                    the Amazon Marketplace Web Service. Every Amazon Pro Account has access to this
                    Amazon Marketplace Web Service. Amazon requires you to grant permission to each
                    authorized app developer such as asinzen in order for the developer to be able
                    to provide account-level information data to you.
                  </span>
                </p>
              </Grid>
            </Grid>
          </Grid>

          {openConfirm && (
            <AlertDialogSlide
              open
              isDisagree
              isAgree
              handleClose={() => this.setState({ openConfirm: false })}
              handleDisagre={() => this.setState({ openConfirm: false })}
              handleAgree={() => {
                if (this.state.isCheckConfirm) {
                  this.setState({
                    showType: false,
                    openSmsCheck: true,
                    ...this.state.typeRegister,
                    showMessageConfirm: false
                  });
                } else {
                  this.setState({ showMessageConfirm: true });
                }
              }}
              title={<div>Confirm limited usage trial</div>}
              content={this.get()}
              textDisagree="No, go back"
              textAgree="I understand, continue"
            />
          )}
        </Grid>
      );
    } else if (openSmsCheck) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={10}
          style={{
            width: "inherit",
            paddingTop: "10%"
          }}
        >
          <Grid container spacing={10} justify="center" alignItems="center">
            <Grid
              item
              xs={6}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                background: "#fff"
              }}
            >
              <p>
                <strong>
                  To prevent free trial abuse and maintain the quality of our service, we need to
                  verify you in order to activate your trial. If you don't wish to perform the SMS
                  verification, please click the Back button and select the Unlimited Usage trial to
                  complete the Amazon Integration. Experiencing issues with the SMS verification
                  process?{" "}
                  <span onClick={() => window.fcWidget?.open()} className="span-link text-blue">
                    Click here
                  </span>{" "}
                  to chat with a customer champion now.
                </strong>
              </p>

              <br />
              <br />
              <p>
                <strong>
                  Step 1: Enter your phone number to receive the one-time verification code to one
                  of the supported countries.If your country is not supported, chat with a customer
                  champion for support.
                </strong>
              </p>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    error={!phoneNumberSentCode && !!errorsSendSms[errorMessage?.code]}
                  >
                    <Grid item xs={12} sm={12} style={{ marginRight: 16 }}>
                      <PhoneInput
                        country="us"
                        onlyCountries={["us", "ca", "mx", "gb", "de"]}
                        onChange={(phone, country) =>
                          this.setState({
                            phoneNumberSuffix: phone.replace(country.dialCode, ""),
                            zipcode: country.dialCode
                          })
                        }
                        specialLabel=""
                      />
                    </Grid>
                    <FormHelperText>
                      {(!phoneNumberSentCode && errorsSendSms[errorMessage?.code]) || ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSendVerificationCode}
                      style={{ marginRight: 10, marginTop: 10, width: 240 }}
                      disabled={!phoneNumberSuffix}
                    >
                      Send Verification code
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {phoneNumberSentCode && (
                <>
                  <br />
                  <br />
                  <p>
                    <strong>Step 2: Enter Verification Code sent to your number</strong>
                  </p>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        error={!codeVerified && !!errorsVerifySms[errorMessage?.code]}
                      >
                        <Input
                          id="codeVerify"
                          style={{ marginRight: 16 }}
                          placeholder=""
                          value={codeVerify}
                          onChange={this.handleChange("codeVerify")}
                          error={!codeVerified && !!errorsVerifySms[errorMessage?.code]}
                        />
                        <FormHelperText>
                          {(!codeVerified && errorsVerifySms[errorMessage?.code]) || ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <Grid container justify="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleVerify}
                          style={{ marginRight: 10, width: 240 }}
                          disabled={!codeVerify}
                        >
                          Verify
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} className={classes.buttonBack}>
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        showType: true,
                        openSmsCheck: false,
                        openConfirm: false,
                        typeRegister: ""
                      })
                    }
                    style={{ marginRight: 10, width: 80 }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={10} justify="center" alignItems="center">
        {this.renderPopup()}
        <Grid item xs={8}>
          <div>
            <p className="img-logo-" style={{ textAlign: "center", height: "15px" }} />
            {["HAZ_FREE_ALL", "HAZ_NORMAL"].includes(userHazType) ? (
              <div>
                <p style={{ color: "#111111", fontFamily: "verdana, geneva" }}>
                  As you may or may not be have received our email we wanted to make you aware of
                  some changes that we are making so you will know how best to navigate through
                  them. Everything that we are doing is with an eye toward a more{" "}
                  <span style={{ color: "#008000" }}>
                    <strong>seamless integration with our other asinzen products.</strong>
                  </span>{" "}
                  We are adding some additional features to our HAZability Pro version, and we are
                  renaming the current version to HAZability Basic.
                </p>
                <p>
                  <span style={{ color: "#111111", fontFamily: "verdana, geneva" }}>
                    The biggest change that you will notice is the{" "}
                    <strong>new layout of HAZability</strong>. Since we also
                    <strong> include HAZability in our AZInsight extension</strong>, it makes sense
                    for us to change the layout of HAZability so it looks the same across all
                    subscription levels. This will allow us to make changes and updates more easily.
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      color: "#ff0000",
                      fontFamily: "verdana, geneva",
                      fontSize: 18
                    }}
                  >
                    <strong>
                      In order to achieve this, we need to shut down the current extension and
                      migrate the current users over to our new layout.{" "}
                    </strong>
                  </span>
                </p>
              </div>
            ) : (
              ""
            )}
            <SettingsCard
              title={
                ["HAZ_FREE_ALL", "HAZ_NORMAL"].includes(userHazType) ? (
                  "Here's the form you need to fill out to continue HAZability"
                ) : (
                  <div>Almost done! Now lets complete the final steps so you can begin. </div>
                )
              }
            >
              <div style={{ display: "flex" }}>
                <Grid item xs={12}>
                  {userMsg ? (
                    <div style={{ color: "red", fontSize: 18, maringTop: 10, marginBottom: 10 }}>
                      <b>
                        {userMsg}
                        {plansURL ? (
                          <>
                            {" "}
                            Please click <a href={plansURL}>HERE</a> to subscribe. If you would like
                            to extend your trial please contact support{" "}
                            <a href={`https://support.asinzen.com`} target={"_blank"}>
                              here
                            </a>
                            .
                          </>
                        ) : null}
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => {
                      return (
                        <Step key={label}>
                          <StepLabel>
                            <div dangerouslySetInnerHTML={{ __html: label }}></div>
                            {label.indexOf(
                              "as a developer on Amazon Seller Central to generate an M"
                            ) >= 0 && (
                              <div>
                                <span
                                  className="wistia_embed wistia_async_fbvm4bb56s popover=true popoverContent=link"
                                  style={{ display: "inline", position: "relative" }}
                                >
                                  Having issues adding asinzen as a developer on Seller Central?{" "}
                                  <a href="#">Click here to watch this short video.</a>
                                </span>
                              </div>
                            )}
                          </StepLabel>
                          <StepContent>
                            {this.getStepContent(index)}
                            <div className={classes.actionsContainer}>
                              <div>
                                {activeStep === 1 && typeRegister === "noMws" && (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ marginRight: 10 }}
                                    onClick={() =>
                                      this.setState({
                                        activeStep: null,
                                        showType: true,
                                        registerType: ""
                                      })
                                    }
                                  >
                                    Cancel, I will do the limited trial
                                  </Button>
                                )}
                                {activeStep < steps.length - 1 ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleNext}
                                    style={{ marginRight: 10 }}
                                    disabled={
                                      isNextButtonDisabled || (!email && !contactId ? true : false)
                                    }
                                  >
                                    {activeStep === steps.length - 1 ? "Finish" : "Save & Finish"}
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {activeStep === steps.length - 1 && this.state.registerFrom !== "BULKSCANPRO" ? (
                    <Paper square elevation={0} className={classes.resetContainer}>
                      <Typography style={{ maxWidth: "783px" }}>
                        <p>
                          That’s it, you may now close this page or click{" "}
                          <a
                            href="https://www.amazon.com/dp/B07G9Y3ZMC"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            here to go to an Amazon product page
                          </a>{" "}
                          to start.{" "}
                        </p>

                        <p>
                          Note: The next time you open an amazon product page, AZInsight will
                          automatically load. Please log in using the email address and password you
                          registered with.
                        </p>
                      </Typography>
                    </Paper>
                  ) : null}
                </Grid>
              </div>
            </SettingsCard>
          </div>
        </Grid>
        {isOpenSnack ? (
          <SnackBars
            isOpenSnack={isOpenSnack}
            verticalSnack={verticalSnack}
            horizontalSnack={horizontalSnack}
            messageSnack={messageSnack}
            handleStopSnackBar={this.handleStopSnackBar}
          />
        ) : (
          ""
        )}
        {isOpenDialogNotSigninSeller === true ? this.renderDialogNotSigninSeller() : null}
      </Grid>
    );
  }
}

AuthorizeMWS.defaultProps = {
  userState: userInitState,
  userSignupRequest: null,
  userUpdateInfoWithoutMwsRequest: null,
  userUpdateMwsRequest: null
};

AuthorizeMWS.propTypes = {
  userState: PropTypes.shape({}),
  userAuthorizeMwsRequest: PropTypes.func,
  userAuthorizeMwsUpdateRequest: PropTypes.func
  // userUpdateInfoWithoutMwsRequest: PropTypes.func,
  // userUpdateMwsRequest: PropTypes.func
};

export default connectAutoDispatch(
  state => ({ userState: state.userState, planState: state.planState }),
  {
    userAuthorizeMwsRequest,
    userAuthorizeMwsUpdateRequest,
    getProductPlanRequest,
    userSendPhoneNumberRequest,
    userVerifyCodeRequest
  }
)(withStyles(UserStyles)(AuthorizeMWS));
