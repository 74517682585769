import React from "react";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/DeleteForever";

export default function AZAlertAccounts({
  record,
  index,
  handleGetUserData,
  handleDeleteMember,
  classes
}) {
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={record._id}>
      <TableCell padding="checkbox">{index + 1}</TableCell>
      <TableCell component="td" scope="row" padding="none">
        {`${record.firstName} ${record.lastName}`}
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        {record.email}
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        {record.createdAt ? moment(record.createdAt).format("ll") : ""}
      </TableCell>
      <TableCell component="td" scope="row" padding="none">
        <EditIcon
          onClick={handleGetUserData(record)}
          style={{ cursor: "pointer", marginRight: "5px" }}
        />
        <RemoveIcon
          onClick={handleDeleteMember(record)}
          style={{ cursor: "pointer", marginRight: "5px", color: "red" }}
          className={classes.btnDelete}
        />
      </TableCell>
    </TableRow>
  );
}
