export const ERROR_MESSAGE = {
  SELLER_NO_DATA: {
    code: 400,
    message:
      "Seller's Data does not exist in Database, Please try another SellerID or Contact Support"
  },
  SELLER_NAME: {
    code: 400,
    message: "Can not get seller name. Please try another SellerID or Contact Support"
  }
};
