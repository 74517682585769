import React, { PureComponent } from "react";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default class InputText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { inputId, data, keyChange, onChange, inputLabel, type, autoFocus } = this.props;
    return (
      <FormControl fullWidth>
        <InputLabel style={{ transform: "unset", fontSize: 15 }} htmlFor={inputId}>
          {inputLabel}
        </InputLabel>
        <Input
          placeholder={inputLabel}
          type={type || "string"}
          id={inputId}
          autoFocus={autoFocus || false}
          value={data}
          onChange={onChange(keyChange)}
          style={{ fontSize: 15 }}
        />
      </FormControl>
    );
  }
}
