import { generateChildHeadersWithFieldName } from "utils/asinzen-api/table";
import { BasicTermTitles } from "./common";

export const TopRatedSellersHeaderTitles = {
  SELLER_ID: "Seller ID",
  SELLER_NAME: "Seller name",
  LISTING_COUNT: "Listing count",
  FEEDBACK_RATING: "Feedback rating",
  FEEDBACK_COUNT: "Feedback count"
};

export const TopRatedSellersHeaderFields = {
  SELLER_NAME: "seller_name",
  SELLER_ID: "seller_id",
  LISTING_COUNT: "listing_count",
  FEEDBACK_RATING: "rating",
  FEEDBACK_COUNT: "feedback_count"
};

export const columnHeaders = [
  {
    field: TopRatedSellersHeaderFields.SELLER_ID,
    headerName: TopRatedSellersHeaderTitles.SELLER_ID,
    filter: true,
    enableValue: false,
    width: 250
  },
  {
    field: TopRatedSellersHeaderFields.SELLER_NAME,
    headerName: TopRatedSellersHeaderTitles.SELLER_NAME,
    filter: true,
    enableValue: false,
    width: 300
  },
  {
    field: TopRatedSellersHeaderFields.LISTING_COUNT,
    headerName: TopRatedSellersHeaderTitles.LISTING_COUNT,
    filter: true,
    enableValue: false,
    width: 200
  },
  {
    field: TopRatedSellersHeaderFields.FEEDBACK_RATING,
    headerName: TopRatedSellersHeaderTitles.FEEDBACK_RATING,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithFieldName(
      BasicTermTitles,
      TopRatedSellersHeaderFields.FEEDBACK_RATING
    )
  },
  {
    field: TopRatedSellersHeaderFields.FEEDBACK_COUNT,
    headerName: TopRatedSellersHeaderTitles.FEEDBACK_COUNT,
    filter: true,
    enableValue: false,
    children: generateChildHeadersWithFieldName(
      BasicTermTitles,
      TopRatedSellersHeaderFields.FEEDBACK_COUNT
    )
  }
];
