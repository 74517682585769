import masterDb from "utils/masterDb";

const baseURL = `${process.env.MASTERDB_API_URL}/portal`;

export const getSellerStoreFront = async params => {
  try {
    const { data: response } = await masterDb({
      method: "POST",
      baseURL,
      url: `/seller-store-front`,
      data: params
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getTopRatedSellers = async params => {
  try {
    const { data: response } = await masterDb({
      method: "POST",
      baseURL,
      url: `/most-rated-sellers`,
      data: params
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getSellerMetrics = async params => {
  try {
    const { data: response } = await masterDb({
      method: "POST",
      baseURL,
      url: `/seller-metrics`,
      data: params
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const getSellerName = async sellerId => {
  try {
    const { data: response } = await masterDb({
      method: "GET",
      baseURL,
      url: `/seller-name/${sellerId}`
    });
    return response;
  } catch (e) {
    throw e;
  }
};
