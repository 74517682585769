// import ProcessError from "api/user/ProcessError";
import masterDb from "utils/masterDb";

const baseURL = `${process.env.MASTERDB_API_URL}/portal`;

export const apiGetItemsByAsins = async params => {
  try {
    const { data: response } = await masterDb({
      method: "POST",
      baseURL,
      url: `/get-items-by-asins`,
      data: params
    });
    return response;
  } catch (e) {
    throw e;
  }
};

export const apiGetItemsByIdentifiers = async params => {
  try {
    const { data: response } = await masterDb({
      method: "POST",
      baseURL,
      url: `/get-items-by-identifiers`,
      data: params
    });
    return response;
  } catch (e) {
    throw e;
  }
};
