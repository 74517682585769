/*eslint-disable*/
import React from "react";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { compose } from "redux";
import { connectAutoDispatch } from "redux/reduxConnect";
import AZInshgitIcon from "static/img/azinsight-hoz-text.png";
import BulkCheckerIcon from "static/img/bulkcheckerpro-hoz-text.png";
import AZAlertIcon from "static/img/azalert-hoz-text.png";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import queryString from "query-string";
import { userRegisterAccountRequest, sendEmailVerifyRequest } from "redux/actions/UserActions";
import { Link } from "react-router-dom";
import SendEmailImage from "static/img/send-email.png";

const ERRORS = {
  ERROR_SEND_EMAIL:
    "We aren’t able to send an email to this email address. Please try another email address to continue.",
  INVALID_TOKEN: "We can't verify your email, you should try again",
  ERROR_INFUSIONSOFT: "An error has occurred. Please contact support for assistance. Error 503",
  SERVER_ERROR: "An error has occurred. Please contact support for assistance. Error 503"
};

const styles = theme => {
  return {
    root: {
      "& .error-text": {
        color: "red",
        textAlign: "left",
        padding: "0 0 0 10px",
        margin: 0
      }
    },
    input: {
      borderRadius: 40,
      overflow: "hidden",
      backgroundColor: "#fff",
      "& input": {
        padding: "12px 16px",
        fontSize: 15
      },
      "& fieldset": {
        borderRadius: "40px",
        borderWidth: "1px !important"
      },
      "& input:disabled": {
        color: "#424242"
      }
    },
    buttonSubmit: {
      backgroundColor: theme.palette.colors.blue,
      color: "white"
    }
  };
};

const defines = {
  azinsight: {
    title: "AZInsight",
    image: AZInshgitIcon,
    name: "AZINSIGHT"
  },
  bulkchecker: {
    title: "Bulk Checker Pro",
    image: BulkCheckerIcon,
    name: "BULK_CHECKER_PRO"
  },
  azalert: {
    title: "AZAlert",
    image: AZAlertIcon,
    name: "AZALERT"
  }
};

class SignUpContainerNew extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repassword: "",
    module: "",
    lmd: "",
    token: "",
    layout: "VERIFY",
    errorPassword: ""
  };

  handleChangeTextField = keys => event => {
    this.setState({ [keys]: event.target.value }, () => {
      if (["password", "repassword"].includes(keys)) {
        if (this.state.password !== this.state.repassword) {
          this.setState({ errorPassword: "Password and repassword don't match" });
        } else {
          this.setState({ errorPassword: "" });
        }
      }
    });
  };

  componentDidMount() {
    const { match, history } = this.props;
    const module = (match && match.params && match.params.module) || "";
    const params = queryString.parse(this.props.history.location.search);

    if (!module || !defines[module]) {
      history.push(
        `/sign-up/azinsight?${queryString.stringify({
          lmd: 10,
          ...(params.token ? { token: params.token } : {}),
          ...(params.email ? { email: params.email } : {}),
          ...(params.affiliateCode ? { aff: params.affiliateCode } : {})
        })}`
      );
    } else {
      let updateData = {};

      if (
        defines[module].name === "AZINSIGHT" &&
        (!params.lmd || !["10", "30"].includes(params.lmd))
      ) {
        updateData = {
          email: params.email
        };
        history.push(
          `/sign-up/azinsight?${queryString.stringify({
            lmd: 10,
            ...(params.token ? { token: params.token } : {}),
            ...(params.email ? { email: params.email } : {}),
            ...(params.affiliateCode ? { aff: params.affiliateCode } : {})
          })}`
        );
      } else {
        updateData = {
          module
        };
      }

      this.setState({
        ...updateData,
        layout: !params.token ? "VERIFY" : "SIGNUP",
        affiliateCode: params.aff || "",
        lmd: params.lmd,
        token: params.token,
        email: params.email
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      history,
      callingRegisterAccount,
      errorMessage,
      callingSendEmailVerify
    } = this.props;
    const { module } = this.state;

    if (match.params && match.params.module && match.params.module !== module) {
      this.setState({ module: match.params.module });
    }

    if (prevProps.callingRegisterAccount && !callingRegisterAccount && !errorMessage.code) {
      const { firstName, lastName, email } = this.state;
      const { registerResponse } = this.props;
      const moduleF = defines[module];
      if (moduleF.name === "AZINSIGHT") {
        history.push(
          `/asin-zen-insight/sign-up?inf_field_Email=${email}&inf_field_FirstName=${firstName}&inf_field_LastName=${lastName}&inf_field_Password=&contactId=${registerResponse.contactId}`
        );
      } else if (moduleF.name === "BULK_CHECKER_PRO") {
        window.location.href = "https://asinzen.co/welcome-to-bulkchecker/";
      } else if (moduleF.name === "AZALERT") {
        window.location.href = "https://goto.asinzen.com/azalert-after-signup";
      }
    }

    if (prevProps.callingSendEmailVerify && !callingSendEmailVerify && !errorMessage.code) {
      this.setState({ email: "", layout: "THANKYOU" });
    }
  }

  onSubmit = () => {
    this.props.userRegisterAccountRequest({
      ...this.state,
      module: defines[this.state.module].name
    });
  };

  renderSignUp = () => {
    const { classes, callingRegisterAccount, errorMessage } = this.props;
    const {
      layout,
      module,
      firstName,
      lastName,
      email,
      password,
      repassword,
      errorPassword
    } = this.state;
    if (!module || layout !== "SIGNUP") return null;
    const { errors = null } = errorMessage;

    return (
      <>
        {ERRORS[errorMessage.code] && (
          <Grid item xs={12}>
            <p
              className="error-text"
              style={{ textAlign: "center", fontSize: "1.2em", margin: 0, padding: 0 }}
            >
              {ERRORS[errorMessage.code]}
            </p>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl fullWidth>
            <OutlinedInput
              error={errors && errors.firstName}
              classes={{
                root: classes.input
              }}
              value={firstName}
              onChange={this.handleChangeTextField("firstName")}
              placeholder="First name"
            />
            {errors && errors.firstName && <p className="error-text">{errors.firstName}</p>}
          </FormControl>
        </Grid>
        {/*first name*/}

        <Grid item xs={6}>
          <FormControl fullWidth>
            <OutlinedInput
              error={errors && errors.lastName}
              classes={{
                root: classes.input
              }}
              value={lastName}
              onChange={this.handleChangeTextField("lastName")}
              placeholder="Last name"
            />
            {errors && errors.lastName && <p className="error-text">{errors.lastName}</p>}
          </FormControl>
        </Grid>
        {/*last name*/}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedInput
              autoComplete="new-password"
              error={errors && errors.email}
              classes={{
                root: classes.input
              }}
              value={email}
              onChange={this.handleChangeTextField("email")}
              placeholder="Email"
              disabled={true}
            />
            {errors && errors.email && <p className="error-text">{errors.email}</p>}
          </FormControl>
        </Grid>
        {/*email*/}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedInput
              autoComplete="new-password"
              classes={{
                root: classes.input
              }}
              value={password}
              type="password"
              onChange={this.handleChangeTextField("password")}
              placeholder="Password"
            />
            {errors && errors.password && <p className="error-text">{errors.password}</p>}
            {errorPassword && <p className="error-text">{errorPassword}</p>}
          </FormControl>
        </Grid>
        {/*password*/}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedInput
              autoComplete="new-password"
              classes={{
                root: classes.input
              }}
              value={repassword}
              type="password"
              onChange={this.handleChangeTextField("repassword")}
              placeholder="Confirm password"
            />
          </FormControl>
        </Grid>
        {/*repassword*/}

        <Grid item xs={12}>
          <div style={{ paddingBottom: 7 }}>
            By signing up, you agree to our{" "}
            <a href="https://asinzen.com/terms" target="_blank">
              terms
            </a>{" "}
            and{" "}
            <a href="https://asinzen.com/privacy-policy" target="_blank">
              privacy policy
            </a>
          </div>
          <Button
            fullWidth
            className={classes.buttonSubmit}
            variant="contained"
            onClick={this.onSubmit}
            disabled={callingRegisterAccount}
          >
            SIGN UP
          </Button>
          <Divider style={{ margin: "7px 0px" }} />
          <div style={{ paddingTop: 7 }}>
            Have an account? <Link to="/sign-in">Log in</Link> here.
          </div>
        </Grid>
      </>
    );
  };

  onSendEmailVerify = () => {
    this.props.sendEmailVerifyRequest({
      ...this.state,
      module: defines[this.state.module].name
    });
  };

  renderVerifyEmail = () => {
    const { classes, errorMessage, callingSendEmailVerify } = this.props;
    const { layout, module, email } = this.state;
    if (!module || layout !== "VERIFY") return null;
    const { errors = null } = errorMessage;

    return (
      <>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <OutlinedInput
              autoComplete="new-password"
              error={errors && errors.email}
              classes={{
                root: classes.input
              }}
              value={email}
              onChange={this.handleChangeTextField("email")}
              placeholder="Email"
            />
            {errors && errors.email && <p className="error-text">{errors.email}</p>}
            {ERRORS[errorMessage.code] && <p className="error-text">{ERRORS[errorMessage.code]}</p>}
          </FormControl>
        </Grid>
        {/*email*/}

        <Grid item xs={12}>
          <div style={{ paddingBottom: 7 }}>
            By signing up, you agree to our{" "}
            <a href="https://asinzen.com/terms" target="_blank">
              terms
            </a>{" "}
            and{" "}
            <a href="https://asinzen.com/privacy-policy" target="_blank">
              privacy policy
            </a>
          </div>
          <Button
            fullWidth
            className={classes.buttonSubmit}
            variant="contained"
            onClick={this.onSendEmailVerify}
            disabled={callingSendEmailVerify}
          >
            GET STARTED
          </Button>
          <Divider style={{ margin: "7px 0px" }} />
          <div style={{ paddingTop: 15 }}>
            Have an account? <Link to="/sign-in">Log in</Link> here.
          </div>
          <div style={{ paddingTop: 20 }}>
            Have trouble?{" "}
            <a href="https://support.asinzen.com/" target="_blank">
              We can help
            </a>
          </div>
        </Grid>
      </>
    );
  };

  renderThankyou = () => {
    const { layout } = this.state;
    if (layout !== "THANKYOU") return null;
    return (
      <>
        <div style={{ backgroundColor: "#3a95de", padding: "2em", color: "white" }}>
          <div style={{ fontSize: "3.3em" }}>You’re almost done.</div>
          <div style={{ fontSize: "2.2em" }}>Please verify your email</div>
          <div style={{ padding: "1em 0" }}>
            <img src={SendEmailImage} width={120} alt="" />
          </div>
          <div style={{ fontSize: "1.1em" }}>
            We’ve sent you an activation email. Please check your inbox and follow the link to
            complete your registration.
          </div>
        </div>
      </>
    );
  };

  render() {
    const { classes } = this.props;
    const { module, layout } = this.state;
    if (!module) return null;
    const item = defines[module];

    return (
      <Grid container className={classes.root}>
        {layout !== "THANKYOU" && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <a href="https://asinzen.com/">
              <img src={item.image} />
            </a>
          </Grid>
        )}
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid item xs={12}>
              {layout === "SIGNUP" && (
                <h1 style={{ textAlign: "center", fontSize: "1rem" }}>Sign Up</h1>
              )}
              {layout === "VERIFY" && (
                <>
                  <h2 style={{ textAlign: "center", fontSize: "1.2rem", fontWeight: "normal" }}>
                    Welcome!
                  </h2>
                  <h3 style={{ textAlign: "center", fontSize: "1rem" }}>
                    Type your email address to begin
                  </h3>
                </>
              )}
            </Grid>
            {this.renderSignUp()}
            {this.renderVerifyEmail()}
            {this.renderThankyou()}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const withConnect = connectAutoDispatch(
  ({ userState }) => ({
    callingRegisterAccount: userState.callingRegisterAccount,
    callingSendEmailVerify: userState.callingSendEmailVerify,
    errorMessage: userState.errorMessage,
    registerResponse: userState.registerResponse
  }),
  {
    userRegisterAccountRequest,
    sendEmailVerifyRequest
  }
);

export default compose(withConnect, withStyles(styles))(SignUpContainerNew);
