import { UserActionType } from "redux/actions/UserActions";
import update from "immutability-helper";
import PropTypes from "prop-types";
import constants from "utils/constants";

export const userPropTypes = {
  userInfo: PropTypes.shape({
    _id: PropTypes.string,
    accountType: PropTypes.string,
    email: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    banAllAccess: PropTypes.bool,
    isLogin: PropTypes.string,
    contactId: PropTypes.number,
    listMarketplace: PropTypes.shape({}),
    plan_info: PropTypes.shape({}),
    isSignUpDone: PropTypes.bool,
    isFirstConfigMWS: PropTypes.bool,
    productType: PropTypes.string,
    nextSubscribeAt: PropTypes.string,
    subscribeCreatedAt: PropTypes.string,
    planName: PropTypes.string,
    registerFrom: PropTypes.string,
    enableAsinTracker: PropTypes.bool,
    accessProducts: PropTypes.bool,
    accessHistory: PropTypes.bool,
    accessSeller: PropTypes.bool
  }),
  callingRequestLogin: PropTypes.bool,
  callingValidateMws: PropTypes.bool,
  callingGetListMember: PropTypes.bool,
  callingAddNewMember: PropTypes.bool,
  callingEditMember: PropTypes.bool,
  callingSignUp: PropTypes.bool,
  callingUpdateInfoWithoutMWS: PropTypes.bool,
  callingActiveMember: PropTypes.bool,
  callingResetPassword: PropTypes.bool,
  callingForgotPassword: PropTypes.bool,
  callingUpdateMws: PropTypes.bool,
  callingGetListMarketplace: PropTypes.bool,
  callingUpdateMarketplace: PropTypes.bool,
  callingClearMarketplace: PropTypes.bool,
  callingCancelSubscriptionPlan: PropTypes.bool,
  callingRegisterAccount: PropTypes.bool,
  callingSendEmailVerify: PropTypes.bool,
  callingRecordSourcingHistoryMember: PropTypes.bool,
  phoneNumberSentCode: PropTypes.bool,
  resetPasswordSuccess: PropTypes.bool,
  addNewMemberSuccess: PropTypes.bool,
  editMemberSuccess: PropTypes.bool,
  accessHistoryMemberSuccess: PropTypes.bool,
  recordSourcingHistoryMemberSuccess: PropTypes.bool,
  activeMemberSuccess: PropTypes.bool,
  callingDeleteMember: PropTypes.bool,
  deleteMemberSuccess: PropTypes.bool,
  mwsValidate: PropTypes.bool,
  codeVerified: PropTypes.bool,
  listMember: PropTypes.array,
  listMarketplace: PropTypes.shape({}),
  errorMessage: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string
  }),
  errorAddMember: PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.shape({})
  })
};

export const userInitState = {
  userInfo: {
    _id: "",
    accountType: null,
    email: "",
    lastName: "",
    firstName: "",
    fullName: "",
    banAllAccess: false,
    isLogin: false,
    contactId: 0,
    listMarketplace: {},
    isSignUpDone: false,
    isFirstConfigMWS: false,
    productType: "",
    nextSubscribeAt: "",
    subscribeCreatedAt: "",
    planName: "",
    registerFrom: "",
    enableAsinTracker: false,
    startTrialTime: null,
    freeTrialType: null,
    isNotSpToken: null,
    accessProducts: false,
    accessSeller: false,
    accessHistory: false
  },
  callingRequestLogin: false,
  callingValidateMws: false,
  callingGetListMember: false,
  callingAddNewMember: false,
  callingEditMember: false,
  callingSignUp: false,
  callingUpdateInfoWithoutMWS: false,
  callingUpdateMws: false,
  callingResetPassword: false,
  callingForgotPassword: false,
  callingDeleteMember: false,
  callingGetListMarketplace: false,
  callingUpdateMarketplace: false,
  callingClearMarketplace: false,
  callingChangePassword: false,
  callingChangeEmail: false,
  callingCancelSubscriptionPlan: false,
  callingRegisterAccount: false,
  callingSendEmailVerify: false,
  callingCopySettings: false,
  callingSendInstructions: false,
  callingRecordSourcingHistoryMember: false,
  phoneNumberSentCode: false,
  registerResponse: {},
  deleteMemberSuccess: false,
  resetPasswordSuccess: false,
  addNewMemberSuccess: false,
  editMemberSuccess: false,
  accessHistoryMemberSuccess: false,
  recordSourcingHistoryMemberSuccess: false,
  copySettingsSuccess: false,
  sendInstructionsSuccess: false,
  mwsValidate: false,
  codeVerified: false,
  listMember: [],
  listMarketplace: {},
  errorMessage: {}
};

export default function createReducer(state = userInitState, action) {
  switch (action.type) {
    case UserActionType.USER_LOGIN_REQUEST:
      return update(state, {
        callingRequestLogin: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_LOGIN_SUCCESS:
      if (action.payload.info.remember) {
        localStorage.setItem(constants.USER_KEY, action.payload.info.email);
        localStorage.setItem(constants.PASSWORD_KEY, action.payload.info.password);
      } else {
        localStorage.removeItem(constants.USER_KEY);
        localStorage.removeItem(constants.PASSWORD_KEY);
      }
      return update(state, {
        callingRequestLogin: { $set: false },
        errorMessage: { $set: {} },
        userInfo: action.payload.userInfo
      });

    case UserActionType.USER_LOGIN_FAILURE:
      return update(state, {
        callingRequestLogin: { $set: false },
        ...action.payload
      });

    case UserActionType.GET_USER_INFO_APP_SUCCESS:
      return update(state, {
        ...action.payload
      });

    case UserActionType.GET_USER_INFO_APP_FAILURE:
      return update(state, {
        ...action.payload
      });

    case UserActionType.USER_VALIDATE_MWS_REQUEST:
    case UserActionType.USER_VALIDATE_MWS_TOKEN_REQUEST:
      return update(state, {
        callingValidateMws: { $set: true },
        mwsValidate: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_VALIDATE_MWS_SUCCESS:
    case UserActionType.USER_VALIDATE_MWS_TOKEN_SUCCESS:
      return update(state, {
        callingValidateMws: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_VALIDATE_MWS_FAILURE:
    case UserActionType.USER_VALIDATE_MWS_TOKEN_FAILURE:
      return update(state, {
        callingValidateMws: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_GET_LIST_MEMBER_REQUEST:
      return update(state, {
        callingGetListMember: { $set: true },
        errorMessage: { $set: {} },
        listMember: { $set: [] }
      });

    case UserActionType.USER_GET_LIST_MEMBER_SUCCESS:
      return update(state, {
        callingGetListMember: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_GET_LIST_MEMBER_FAILURE:
      return update(state, {
        callingGetListMember: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_ADD_NEW_MEMBER_REQUEST:
      return update(state, {
        callingAddNewMember: { $set: true },
        addNewMemberSuccess: { $set: false },
        errorAddMember: { $set: { code: "", message: {} } }
      });

    case UserActionType.USER_ADD_NEW_MEMBER_SUCCESS:
      return update(state, {
        callingAddNewMember: { $set: false },
        addNewMemberSuccess: { $set: true },
        errorAddMember: { $set: { code: "", message: {} } }
      });

    case UserActionType.USER_ADD_NEW_MEMBER_FAILURE:
      return update(state, {
        callingAddNewMember: { $set: false },
        addNewMemberSuccess: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_EDIT_MEMBER_REQUEST:
      return update(state, {
        callingEditMember: { $set: true },
        editMemberSuccess: { $set: false },
        errorAddMember: { $set: { code: "", message: {} } }
      });

    case UserActionType.USER_EDIT_MEMBER_SUCCESS:
      return update(state, {
        callingEditMember: { $set: false },
        editMemberSuccess: { $set: true },
        errorAddMember: { $set: { code: "", message: {} } }
      });

    case UserActionType.USER_EDIT_MEMBER_FAILURE:
      return update(state, {
        callingEditMember: { $set: false },
        editMemberSuccess: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_ACCESS_HISTORY_REQUEST:
      return update(state, {
        callingAccessHistoryMember: { $set: true },
        accessHistoryMemberSuccess: { $set: false }
        // errorAddMember: { $set: { code: "", message: {} } }
      });

    case UserActionType.USER_ACCESS_HISTORY_SUCCESS:
      return update(state, {
        callingAccessHistoryMember: { $set: false },
        accessHistoryMemberSuccess: { $set: true }
        // errorAddMember: { $set: { code: "", message: {} } }
      });

    case UserActionType.USER_ACCESS_HISTORY_FAILURE:
      return update(state, {
        callingAccessHistoryMember: { $set: false },
        accessHistoryMemberSuccess: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_RECORD_SOURCING_HISTORY_REQUEST:
      return update(state, {
        callingRecordSourcingHistoryMember: { $set: true },
        recordSourcingHistoryMemberSuccess: { $set: false }
      });

    case UserActionType.USER_RECORD_SOURCING_HISTORY_SUCCESS:
      return update(state, {
        callingRecordSourcingHistoryMember: { $set: false },
        recordSourcingHistoryMemberSuccess: { $set: true }
      });

    case UserActionType.USER_RECORD_SOURCING_HISTORY_FAILURE:
      return update(state, {
        callingRecordSourcingHistoryMember: { $set: false },
        recordSourcingHistoryMemberSuccess: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_SIGNUP_REQUEST:
      return update(state, {
        callingSignUp: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_SIGNUP_SUCCESS:
      return update(state, {
        callingSignUp: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_SIGNUP_FAILURE:
      return update(state, {
        callingSignUp: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_ACTIVE_MEMBER_REQUEST:
      return update(state, {
        callingActiveMember: { $set: true },
        activeMemberSuccess: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_ACTIVE_MEMBER_SUCCESS:
      return update(state, {
        callingActiveMember: { $set: false },
        activeMemberSuccess: { $set: true },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_ACTIVE_MEMBER_FAILURE:
      return update(state, {
        callingActiveMember: { $set: false },
        activeMemberSuccess: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_RESET_PASSWORD_REQUEST:
      return update(state, {
        callingResetPassword: { $set: true },
        resetPasswordSuccess: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_RESET_PASSWORD_SUCCESS:
      return update(state, {
        callingResetPassword: { $set: false },
        resetPasswordSuccess: { $set: true },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_RESET_PASSWORD_FAILURE:
      return update(state, {
        callingResetPassword: { $set: false },
        resetPasswordSuccess: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_UPDATE_INFO_WITHOUT_MWS_REQUEST:
      return update(state, {
        callingUpdateInfoWithoutMWS: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_UPDATE_INFO_WITHOUT_MWS_SUCCESS:
      return update(state, {
        callingUpdateInfoWithoutMWS: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_UPDATE_INFO_WITHOUT_MWS_FAILURE:
      return update(state, {
        callingUpdateInfoWithoutMWS: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_UPDATE_MWS_REQUEST:
      return update(state, {
        callingUpdateMws: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_UPDATE_MWS_SUCCESS:
      return update(state, {
        callingUpdateMws: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_UPDATE_MWS_FAILURE:
      return update(state, {
        callingUpdateMws: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_DELETE_MEMBER_REQUEST:
      return update(state, {
        callingDeleteMember: { $set: true },
        deleteMemberSuccess: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_DELETE_MEMBER_SUCCESS:
      return update(state, {
        callingDeleteMember: { $set: false },
        deleteMemberSuccess: { $set: true },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_DELETE_MEMBER_FAILURE:
      return update(state, {
        callingDeleteMember: { $set: false },
        deleteMemberSuccess: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_FORGOT_PASSWORD_REQUEST:
      return update(state, {
        callingForgotPassword: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_FORGOT_PASSWORD_SUCCESS:
      return update(state, {
        callingForgotPassword: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_FORGOT_PASSWORD_FAILURE:
      return update(state, {
        callingForgotPassword: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_GET_LIST_MARKETPLACE_REQUEST:
      return update(state, {
        callingGetListMarketplace: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_GET_LIST_MARKETPLACE_SUCCESS:
      return update(state, {
        callingGetListMarketplace: { $set: false },
        callingClearMarketplace: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_GET_LIST_MARKETPLACE_FAILURE:
      return update(state, {
        callingGetListMarketplace: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_UPDATE_MARKETPLACE_REQUEST:
      return update(state, {
        callingUpdateMarketplace: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_UPDATE_MARKETPLACE_SUCCESS:
      return update(state, {
        callingUpdateMarketplace: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_UPDATE_MARKETPLACE_FAILURE:
      return update(state, {
        callingUpdateMarketplace: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_CLEAR_MARKETPLACE_REQUEST:
      return update(state, {
        callingClearMarketplace: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_CLEAR_MARKETPLACE_SUCCESS:
      return update(state, {
        callingClearMarketplace: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_CLEAR_MARKETPLACE_FAILURE:
      return update(state, {
        callingClearMarketplace: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_CHANGE_PASSWORD_REQUEST:
      return update(state, {
        callingChangePassword: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_CHANGE_PASSWORD_SUCCESS:
      return update(state, {
        callingChangePassword: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_CHANGE_PASSWORD_FAILURE:
      return update(state, {
        callingChangePassword: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_CHANGE_EMAIL_REQUEST:
      return update(state, {
        callingChangeEmail: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_CHANGE_EMAIL_SUCCESS:
      return update(state, {
        callingChangeEmail: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_CHANGE_EMAIL_FAILURE:
      return update(state, {
        callingChangeEmail: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_CANCEL_SUBSCRIPTION_PLAN_REQUEST:
      return update(state, {
        callingCancelSubscriptionPlan: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_CANCEL_SUBSCRIPTION_PLAN_SUCCESS:
      return update(state, {
        callingCancelSubscriptionPlan: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_CANCEL_SUBSCRIPTION_PLAN_FAILURE:
      return update(state, {
        callingCancelSubscriptionPlan: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_REGISTER_ACCOUNT_REQUEST:
      return update(state, {
        callingRegisterAccount: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_REGISTER_ACCOUNT_SUCCESS:
      return update(state, {
        callingRegisterAccount: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_REGISTER_ACCOUNT_FAILURE:
      return update(state, {
        callingRegisterAccount: { $set: false },
        ...action.payload
      });

    case UserActionType.SEND_EMAIL_VERIFY_REQUEST:
      return update(state, {
        callingSendEmailVerify: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.SEND_EMAIL_VERIFY_SUCCESS:
      return update(state, {
        callingSendEmailVerify: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.SEND_EMAIL_VERIFY_FAILURE:
      return update(state, {
        callingSendEmailVerify: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_COPY_CALCULATOR_SETTINGS_REQUEST:
      return update(state, {
        callingCopySettings: { $set: true },
        copySettingsSuccess: { $set: false }
      });

    case UserActionType.USER_COPY_CALCULATOR_SETTINGS_SUCCESS:
      return update(state, {
        callingCopySettings: { $set: false },
        copySettingsSuccess: { $set: true }
      });

    case UserActionType.USER_COPY_CALCULATOR_SETTINGS_FAILURE:
      return update(state, {
        callingCopySettings: { $set: false }
      });

    case UserActionType.USER_SEND_INSTRUCTIONS_REQUEST:
      return update(state, {
        callingSendInstructions: { $set: true },
        sendInstructionsSuccess: { $set: false }
      });

    case UserActionType.USER_SEND_INSTRUCTIONS_SUCCESS:
      return update(state, {
        callingSendInstructions: { $set: false },
        sendInstructionsSuccess: { $set: true }
      });

    case UserActionType.USER_SEND_INSTRUCTIONS_FAILURE:
      return update(state, {
        callingSendInstructions: { $set: false }
      });

    case UserActionType.USER_AUTHORIZE_MWS_REQUEST:
      return update(state, {
        callingSignUp: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_AUTHORIZE_MWS_SUCCESS:
      return update(state, {
        callingSignUp: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_AUTHORIZE_MWS_FAILURE:
      return update(state, {
        callingSignUp: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_AUTHORIZE_MWS_UPDATE_REQUEST:
      return update(state, {
        callingUpdateMws: { $set: true },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_AUTHORIZE_MWS_UPDATE_SUCCESS:
      return update(state, {
        callingUpdateMws: { $set: false },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_AUTHORIZE_MWS_UPDATE_FAILURE:
      return update(state, {
        callingUpdateMws: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_SEND_PHONE_NUMBER_REQUEST:
      return update(state, {
        phoneNumberSentCode: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_SEND_PHONE_NUMBER_SUCCESS:
      return update(state, {
        phoneNumberSentCode: { $set: true },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_SEND_PHONE_NUMBER_FAILURE:
      return update(state, {
        phoneNumberSentCode: { $set: false },
        ...action.payload
      });

    case UserActionType.USER_VERIFY_CODE_REQUEST:
      return update(state, {
        callingSignUp: { $set: true },
        codeVerified: { $set: false },
        errorMessage: { $set: {} }
      });

    case UserActionType.USER_VERIFY_CODE_SUCCESS:
      return update(state, {
        callingSignUp: { $set: false },
        codeVerified: { $set: true },
        errorMessage: { $set: {} },
        ...action.payload
      });

    case UserActionType.USER_VERIFY_CODE_FAILURE:
      return update(state, {
        callingSignUp: { $set: false },
        codeVerified: { $set: false },
        ...action.payload
      });

    default:
      return state;
  }
}
