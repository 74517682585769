import { styled } from "@mui/material/styles";

export const Layout = styled("div", {
  name: "AzLayout",
  slot: "azlayout"
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  minHeight: "100vh",
  position: "relative",
  minWidth: "fit-content",
  width: "100%"
}));

export const Content = styled("div", {
  name: "AzContent",
  slot: "azcontent"
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  padding: `${theme.spacing(3)}`,
  position: "relative"
}));
