import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import {
  apiGetTeamHistory,
  apiGetAllAccounts,
  apiGetHistoryUsageStatistics,
  apiGetSummaryHistoryUsageStatistics
} from "api/user";
import { debounce } from "lodash";
import { Button, Flex, Grid, Input, Select, Table, Tooltip } from "antd";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const { useBreakpoint } = Grid;

const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    if (cellValue == null) return -1;
    const dateAsString = moment(cellValue).format("DD/MM/YYYY");
    const dateParts = dateAsString.split("/");
    const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  inRangeInclusive: true
};

const textFilterParams = {
  filterOptions: ["contains", "notContains"],
  textFormatter: r => {
    if (r == null) return null;
    return r
      .toLowerCase()
      .replace(/[àáâãäå]/g, "a")
      .replace(/æ/g, "ae")
      .replace(/ç/g, "c")
      .replace(/[èéêë]/g, "e")
      .replace(/[ìíîï]/g, "i")
      .replace(/ñ/g, "n")
      .replace(/[òóôõö]/g, "o")
      .replace(/œ/g, "oe")
      .replace(/[ùúûü]/g, "u")
      .replace(/[ýÿ]/g, "y");
  },
  debounceMs: 200,
  maxNumConditions: 1
};

const listTime = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Last 30 days", value: "last30days" },
  { label: "All history", value: "all" }
];

const DETAIL_USAGE_STATISTICS_COLUMNS = {
  category: {
    id: "category",
    displayName: "Category"
  },
  marketplace: {
    id: "marketplace",
    displayName: "Marketplace"
  },
  percentage: {
    id: "percentage",
    displayName: "Percentage"
  }
};

const SUMMARY_USAGE_STATISTICS_COLUMNS = {
  name: {
    id: "name",
    displayName: "Account Name"
  },
  email: {
    id: "email",
    displayName: "Account Email"
  },
  total: {
    id: "asinsWatched",
    displayName: "ASINs searched"
  }
};

const HistoryUsageStatistics = () => {
  let gridApi = null;
  let gridColumnApi = null;
  const limit = 100;
  const screens = useBreakpoint();

  const [agGridApi, setAgGridApi] = useState();
  const [loadingSummaryTable, setLoadingSummaryTable] = useState(false);
  const [sortedColumn, setSortedColumn] = useState();
  const [state, setState] = useState({
    time: "today",
    listAccount: [{ label: "All accounts", value: "all" }],
    account: "all",
    summaryUsageStatistics: [],
    order: "asc",
    orderBy: "email"
  });

  const handleTableSortChange = (_pagination, _filter, sorter) => {
    const { field } = sorter;
    onGetSummaryUsageStatisticAccounts(field);
  };

  const onGetSummaryUsageStatisticAccounts = fieldOrder => {
    setLoadingSummaryTable(true);
    const { orderBy, order, time, account, listAccount } = state;
    const sort = orderBy === fieldOrder && order === "asc" ? "desc" : "asc";

    setState(prevState => ({
      ...prevState,
      order: sort,
      orderBy: fieldOrder || orderBy
    }));
    apiGetSummaryHistoryUsageStatistics({
      sortModel: [
        {
          colId: fieldOrder || orderBy,
          sort
        }
      ],
      time,
      account
    })
      .then(resp => {
        const accountSummaryUsageStatistics = resp
          .map(item => {
            const account = listAccount.find(acc => {
              return item.value === acc.email;
            });
            return account ? Object.assign({}, account, item) : {};
          })
          .filter(item => Object.values(item).length !== 0);

        if (account !== "all") {
          setState(prevState => ({
            ...prevState,
            summaryUsageStatistics: accountSummaryUsageStatistics.filter(
              item => item.email === account
            )
          }));
        } else {
          setState(prevState => ({
            ...prevState,
            summaryUsageStatistics: accountSummaryUsageStatistics
          }));
        }
        setLoadingSummaryTable(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: DETAIL_USAGE_STATISTICS_COLUMNS.category.displayName,
      field: DETAIL_USAGE_STATISTICS_COLUMNS.category.id,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: DETAIL_USAGE_STATISTICS_COLUMNS.marketplace.displayName,
      field: DETAIL_USAGE_STATISTICS_COLUMNS.marketplace.id,
      width: 160,
      filter: "agTextColumnFilter",
      filterParams: textFilterParams
    },
    {
      headerName: DETAIL_USAGE_STATISTICS_COLUMNS.percentage.displayName,
      field: DETAIL_USAGE_STATISTICS_COLUMNS.percentage.id,
      width: 160,
      valueFormatter: params => Math.round(params.value * 100) / 100,
      sortable: true
    }
  ]);

  const [summaryColumnDefs, setSummaryColumnDefs] = useState([
    {
      title: SUMMARY_USAGE_STATISTICS_COLUMNS.name.displayName,
      dataIndex: SUMMARY_USAGE_STATISTICS_COLUMNS.name.id
    },
    {
      title: SUMMARY_USAGE_STATISTICS_COLUMNS.email.displayName,
      dataIndex: SUMMARY_USAGE_STATISTICS_COLUMNS.email.id,
      sorter: true,
      sortIcon: ({ sortOrder }) =>
        (sortOrder === "descend" && <FaSortDown />) ||
        (sortOrder === "ascend" && <FaSortUp />) ||
        null
    },
    {
      title: SUMMARY_USAGE_STATISTICS_COLUMNS.total.displayName,
      dataIndex: SUMMARY_USAGE_STATISTICS_COLUMNS.total.id,
      sorter: true,
      sortIcon: ({ sortOrder }) =>
        (sortOrder === "descend" && <FaSortDown />) ||
        (sortOrder === "ascend" && <FaSortUp />) ||
        null
    }
  ]);

  const [gridOptions, setGridOptions] = useState(
    Object.create({
      rowModelType: "serverSide",
      rowSelection: "multiple",
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true // set filtering on for all cols
      },
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: () => true,
      domLayout: "autoHeight",
      cacheBlockSize: limit,
      overlayNoRowsTemplate: "No results found"
    })
  );

  const showListAccounts = allAccounts => {
    const list = [{ label: "All accounts", value: "all" }];
    // eslint-disable-next-line
    for (const account of allAccounts) {
      list.push({ label: account.email, value: account.email });
    }
    setState(prevState => ({
      ...prevState,
      listAccount: list
    }));
  };

  const onDetailUsageStatisticsGridReady = params => {
    gridApi = params.api;
    setAgGridApi(gridApi);

    gridApi.setColumnDefs(columnDefs);
    gridColumnApi = params.columnApi;

    gridApi.closeToolPanel();
    onGetDetailUsageStatistics();
  };

  const onGetDetailUsageStatistics = () => {
    const dataSource = getServerSideDatasource();
    agGridApi?.setServerSideDatasource(dataSource);

    agGridApi?.sizeColumnsToFit({ defaultMaxWidth: 1000 });
  };

  function getServerSideDatasource() {
    return {
      getRows: debounce(params => {
        const { time, account } = state;
        const { startRow, sortModel, filterModel } = params.request;
        const page = startRow === 0 || startRow === undefined ? 1 : startRow / limit + 1;
        apiGetHistoryUsageStatistics({
          page,
          limit,
          sortModel,
          filterModel,
          time,
          account
        })
          .then(resp => {
            const resultTotal = resp?.length || 0;
            if (resultTotal === 0) {
              agGridApi.showNoRowsOverlay();
            }
            params.successCallback(resp || [], resultTotal);
          })
          .catch(error => {
            console.error(error);
            params.fail();
          });
      }, 500)
    };
  }

  const getRowNodeId = params => params._id;

  const onChangeTime = value =>
    setState(prevState => ({
      ...prevState,
      time: value
    }));

  const onChangeAccount = value =>
    setState(prevState => ({
      ...prevState,
      account: value
    }));

  useEffect(() => {
    onGetDetailUsageStatistics();
    onGetSummaryUsageStatisticAccounts();
  }, [state.listAccount, state.time, state.account]);

  useEffect(() => {
    apiGetAllAccounts().then(data => showListAccounts(data));
  }, []);

  return (
    <Flex vertical gap="middle">
      <div>Filter by:</div>
      <Flex align="center" gap="middle">
        <div>Time</div>
        <Select
          onChange={onChangeTime}
          value={state.time}
          options={listTime.map(item => ({
            label: item.label,
            value: item.value
          }))}
          popupMatchSelectWidth={false}
        />
        <div>and Account email</div>
        <Select
          onChange={onChangeAccount}
          value={state.account}
          options={state.listAccount.map(item => ({
            label: item.label,
            value: item.value
          }))}
          popupMatchSelectWidth={false}
        />
      </Flex>
      <Flex vertical={!screens.sm || !screens.md} justify="space-between" gap="large">
        <div style={{ width: "100%", maxWidth: 600 }}>
          <Table
            bordered
            columns={summaryColumnDefs}
            rowKey={record => record.email}
            dataSource={state.summaryUsageStatistics}
            loading={loadingSummaryTable}
            onChange={handleTableSortChange}
          />
        </div>
        <div className="ag-theme-alpine" style={{ width: "100%", maxWidth: 800, height: "100%" }}>
          <AgGridReact
            style={{ width: "100%", height: "100%" }}
            animateRows
            enableRangeSelection
            getRowNodeId={getRowNodeId}
            gridOptions={gridOptions}
            onGridReady={onDetailUsageStatisticsGridReady}
            // sideBar="columns"
            // localeText={{ columns: "Configure Columns" }}
            pagination
            paginationPageSize={limit}
          />
        </div>
      </Flex>
    </Flex>
  );
};

export default HistoryUsageStatistics;
