import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import { Container, Stack, Box, CircularProgress } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";

import { getTopRatedSellers } from "api/masterdb/seller";
import { AZApiStyle } from "./TopRatedSellers.style";
import AZApiAppBar from "../AZApiAppBar";
import AgTable from "components/Table/AgTable";
import constants from "utils/constants";
import { getConfiguredFields, getEnabledFields } from "utils/asinzen-api/table";
import { columnHeaders } from "constants/asinzen-api/topRatedSellers";
import { Link } from "react-router-dom";

const AZApi = ({ classes }) => {
  const [states, setStates] = useState({
    page: 0,
    rowsPerPage: 5,
    marketplace: "US",
    data_items: [],
    headers: []
  });
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const retrieveTopRatedSellers = async params => {
    try {
      const res = await getTopRatedSellers(params);
      return res.data;
    } catch (e) {
      alert("Error in calling api, please check api");
      console.error(e);
      return {};
    }
  };

  const mappingDataItems = dataItems => {
    const result = [];
    const keyItems = Object.keys(dataItems);

    if (keyItems.length > 0) {
      keyItems.map(sellerId => {
        result.push({
          seller_id: sellerId,
          ...dataItems[sellerId].feedback,
          listing_count: dataItems[sellerId].listing_count,
          seller_name: dataItems[sellerId].name,
          seller_storefront: `/az-api/seller-storefront?sellerId=${sellerId}`,
          seller_metrics: `/az-api/seller-storefront?tab=2&sellerId=${sellerId}`
        });
      });
    }

    return result;
  };

  const handleChangePage = (event, newPage) => {
    setStates(prevStates => ({ ...prevStates, page: newPage }));
  };

  const handleChangeRowsPerPage = event => {
    setStates(prevStates => ({ ...prevStates, rowsPerPage: parseInt(event.target.value, 10) }));
  };

  const handleChangeMarketplace = ({ marketplace }) => {
    setStates(prevStates => ({ ...prevStates, marketplace }));
  };

  const getDataTable = async () => {
    let headers = getEnabledFields(columnHeaders, []);
    const addtionalHeaders = [
      {
        field: "seller_storefront",
        headerName: "",
        enableValue: true,
        filter: false,
        width: 200,
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        },
        cellRenderer: ({ value }: ICellRendererParams) => (
          <Link to={value} style={{ display: "flex" }}>
            <StorefrontOutlinedIcon />
          </Link>
        )
      },
      {
        field: "seller_metrics",
        headerName: "",
        enableValue: true,
        filter: false,
        width: 200,
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        },
        cellRenderer: ({ value }: ICellRendererParams) => (
          <Link to={value} style={{ display: "flex" }}>
            <AssessmentOutlinedIcon />
          </Link>
        )
      }
    ];
    const columnSellerName = headers.shift();
    addtionalHeaders.unshift(columnSellerName);
    headers = [...addtionalHeaders, ...headers];

    const params = {
      marketplace: states.marketplace,
      included_data: [],
      qty: 5,
      page: "4",
      per_page: 1
    };

    const result = await retrieveTopRatedSellers(params);
    const data_items = mappingDataItems(result);

    return {
      data_items,
      headers
    };
  };

  useEffect(async () => {
    setIsLoadingTable(true);
    const dataTable = await getDataTable();
    setStates(prevStates => {
      setIsLoadingTable(false);
      return {
        ...prevStates,
        ...dataTable
      };
    });
  }, []);

  return (
    <Stack py={3} spacing={3}>
      <AZApiAppBar title="Top Rated Sellers" onChangeMarketplace={handleChangeMarketplace} />
      <Box px={2}>
        {isLoadingTable ? (
          <CircularProgress />
        ) : (
          <AgTable dataItems={states.data_items} headers={states.headers} />
        )}
      </Box>
      {/*
      // <TableContainer component={Paper}>
      //   <Table className={classes.table} aria-label="simple table">
      //     <TableHead>
      //       <TableRow>
      //         <TableCell>#</TableCell>
      //         <TableCell align="right">c</TableCell>
      //         <TableCell align="right">&nbsp;</TableCell>
      //         <TableCell align="right">&nbsp;</TableCell>
      //         <TableCell align="right">Listings count</TableCell>
      //         <TableCell align="right">Rating</TableCell>
      //         <TableCell align="right">Rating</TableCell>
      //         <TableCell align="right">Rating</TableCell>
      //         <TableCell align="right">Rating</TableCell>
      //         <TableCell align="right">Feedback count</TableCell>
      //         <TableCell align="right">Feedback count</TableCell>
      //         <TableCell align="right">Feedback count</TableCell>
      //         <TableCell align="right">Feedback count</TableCell>
      //         <TableCell align="right">Seller id</TableCell>
      //       </TableRow>
      //     </TableHead>
      //     <TableBody>
      //       {rows.map(row => (
      //         <TableRow key={row.num}>
      //           <TableCell align="right">{row.num}</TableCell>
      //           <TableCell align="right">{row.sellerName}</TableCell>
      //           <TableCell align="right">{row.sellerMetric}</TableCell>
      //           <TableCell align="right">{row.storeFront}</TableCell>
      //           <TableCell align="right">{row.listingsCount}</TableCell>
      //           <TableCell align="right">{row.rating1}</TableCell>
      //           <TableCell align="right">{row.rating2}</TableCell>
      //           <TableCell align="right">{row.rating3}</TableCell>
      //           <TableCell align="right">{row.rating4}</TableCell>
      //           <TableCell align="right">{row.feedbackCount1}</TableCell>
      //           <TableCell align="right">{row.feedbackCount2}</TableCell>
      //           <TableCell align="right">{row.feedbackCount3}</TableCell>
      //           <TableCell align="right">{row.feedbackCount4}</TableCell>
      //           <TableCell align="right">{row.sellerId}</TableCell>
      //         </TableRow>
      //       ))}
      //     </TableBody>
      //   </Table>
      // </TableContainer>
      // <TablePagination
      //   rowsPerPageOptions={[5, 10, 25]}
      //   component="div"
      //   count={rows.length}
      //   page={states.page}
      //   rowsPerPage={states.rowsPerPage}
      //   onPageChange={handleChangePage}
      //   onRowsPerPageChange={handleChangeRowsPerPage}
      // />*/}
    </Stack>
  );
};

export default withStyles(AZApiStyle)(AZApi);
