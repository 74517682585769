import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Stack,
  Box,
  Typography,
  Paper,
  Modal,
  CircularProgress
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import Dropzone from "react-dropzone";
import { read, utils } from "xlsx/dist/xlsx.full.min.js";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connectAutoDispatch } from "redux/reduxConnect";
import { userPropTypes, userInitState } from "redux/reducers/UserReducer";

import { saveLocalStorage } from "utils";
import constants from "utils/constants";
import { apiGetItemsByAsins, apiGetItemsByIdentifiers } from "api/masterdb/product";
import AZApiAppBar from "../AZApiAppBar";
import DataSettings from "components/Table/DataSettings";
import { listData, columnHeaders } from "constants/asinzen-api/productViewer";
import { getConfiguredFields, getEnabledFields } from "utils/asinzen-api/table";
import ImportDataModal from "../../../../../components/Modals/ImportDataModal";
import AgTable from "../../../../../components/Table/AgTable";

const initialState = {
  data_items: {},
  headers: [],
  identifier_type: "asin",
  input_method: "identifier_direct_enter",
  file_upload: {},
  marketplace: "US",
  product_list: [],
  loadingData: false,
  select_all: {
    general: false,
    statistical: false
  },
  selections: {
    general: {
      info: false,
      hazmat_aspects: false,
      hazmat_condition: false,
      meltable: false,
      azalert: false
    },

    statistical: {
      root_rank: false,
      sales: false,
      amazon: false,
      amazon_warehouse: false,
      offers_fba_new: false,
      offers_fbm_new: false,
      offers_new: false,
      lowest_fba_new: false,
      lowest_fbm_new: false,
      lowest_new: false,
      buybox_new: false,
      offers_fba_used: false,
      offers_fbm_used: false,
      offers_used: false,
      lowest_fba_used: false,
      lowest_fbm_used: false,
      lowest_used: false,
      buybox_used: false,
      lowest_used_like_new: false,
      lowest_used_very_good: false,
      lowest_used_good: false,
      lowest_used_acceptable: false,
      review: false,
      rating: false
    }
  }
};

const ProductViewer = ({ ...props }) => {
  const [state, setState] = useState(initialState);
  const [openDataSettingsModal, setOpenDataSettingsModal] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const handleChangeStep1 = event => {
    setState(prevState => ({
      ...prevState,
      identifier_type: event.target.value
    }));
  };

  const handleChangeStep2 = event => {
    setState(prevState => ({
      ...prevState,
      input_method: event.target.value
    }));
  };

  const handleChangeData = (event, type) => {
    const { selections } = state;
    setState(prevState => ({
      ...prevState,
      selections: {
        ...selections,
        [type]: {
          ...selections[type],
          [event.target.name]: event.target.checked
        }
      }
    }));
  };

  const handleSelectAll = (event, type) => {
    const { selections, select_all } = state;
    const obj = {};
    Object.keys(selections[type]).forEach(key => {
      obj[key] = !select_all[type];
    });
    setState(prevState => ({
      ...prevState,
      select_all: {
        ...select_all,
        [type]: !select_all[type]
      },
      selections: {
        ...selections,
        [type]: obj
      }
    }));
  };

  const handleOnChangeMarketplace = mkp =>
    setState(prevState => ({ ...prevState, marketplace: mkp.marketplace }));

  const getSelectionIncluded = selections =>
    Object.entries(selections).reduce(
      (prevSelection, current) =>
        current[1] === true ? [...prevSelection, current[0]] : prevSelection,
      []
    );

  const mappingDataItems = dataItems => {
    const result = Object.keys(dataItems).map(identifier => {
      return {
        ASIN: identifier,
        ...dataItems[identifier]
      };
    });

    return result;
  };

  const getDataTable = async data => {
    const selectedFields = getConfiguredFields(data);
    const headers = getEnabledFields(columnHeaders, selectedFields);

    const included_data_general = getSelectionIncluded(data.selections.general);
    const included_data_statistics = getSelectionIncluded(data.selections.statistical);

    const params = {
      marketplace: data.marketplace,
      days: -1,
      page: "1",
      per_page: 20,
      included_data_general,
      included_data_statistics
    };

    let result = [];
    if (data.identifier_type === "asin") {
      result = await generateDataAsins(params, data.product_list);
    } else {
      result = await generateDataIdentifiers(params, data.product_list);
    }

    const dataItems = mappingDataItems(result.data);
    return {
      data_items: dataItems,
      headers
    };
  };

  const generateDataAsins = async (params, list) => {
    let promiseAll = [];
    let asins = list.splice(0, 20);
    while (asins.length > 0) {
      try {
        const req = {
          ...params,
          asins
        };
        const resp = apiGetItemsByAsins(req);
        promiseAll = [...promiseAll, resp];

        asins = list.splice(0, 20);
      } catch (e) {
        alert("Error in calling api, please check api");
        console.error(e);
        break;
      }
    }

    const [result] = await Promise.all(promiseAll);
    return result;
  };

  const generateDataIdentifiers = async (params, list) => {
    let result = [];
    let identifiers = list.splice(0, 20);
    while (identifiers.length > 0) {
      try {
        const req = {
          ...params,
          identifiers
        };
        const resp = apiGetItemsByIdentifiers(req);
        result = [...result, resp.data];

        identifiers = list.splice(0, 20);
      } catch (e) {
        alert("Error in calling api, please check api");
        console.error(e);
        break;
      }
    }

    return mappingDataItems(result);
  };

  const handleOnSubmit = async data => {
    setIsLoadingTable(true);

    const storingData = {
      selections: data.selections,
      select_all: data.select_all,
      marketplace: data.marketplace,
      file_upload: data.file_upload,
      input_method: data.input_method,
      identifier_type: data.identifier_type,
      product_list: data.product_list
    };
    await saveLocalStorage(constants.PRODUCT_VIEWER_DATA, JSON.stringify(storingData));

    const dataTable = await getDataTable(data);
    setState(prevState => {
      setIsLoadingTable(false);
      return {
        ...prevState,
        ...data,
        ...dataTable
      };
    });
  };

  const handleInputList = evt => {
    const listString = evt.target.value
      .trim(",")
      .split(",")
      .map(str => str.trim())
      .filter(str => str);

    setState(prevState => ({
      ...prevState,
      product_list: listString
    }));
  };

  const onSelectFiles = async files => {
    if (files && files[0]) {
      setState({ file_upload: files[0] });
      try {
        const reader = new window.FileReader();
        reader.onload = event => {
          const data = event.target.result;
          const workbook = read(data, { type: "binary" });
          generateRowData(workbook);
        };
        reader.readAsBinaryString(files[0]);
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const generateRowData = workbook => {
    try {
      const sheeName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheeName];
      const result = utils.sheet_to_json(worksheet);
      const len = result.length || 0;
      let rowData = [];
      for (let i = 0; i < len; i++) {
        let { ASIN = "" } = result[i];
        ASIN = ASIN.toString().trim();
        if (ASIN) {
          rowData = [...rowData, ASIN];
        }
      }
      setState(prevState => ({
        ...prevState,
        product_list: rowData
      }));
    } catch (e) {
      console.log("debug error", e);
    }
  };

  useEffect(async () => {
    setIsLoadingTable(true);
    const initialData = JSON.parse(localStorage.getItem(constants.PRODUCT_VIEWER_DATA));
    if (initialData) {
      const dataTable = await getDataTable(initialData);
      setState(prevState => ({
        ...prevState,
        ...initialData,
        ...dataTable
      }));
    }
    setIsLoadingTable(false);
  }, []);

  const handleOpenDataSettingsModal = () => {
    setOpenDataSettingsModal(true);
  };

  const handleCloseDataSettingsModal = () => {
    setOpenDataSettingsModal(false);
  };

  const handleOnSubmitDataSettingsModal = stateData => {
    handleOnSubmit(stateData);
    handleCloseDataSettingsModal();
  };

  const handleOnResetDataSettings = () => {
    localStorage.removeItem(constants.PRODUCT_VIEWER_DATA);
    setState(initialState);
  };

  const isDoneLoading = Object.keys(state.data_items).length > 0 && state.headers.length > 0;

  const renderTable = () =>
    isDoneLoading ? (
      <AgTable
        dataItems={state.data_items}
        headers={state.headers}
        showAll
        onImport={handleOpenDataSettingsModal}
        onReset={handleOnResetDataSettings}
      />
    ) : (
      <Paper variant="outlined" sx={{ py: 3, px: 4 }}>
        <DataSettings
          showStepTitles
          isAllSteps
          enabledSelection
          initialData={state}
          onSubmit={handleOnSubmit}
        />
      </Paper>
    );

  return (
    <Stack py={3} spacing={3}>
      <AZApiAppBar title="Product Viewer" onChangeMarketplace={handleOnChangeMarketplace} />
      <Box px={2}>{isLoadingTable ? <CircularProgress /> : renderTable()}</Box>
      <ImportDataModal
        title="Import"
        isOpen={openDataSettingsModal}
        onClose={handleCloseDataSettingsModal}
      >
        <DataSettings
          initialData={state}
          renderStepNumber={[2, 4]}
          onSubmit={handleOnSubmitDataSettingsModal}
        />
      </ImportDataModal>
    </Stack>
  );
};

ProductViewer.defaultProps = {
  userInfo: userInitState.userInfo,
  classes: {}
};

ProductViewer.propTypes = {
  classes: PropTypes.shape({}),
  userInfo: userPropTypes.userInfo
};

export default withRouter(
  connectAutoDispatch(
    ({ userState }) => ({
      userInfo: userState.userInfo
    }),
    {}
  )(ProductViewer)
);
