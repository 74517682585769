import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Radio,
  useTheme,
  RadioGroup,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { read, utils } from "xlsx/dist/xlsx.full.min.js";
import { listData } from "constants/asinzen-api/productViewer";
import Dropzone from "react-dropzone";
import GoogleSheets from "containers/app/asinzen-api/containers/ProductViewer/GoogleSheets";
import { toUpper } from "lodash";

const DataSettings = ({
  initialData,
  showStepTitles = false,
  isAllSteps = false,
  renderStepNumber = [],
  onSubmit,
  enabledSelection = false
}) => {
  const theme = useTheme();
  const [states, setStates] = useState(initialData);
  const [inputList, setInputList] = useState(initialData.product_list);
  const [isEnableSubmit, setIsEnableSubmit] = useState(false);

  const handleChangeStep1 = event => {
    setStates(prevState => ({
      ...prevState,
      identifier_type: event.target.value
    }));
  };

  const handleChangeStep2 = event => {
    setStates(prevState => ({
      ...prevState,
      input_method: event.target.value
    }));
  };

  const handleInputList = evt => {
    const inputValues = evt.target.value;
    setInputList(inputValues);

    const listString = inputValues
      .trim(",")
      .split(",")
      .map(str => str.trim())
      .filter(str => str);
    setStates(prevState => ({
      ...prevState,
      product_list: listString
    }));
  };

  const onSelectFiles = async files => {
    if (files && files[0]) {
      setStates(prevStates => ({ ...prevStates, file_upload: files[0] }));
      try {
        const reader = new window.FileReader();
        reader.onload = event => {
          const data = event.target.result;
          const workbook = read(data, { type: "binary" });
          generateRowData(workbook);
        };
        reader.readAsBinaryString(files[0]);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const generateRowData = workbook => {
    try {
      const sheeName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheeName];
      const result = utils.sheet_to_json(worksheet);
      const len = result.length || 0;
      let rowData = [];
      for (let i = 0; i < len; i++) {
        let { ASIN = "" } = result[i];
        ASIN = ASIN.toString().trim();
        if (ASIN) {
          rowData = [...rowData, ASIN];
        }
      }
      setStates(prevState => ({
        ...prevState,
        product_list: rowData
      }));
    } catch (e) {
      console.log("debug error", e);
    }
  };

  const handleOnRemoveFileUploaded = () => {
    setStates(prevStates => ({
      ...prevStates,
      product_list: [],
      file_upload: {}
    }));
  };

  const handleSelectAll = (event, type) => {
    const { selections, select_all } = states;
    const obj = {};
    Object.keys(selections[type]).forEach(key => {
      obj[key] = !select_all[type];
    });
    setStates(preStates => ({
      ...preStates,
      select_all: {
        ...preStates.select_all,
        [type]: !preStates.select_all[type]
      },
      selections: {
        ...preStates.selections,
        [type]: obj
      }
    }));
  };

  const handleChangeData = (event, type) => {
    const { selections } = states;
    setStates(preStates => ({
      ...preStates,
      selections: {
        ...preStates.selections,
        [type]: {
          ...selections[type],
          [event.target.name]: event.target.checked
        }
      }
    }));
  };

  const handleSheetValues = values => {
    let columnNumber;
    if (states.identifier_type === "asin") {
      columnNumber = values[0].findIndex(header => toUpper(header) === "ASIN");
    } else {
      columnNumber = values[0].findIndex(header => ["UPC", "EAN", "ISB"].includes(toUpper(header)));
    }

    values.shift();
    const listIds = values.map(d => d[columnNumber]);
    setStates(prevState => ({
      ...prevState,
      product_list: listIds
    }));
  };

  const handleOnSubmit = async () => {
    onSubmit(states);
  };

  useEffect(() => {
    if (states.product_list) {
      const identifierList = states.product_list.join(", ");
      setInputList(identifierList);
    }
  }, []);

  useEffect(() => {
    const isSelectAll = states.select_all.general || states.select_all.statistical;
    const isSelectGeneralItems = Object.values(states.selections.general).some(value => value);
    const isSelectStatisItems = Object.values(states.selections.statistical).some(value => value);

    setIsEnableSubmit(() => isSelectAll || isSelectGeneralItems || isSelectStatisItems);
  }, [states.selections || states.select_all]);

  const renderStep1 = () => (
    <Stack spacing={1}>
      <Box>
        <Typography>
          <b>Step 1: Select type of product’s identifier</b>
        </Typography>
        <Typography>
          <i>You can load up to 10,000 products.</i>
        </Typography>
      </Box>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="select-type"
          name="selectType"
          value={states.identifier_type}
          onChange={handleChangeStep1}
        >
          <FormControlLabel value="asin" control={<Radio />} label="ASINs" />
          <FormControlLabel value="other" control={<Radio />} label="UPCs/EANs/ISBs" />
        </RadioGroup>
      </FormControl>
    </Stack>
  );

  const renderStep2 = () => (
    <Stack spacing={isAllSteps ? 1 : 3}>
      <div>
        {showStepTitles && (
          <Typography>
            <b>Step 2: Provide product’s identifiers</b>
          </Typography>
        )}
        <Typography textAlign={!isAllSteps && "center"}>
          <i>You can select one of the 3 methods below</i>
        </Typography>
      </div>
      <div className="full-width">
        <RadioGroup
          aria-label="provide"
          name="provide"
          value={states.input_method}
          onChange={handleChangeStep2}
        >
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell
                  align="right"
                  style={{ width: "20%", padding: 4, borderBottom: "unset" }}
                >
                  <FormControlLabel
                    value="identifier_direct_enter"
                    control={<Radio />}
                    label="Enter a list"
                  />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ width: "80%", padding: 4, borderBottom: "unset" }}
                >
                  <TextField
                    label="Enter a list of ASINs or UPCs (up to 10,000 ASINs or UPCs)"
                    variant="outlined"
                    size="small"
                    className="full-width"
                    disabled={states.input_method !== "identifier_direct_enter"}
                    onChange={event => handleInputList(event)}
                    value={inputList}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="right"
                  style={{ width: "20%", padding: 4, borderBottom: "unset" }}
                >
                  <FormControlLabel
                    value="identifier_upload"
                    control={<Radio />}
                    label="Upload file (.csv/.xls)"
                  />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ width: "80%", padding: 4, borderBottom: "unset" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Dropzone
                      onDrop={onSelectFiles}
                      disabled={states.input_method !== "identifier_upload"}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) =>
                        !states.file_upload.name ? (
                          <div className={`${isDragActive ? "active" : ""}`} {...getRootProps()}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<CloudUploadIcon />}
                              style={{ marginRight: 10 }}
                              disabled={states.input_method !== "identifier_upload"}
                            >
                              Upload file
                            </Button>
                            <input {...getInputProps()} />
                          </div>
                        ) : (
                          <>
                            <Typography
                              sx={
                                states.input_method !== "identifier_upload" && {
                                  color: theme.palette.muted.main
                                }
                              }
                            >
                              {states.file_upload.name}
                            </Typography>
                            {states.input_method === "identifier_upload" && (
                              <IconButton
                                aria-label="remove-file-uploaded"
                                onClick={handleOnRemoveFileUploaded}
                                sx={{
                                  marginLeft: 8
                                }}
                              >
                                <ClearIcon
                                  sx={{
                                    color: "red"
                                  }}
                                />
                              </IconButton>
                            )}
                          </>
                        )
                      }
                    </Dropzone>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="right"
                  style={{ width: "20%", padding: 4, borderBottom: "unset" }}
                >
                  <FormControlLabel
                    value="identifier_google_sheet"
                    control={<Radio />}
                    label="Select from Google sheet"
                  />
                </TableCell>
                <TableCell
                  align="right"
                  style={{ width: "80%", padding: 4, borderBottom: "unset" }}
                >
                  <GoogleSheets
                    disabled={states.input_method !== "identifier_google_sheet"}
                    onData={handleSheetValues}
                  />

                  {/* <FormControl
                    fullWidth
                    disabled={states.input_method !== "identifier_google_sheet"}
                  >
                    <InputLabel id="select-gg-sheet-label">Select a Google sheet</InputLabel>
                    <Select
                      labelId="select-gg-sheet-label"
                      label="Select a Google sheet"
                      disabled={states.input_method !== "identifier_google_sheet"}
                    >
                      <MenuItem value={10}>my-first-sheet</MenuItem>
                      <MenuItem value={20}>my-second-sheet</MenuItem>
                      <MenuItem value={30} disabled>
                        <em style={{ fontWeight: "bold" }}>Add new Google sheet</em>
                      </MenuItem>
                    </Select>
                  </FormControl> */}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </RadioGroup>
      </div>
    </Stack>
  );

  const renderStep3 = () => (
    <Stack spacing={2}>
      {showStepTitles && (
        <Box>
          <Typography>
            <b>Step 3: Provide product’s identifiers</b>
          </Typography>
          <Typography>
            <i>
              Please select what data you want to view. Your selection will be kept for next visit
              and you can change anytime
            </i>
          </Typography>
        </Box>
      )}
      <Stack gap={6}>
        <FormControl component="fieldset" disabled={!enabledSelection}>
          <FormLabel component="legend" sx={{ color: "#000" }}>
            <b>General data</b>
            <FormControlLabel
              control={
                <Radio
                  checked={states.select_all.general}
                  onClick={e => handleSelectAll(e, "general")}
                  name="select_allGeneral"
                  size="small"
                  color="primary"
                />
              }
              label={!states.select_all.general ? "Select all" : "Deselect all"}
              sx={{ marginLeft: 5 }}
            />
          </FormLabel>
          <FormGroup className="list-checkbox">
            {listData.general.map(item => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={states.selections.general[item.name]}
                    onChange={e => handleChangeData(e, "general")}
                    name={item.name}
                    color="primary"
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" disabled={!enabledSelection}>
          <FormLabel component="legend" sx={{ color: "#000" }}>
            <b>Statistical data</b>
            <FormControlLabel
              control={
                <Radio
                  checked={states.select_all.statistical}
                  onClick={e => handleSelectAll(e, "statistical")}
                  name="select_allStatistical"
                  size="small"
                  color="primary"
                />
              }
              label={!states.select_all.statistical ? "Select all" : "Deselect all"}
              sx={{ marginLeft: 5 }}
            />
          </FormLabel>
          <FormGroup className="list-checkbox">
            {listData.statistical.map(item => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={states.selections.statistical[item.name]}
                    onChange={e => handleChangeData(e, "statistical")}
                    name={item.name}
                    color="primary"
                  />
                }
                label={item.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Stack>
    </Stack>
  );

  const renderStep4 = () => (
    <Stack spacing={2}>
      {showStepTitles && (
        <Typography>
          <b>Step 4: Click to view data</b>
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        disabled={!isEnableSubmit}
        onClick={() => handleOnSubmit()}
        style={{ maxWidth: 150 }}
      >
        Submit
      </Button>
    </Stack>
  );

  const renderAllSteps = () => (
    <>
      {renderStep1()}
      {renderStep2()}
      {renderStep3()}
      {renderStep4()}
    </>
  );

  return (
    <Stack spacing={2}>
      {isAllSteps ? (
        renderAllSteps()
      ) : (
        <Stack gap={5}>
          {renderStepNumber.includes(1) && renderStep1()}
          {renderStepNumber.includes(2) && renderStep2()}
          {renderStepNumber.includes(3) && renderStep3()}
          {renderStepNumber.includes(4) && renderStep4()}
        </Stack>
      )}
    </Stack>
  );
};

export default DataSettings;
