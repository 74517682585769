import { MenuProps } from "antd";

export const LabelMainMenuItems: any = {
  // product: {
  //   label: "Products",
  //   key: "products",
  //   children: [
  //     {
  //       label: "Product Search",
  //       key: "product-search"
  //     },
  //     {
  //       label: "Product Viewer",
  //       key: "product-viewer"
  //     },
  //     {
  //       label: "Product Tracking",
  //       key: "product-tracking"
  //     }
  //   ]
  // },
  // seller: {
  //   label: "Sellers",
  //   key: "sellers",
  //   children: [
  //     {
  //       label: "Seller Storefront",
  //       key: "seller-storefront"
  //     },
  //     {
  //       label: "Top Rated Sellers",
  //       key: "top-rated-sellers"
  //     }
  //   ]
  // },
  pricing: {
    label: "Pricing Plans",
    key: "pricing-plans"
  },
  history: {
    label: "History",
    key: "history"
  }
};

export const LinkMenuItems = [
  // {
  //   label: "Product Search",
  //   key: "product-search",
  //   parent: LabelMainMenuItems.product.key,
  //   link: "/"
  // },
  // {
  //   label: "Product Viewer",
  //   key: "product-viewer",
  //   parent: LabelMainMenuItems.product.key,
  //   link: "/az-api/product-viewer"
  // },
  // {
  //   label: "Product Tracking",
  //   key: "product-tracking",
  //   parent: LabelMainMenuItems.product.key,
  //   link: "/az-api/product-tracking"
  // },
  // {
  //   label: "Seller Storefront",
  //   key: "seller-storefront",
  //   parent: LabelMainMenuItems.seller.key,
  //   link: "/az-api/seller-storefront"
  // },
  // {
  //   label: "Top Rated Sellers",
  //   key: "top-rated-sellers",
  //   parent: LabelMainMenuItems.seller.key,
  //   link: "/az-api/top-rated-sellers"
  // },
  {
    label: "Pricing Plans",
    key: "pricing-plans",
    parent: LabelMainMenuItems.pricing.key,
    link: "/"
  },
  {
    label: "History",
    key: "history",
    parent: LabelMainMenuItems.history.key,
    link: "/user/team-history"
  }
];
