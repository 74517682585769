import axios from "axios";
import constants from "utils/constants";

const instance = axios.create({
  // baseURL: process.env.INTEGRATE_MASTERDB_API_URL || "http://localhost:3000",
  baseURL: "https://masterdb-staging.asinzen.com",
  headers: {
    "Content-Type": "application/json"
  }
});

instance.interceptors.request.use(async config => {
  const Authorization = await localStorage.getItem(constants.KEY_LOCAL_TOKEN);
  if (Authorization) {
    config.headers["portal-token"] = Authorization;
  }
  return config;
});

export default instance;
