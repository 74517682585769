import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Input,
  Tab,
  Paper,
  Tabs,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  Radio,
  Stack,
  InputLabel,
  Container,
  useTheme,
  Box,
  Modal,
  Alert,
  CircularProgress
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StorefrontIcon from "@mui/icons-material/Storefront";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { CONF, saveLocalStorage } from "utils";
import constants from "utils/constants";
import { ERROR_MESSAGE } from "constants/message";
import { useQuery } from "utils/route";
import { getSellerStoreFront, getSellerName } from "api/masterdb/seller";
import AZApiAppBar from "containers/app/asinzen-api/containers/AZApiAppBar";
import AgTable from "components/Table/AgTable";
import DataSettings from "components/Table/DataSettings";
import { columnHeaders } from "constants/asinzen-api/productViewer";
import { getConfiguredFields } from "utils/asinzen-api/table";
import SellerMetrics from "../Metrics";

const listData = {
  general: [
    { name: "info", label: "Info" },
    { name: "hazmat_aspects", label: "Hazmat Aspects" },
    { name: "hazmat_condition", label: "Hazmat Condition" },
    { name: "meltable", label: "Meltable" },
    { name: "azalert", label: "Azalert" },
    { name: "variations", label: "Variations" },
    { name: "variations_themes", label: "Variations Themes" }
  ],
  statistical: [
    { name: "root_rank", label: "Root Rank" },
    { name: "sales", label: "Sales" },
    { name: "amazon", label: "Amazon" },
    { name: "amazon_warehouse", label: "Amazon Warehouse" },
    { name: "offers_fba_new", label: "Offers FBA New" },
    { name: "offers_fbm_new", label: "Offers FBM New" },
    { name: "offers_new", label: "Offers New" },
    { name: "lowest_fba_new", label: "Lowest FBA New" },
    { name: "lowest_fbm_new", label: "Lowest FBM New" },
    { name: "lowest_new", label: "Lowest New" },
    { name: "buybox_new", label: "Buybox New" },
    { name: "offers_fba_used", label: "Offers FBA Used" },
    { name: "offers_fbm_used", label: "Offers FBM Used" },
    { name: "offers_used", label: "Offers Used" },
    { name: "lowest_fba_used", label: "Lowest FBA Used" },
    { name: "lowest_fbm_used", label: "lowest FBM Used" },
    { name: "lowest_used", label: "Lowest Used" },
    { name: "buybox_used", label: "Buybox Used" },
    { name: "lowest_used_like_new", label: "Lowest Used Like New" },
    { name: "lowest_used_very_good", label: "Lowest Used Very Good" },
    { name: "lowest_used_good", label: "Lowest Used Good" },
    { name: "lowest_used_acceptable", label: "Lowest Used Acceptable" },
    { name: "review", label: "Review" },
    { name: "rating", label: "Rating" }
  ]
};

const initialStates = {
  data_items: {},
  headers: [],
  value: 0,
  select_all: {
    general: false,
    statistical: false
  },
  selections: {
    general: {
      info: false,
      hazmat_aspects: false,
      hazmat_condition: false,
      meltable: false,
      azalert: false,
      variations: false,
      variations_themes: false
    },

    statistical: {
      root_rank: false,
      sales: false,
      amazon: false,
      amazon_warehouse: false,
      offers_fba_new: false,
      offers_fbm_new: false,
      offers_new: false,
      lowest_fba_new: false,
      lowest_fbm_new: false,
      lowest_new: false,
      buybox_new: false,
      offers_fba_used: false,
      offers_fbm_used: false,
      offers_used: false,
      lowest_fba_used: false,
      lowest_fbm_used: false,
      lowest_used: false,
      buybox_used: false,
      lowest_used_like_new: false,
      lowest_used_very_good: false,
      lowest_used_good: false,
      lowest_used_acceptable: false,
      review: false,
      rating: false
    }
  },
  sellerName: "",
  sellerId: "",
  marketplace: "US"
};

const metrics_rating = [
  { name: "Feedback count", value: "3,067,476" },
  { name: "Listing count", value: "88,004" },
  { name: "Tracked since", value: "Sep 25, 2017" }
];

const metrics_category = [
  { name: "CDs & Viny", listings: "73%", listingsAmazonOffer: "74%" },
  { name: "Movies & TV", listings: "29%", listingsAmazonOffer: "80%" },
  { name: "Book", listings: "0.22%", listingsAmazonOffer: "23.23%" }
];

const SellerStorefront = () => {
  const theme = useTheme();
  const [states, setStates] = useState(initialStates);
  const [openConfigureData, setOpenConfigureData] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [enableDataSetting, setEnableDataSetting] = useState(false)

  const history = useHistory();
  const query = useQuery();

  const sellerIdParam = query.get("sellerId");
  const tab: number = query.get("tab");

  const getSelectedFields = (data, parentField?) => {
    const fieldNames = [];
    const listKeys = Object.keys(data);

    listKeys.forEach(field => {
      if (!parentField) {
        fieldNames.push(field);
      } else {
        const fieldName = `${parentField}.${field}`;
        if (data[field] instanceof Array) {
          data[field].map(child => {
            if (typeof child !== "string") {
              const childFields = getSelectedFields(child, fieldName);
              fieldNames.push(childFields);
            }
          });
        } else if (data[field] instanceof Object) {
          const childFields = getSelectedFields(data[field], fieldName);
          fieldNames.push(childFields);
        }
      }
    });

    return fieldNames;
  };

  const getEnabledFields = fields => {
    return columnHeaders.map(col => !fields[col.field] && { ...col, enableValue: true });
  };

  const retrieveSellerStoreFront = async params => {
    try {
      const res = await getSellerStoreFront(params);
      return res.data;
    } catch (e) {
      console.error(e);
      setErrorMessage(ERROR_MESSAGE.SELLER_NO_DATA);
      return { [sellerIdParam ?? states.sellerId]: {} };
    }
  };

  const mappingDataItems = dataItems => {
    const result = [];
    const products = Object.values(dataItems)[0];
    if (!products || products.length === 0) {
      console.error(dataItems);
      setErrorMessage(ERROR_MESSAGE.SELLER_NO_DATA);
      return {};
    }

    const data = Object.keys(products).map(p => {
      return {
        ASIN: p,
        ...products[p]
      };
    });

    return data;
  };

  const handleChangeTabs = (event, newValue) => {
    setStates(preStates => ({ ...preStates, value: newValue }));
    history.push({
      pathname: "/az-api/seller-storefront",
      search: `?tab=${newValue}&sellerId=${states.sellerId}`
    });
  };

  const onChangeSellerId = event => {
    setStates(prevStates => ({ ...prevStates, sellerId: event.target.value }));
  };

  const handleOnGetSellerName = async () => {
    setIsLoadingTable(true)

    try {
      const sellerName = await getSellerName(states.sellerId)
      setStates((prevStates) => {
        setEnableDataSetting(true)
        setIsLoadingTable(false)
        return {
          ...prevStates,
          sellerName
        }
      })
    } catch (error) {
      setErrorMessage(() => {
        setIsLoadingTable(false)
        return ERROR_MESSAGE.SELLER_NAME
      });
    }
  };

  const lookupSellerName = () => {
    const { sellerId, marketplace } = states;
    if (!marketplace || !sellerId) return;
    const url = `https://${CONF[marketplace].domain}/sp?seller=${sellerId}`;
    window.open(url, "_blank");
  };

  const onChangeMarketplace = ({ marketplace }) => {
    setStates(prevStates => ({ ...prevStates, marketplace }));
  };

  const getDataIncluded = selections =>
    Object.entries(selections).reduce(
      (prevSelection, current) =>
        current[1] === true ? [...prevSelection, current[0]] : prevSelection,
      []
    );

  const getDataTable = async data => {
    const selectedFields = getConfiguredFields(data.selections);
    const headers = getEnabledFields(columnHeaders, selectedFields);

    const included_data_general = getDataIncluded(data.selections.general);
    const included_data_statistics = getDataIncluded(data.selections.statistical);

    const params = {
      marketplace: data.marketplace,
      day: -1,
      included_data_general,
      included_data_statistics,
      seller_ids: [data.sellerId],
      page: "1",
      per_page: 5
    };
    const result = await retrieveSellerStoreFront(params);

    const dataItems = mappingDataItems(result);

    return {
      data_items: dataItems,
      headers
    };
  };

  const handleOnSubmit = async data => {
    setIsLoadingTable(true);

    const storingData = {
      sellerId: data.sellerId,
      selections: data.selections,
      select_all: data.select_all,
      marketplace: data.marketplace
    };
    await saveLocalStorage(constants.SELLER_STOREFRONT_DATA, JSON.stringify(storingData));

    const dataTable = await getDataTable(data);

    setStates(prevStates => {
      setIsLoadingTable(false);
      return {
        ...data,
        ...dataTable
      };
    });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  useEffect(async () => {
    const initialData = JSON.parse(localStorage.getItem(constants.SELLER_STOREFRONT_DATA));
    if (initialData) {
      setIsLoadingTable(true);

      if (sellerIdParam) {
        initialData.sellerId = sellerIdParam;
      }

      const dataTable = await getDataTable(initialData);
      setStates(prevStates => {
        setIsLoadingTable(false);
        return {
          ...prevStates,
          ...initialData,
          ...dataTable
        };
      });
    }
  }, []);

  useEffect(() => {
    if (sellerIdParam) {
      states.sellerId = sellerIdParam;
    }
  }, [sellerIdParam]);

  const handleOpenConfigureData = () => {
    setOpenConfigureData(true);
  };

  const handleCloseConfigureData = () => {
    setOpenConfigureData(false);
  };

  const handleOnSubmitModalConfigureData = data => {
    handleOnSubmit(data);
    handleCloseConfigureData();
  };

  const handleOnResetDataSettings = () => {
    localStorage.removeItem(constants.SELLER_STOREFRONT_DATA);
    setStates(initialStates);
  };

  const isDoneLoading = useMemo(
    () => Object.keys(states.data_items).length > 0 && states.headers.length > 0,
    [states.data_items, states.headers]
  );

  const renderErrorMessage = () => {
    return <Alert severity="error">{errorMessage.message}</Alert>;
  };

  const renderTable = () =>
    isDoneLoading ? (
      <Paper variant="outlined" sx={{ borderTop: "unset", borderRadius: "0 0 4px 4px" }}>
        <AgTable
          dataItems={states.data_items}
          headers={states.headers}
          showAll
          onReset={handleOnResetDataSettings}
        />
      </Paper>
    ) : (
      <Paper
        variant="outlined"
        sx={{ px: 7, pb: 2, borderTop: "unset", borderRadius: "0 0 4px 4px" }}
      >
        <Stack maxWidth={900} marginBottom={3} spacing={3}>
          <Typography>
            <strong>
              Welcome to the Seller Storefront! Since this is your first time here, please select
              the data you want to see and click the “Submit” button. Make sure there’s a valid
              Seller ID inside the text-field
            </strong>
          </Typography>
          <Typography>
            Please select what data you want to see. Your selection will be kept for future visits
            and you can change it anytime
          </Typography>
        </Stack>
        <DataSettings
          renderStepNumber={[3, 4]}
          initialData={states}
          onSubmit={() => handleOnSubmit(states)}
          enabledSelection={enableDataSetting}
        />
      </Paper>
    );

  return (
    <Stack py={3} spacing={3}>
      <AZApiAppBar title="Seller Storefront" onChangeMarketplace={onChangeMarketplace} />
      <Box px={2}>
        <Paper
          variant="outlined"
          sx={{ py: 3, px: 4, borderBottom: "unset", borderRadius: "4px 4px 0 0" }}
        >
          <Stack justifyContent="space-between" spacing={3}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="end" spacing={3}>
                <FormControl variant="standard">
                  <InputLabel htmlFor="component-simple">Seller ID</InputLabel>
                  <Input
                    id="component-simple"
                    defaultValue="Composed TextField"
                    value={states.sellerId}
                    onChange={onChangeSellerId}
                  />
                </FormControl>
                <Typography variant="h6" style={{ textAlign: "left" }}>
                  Seller Name: {states.sellerName}
                </Typography>
                <div onClick={lookupSellerName} style={{ cursor: "pointer" }}>
                  <OpenInNewIcon />
                </div>
              </Stack>
              <Stack direction="row" justifyContent="end" spacing={5}>
                <Stack>{errorMessage && renderErrorMessage()}</Stack>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOnGetSellerName}
                  disabled={isLoadingTable}
                  style={{ minWidth: "unset" }}
                >
                  Get seller name
                </Button>
              </Stack>
            </Stack>
            <Tabs
              value={tab ?? states.value}
              onChange={handleChangeTabs}
              textColor="primary"
              indicatorColor="primary"
              centered
              sx={{ maxWidth: 300 }}
            >
              <Tab
                icon={<StorefrontIcon />}
                label="Storefront"
                value={0}
                sx={{ width: "50%" }}
                disabled={!isDoneLoading}
              />
              <Tab
                icon={<AssessmentOutlinedIcon />}
                label="Metrics"
                value={1}
                sx={{ width: "50%" }}
                disabled={!isDoneLoading}
              />
            </Tabs>
          </Stack>
        </Paper>
        <Paper elevation={0}>
          <TabPanel value={states.value} index={0} dir={theme.direction}>
            {isLoadingTable ? <CircularProgress /> : renderTable()}
          </TabPanel>
          <TabPanel value={states.value} index={1} dir={theme.direction}>
            <Paper
              variant="outlined"
              sx={{ px: 2, pb: 2, borderTop: "unset", borderRadius: "0 0 4px 4px" }}
            >
              <SellerMetrics sellerIds={[states.sellerId]} marketplace={states.marketplace} />
            </Paper>
          </TabPanel>
        </Paper>
      </Box>
      <Modal
        open={openConfigureData}
        onClose={handleCloseConfigureData}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Paper
          sx={{
            maxWidth: 800,
            maxHeight: 600,
            margin: "20px auto",
            padding: 3,
            overflowY: "scroll"
          }}
        >
          <DataSettings
            initialData={states}
            renderStepNumber={[3, 4]}
            onSubmit={handleOnSubmitModalConfigureData}
          />
        </Paper>
      </Modal>
    </Stack>
  );
};

export default SellerStorefront;
