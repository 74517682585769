export const PeriodTimeTitles = {
  30: "30 days",
  90: "90 days",
  180: "180 days",
  life_time: "Life Time"
};

export const TypeTitles = {
  drop: "drop",
  perc: "%",
  highest: "Highest",
  lowest: "Lowest",
  current: "Current"
};

export const BasicTermTitles = {
  "180d": PeriodTimeTitles[180],
  "30d": PeriodTimeTitles[30],
  "90d": PeriodTimeTitles[90],
  life_time: PeriodTimeTitles.life_time
};

export const TermTitles = {
  drop_180d: `${PeriodTimeTitles[180]} ${TypeTitles.drop}`,
  drop_180d_perc: `${PeriodTimeTitles[180]} ${TypeTitles.drop} ${TypeTitles.perc}`,
  drop_30d: `${PeriodTimeTitles[30]} ${TypeTitles.drop}`,
  drop_30d_perc: `${PeriodTimeTitles[30]} ${TypeTitles.drop} ${TypeTitles.perc}`,
  drop_90d: `${PeriodTimeTitles[90]} ${TypeTitles.drop}`,
  drop_90d_perc: `${PeriodTimeTitles[90]} ${TypeTitles.drop} ${TypeTitles.perc}`,
  highest: `${PeriodTimeTitles[90]} ${TypeTitles.highest}`,
  lowest: `${PeriodTimeTitles[90]} ${TypeTitles.lowest}`,
  current: `${PeriodTimeTitles[90]} ${TypeTitles.current}`
};

export const MARKETPLACES = ["US", "CA", "MX", "UK", "FR", "DE", "IT", "ES"];

export const MARKETPLACES_CONFIG = {
  UK: {
    merchantId: "A3P5ROKL5A1OLE",
    marketPlaceId: "A1F83G8C2ARO7P",
    formatCurrency: "GBP",
    sellerCentralUrl: "https://sellercentral.amazon.co.uk",
    iconMoney: "£",
    currency: "GBP",
    amzCurrency: "£",
    className: "gbp",
    marketplace: "UK",
    domain: "www.amazon.co.uk",
    domainSeller: "sellercentral.amazon.co.uk",
    unitWeight: "kgs",
    unitDimension: "cm",
    unitCubic: "",
    location: "WC2N 5DU",
    shortcut: "co.uk",
    domainCamel: "uk.camelcamelcamel.com",
    keepaId: 2,
    lang: "en_GB",
    crawlLang: "",
    enrollURL:
      "https://sellercentral.amazon.co.uk/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    urlLoginSellerCentral:
      "https://sellercentral.amazon.co.uk/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https://sellercentral.amazon.co.uk/home&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.assoc_handle=sc_uk_amazon_v2&openid.mode=checkid_setup&language=en_GB&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&pageId=sc_uk_amazon_v2&openid.ns=http://specs.openid.net/auth/2.0",
    listMerchant: {
      "Amazon Warehouse": "A2OAJ7377F756P",
      "Amazon Warehouse Deals": "A2OAJ7377F756P",
      "Amazon.co.uk": "A3P5ROKL5A1OLE",
      "Amazon US": "A2KVF7QXNCLV8H",
      Amazon: "A3P5ROKL5A1OLE"
    },
    locationStoreContext: "generic",
    iso2: "GB",
    sizeTiers: [
      "Small envelope",
      "Standard envelope",
      "Large envelope",
      "Extra large envelope",
      "Small parcel",
      "Standard parcel",
      "Small oversize",
      "Standard oversize",
      "Large oversize",
      "Special oversize"
    ],
    decimal: ".",
    locale: "en-gb",
    inEurope: true,
    defineCurrency: {
      decimal: ".",
      group: ","
    },
    amzUrl: "https://www.amazon.co.uk"
  },
  CA: {
    merchantId: "A3DWYIK6Y9EEQB",
    marketPlaceId: "A2EUQ1WTGCTBG2",
    formatCurrency: "CAD",
    sellerCentralUrl: "https://sellercentral.amazon.ca",
    iconMoney: "C$",
    amzCurrency: "CDN$",
    currency: "CAD",
    className: "cdn",
    marketplace: "CA",
    domain: "www.amazon.ca",
    domainSeller: "sellercentral.amazon.ca",
    unitWeight: "kgs",
    unitWeight1: "kg",
    unitDimension: "cm",
    unitCubic: "Meter",
    location: "M4B 1B3",
    locationName: "Toronto",
    shortcut: "ca",
    domainCamel: "ca.camelcamelcamel.com",
    keepaId: 6,
    lang: "en_CA",
    crawlLang: "",
    enrollURL:
      "https://sellercentral.amazon.ca/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    listMerchant: {
      "Amazon Warehouse": "A2L77EE7U53NWQ",
      "Amazon.ca": "A3DWYIK6Y9EEQB",
      Amazon: "A3DWYIK6Y9EEQB"
    },
    urlLoginSellerCentral:
      "https://sellercentral.amazon.ca/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https://sellercentral.amazon.ca/home&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.assoc_handle=sc_ca_amazon_v2&openid.mode=checkid_setup&language=en_CA&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&pageId=sc_ca_amazon_v2&openid.ns=http://specs.openid.net/auth/2.0",
    locationStoreContext: "beauty",
    iso2: "CA",
    sizeTiers: ["Envelope", "Standard", "Oversize", "Special oversize"],
    decimal: ".",
    locale: "en-ca",
    defineCurrency: {
      decimal: ".",
      group: ","
    },
    amzUrl: "https://www.amazon.ca"
  },
  DE: {
    merchantId: "A3JWKAKR8XB7XF",
    marketPlaceId: "A1PA6795UKMFR9",
    formatCurrency: "EUR",
    sellerCentralUrl: "https://sellercentral.amazon.de",
    amzUrl: "https://www.amazon.de",
    iconMoney: "€",
    amzCurrency: "EUR",
    currency: "EUR",
    className: "gbp",
    marketplace: "DE",
    domain: "www.amazon.de",
    domainSeller: "sellercentral.amazon.de",
    unitWeight: "kgs",
    unitDimension: "cm",
    unitCubic: "",
    location: "10115",
    shortcut: "de",
    domainCamel: "de.camelcamelcamel.com",
    keepaId: 3,
    lang: "de_DE",
    crawlLang: "en_GB",
    enrollURL:
      "https://sellercentral.amazon.de/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    urlLoginSellerCentral:
      "https://sellercentral.amazon.de/ap/signin?openid.pape.max_auth_age=0&openid.return_to=https://sellercentral.amazon.de/home&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.assoc_handle=sc_uk_amazon_v2&openid.mode=checkid_setup&language=en_GB&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&pageId=sc_uk_amazon_v2&openid.ns=http://specs.openid.net/auth/2.0",
    listMerchant: {
      "Amazon Warehouse": "A8KICS1PHF7ZO",
      "Amazon Warehouse Deals": "A2OAJ7377F756P",
      "Amazon.de": "A3JWKAKR8XB7XF",
      Amazon: "A3JWKAKR8XB7XF"
    },
    locationStoreContext: "generic",
    iso2: "DE",
    sizeTiers: [
      "Small envelope",
      "Standard envelope",
      "Large envelope",
      "Extra large envelope",
      "Small parcel",
      "Standard parcel",
      "Small oversize",
      "Standard oversize",
      "Large oversize",
      "Special oversize"
    ],
    decimal: ",",
    locale: "de-de",
    inEurope: true,
    defineCurrency: {
      decimal: ".",
      group: ","
    }
  },
  US: {
    merchantId: "ATVPDKIKX0DER",
    marketPlaceId: "ATVPDKIKX0DER",
    formatCurrency: "USD",
    sellerCentralUrl: "https://sellercentral.amazon.com",
    amzUrl: "https://www.amazon.com",
    iconMoney: "$",
    amzCurrency: "$",
    className: "dollar",
    marketplace: "US",
    domain: "www.amazon.com",
    domainSeller: "sellercentral.amazon.com",
    unitWeight: "lbs",
    unitWeight1: "lb",
    unitDimension: "in",
    unitCubic: "Feet",
    location: "90210",
    locationName: "California",
    shortcut: "com",
    domainCamel: "camelcamelcamel.com",
    keepaId: 1,
    lang: "en_US",
    crawlLang: "",
    enrollURL:
      "https://sellercentral.amazon.com/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    listMerchant: {
      "Amazon Warehouse": "A2L77EE7U53NWQ",
      "Amazon.com": "ATVPDKIKX0DER",
      Amazon: "ATVPDKIKX0DER"
    },
    urlLoginSellerCentral:
      "https://sellercentral.amazon.com/ap/signin?openid.pape.max_auth_age=8600&openid.return_to=https://sellercentral.amazon.com/home&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.assoc_handle=sc_na_amazon_v2&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&pageId=sc_na_amazon_v2&openid.ns=http://specs.openid.net/auth/2.0",
    locationStoreContext: "beauty",
    iso2: "US",
    sizeTiers: [
      "Small standard",
      "Large standard",
      "Small oversize",
      "Medium oversize",
      "Large oversize",
      "Special oversize"
    ],
    locale: "en-us",
    currency: "USD",
    weightUnit: "pounds",
    weightUnitString: "pounds",
    dimensionUnit: "inches",
    dimensionUnitString: "inches",
    defineCurrency: {
      decimal: ".",
      group: ","
    }
  },
  MX: {
    merchantId: "AVDBXBAVVSXLQ",
    marketPlaceId: "A1AM78C64UM0Y8",
    formatCurrency: "USD",
    sellerCentralUrl: "https://sellercentral.amazon.com.mx",
    amzUrl: "https://www.amazon.com.mx",
    iconMoney: "$",
    amzCurrency: "$",
    className: "dollar",
    marketplace: "MX",
    domain: "www.amazon.com.mx",
    domainSeller: "sellercentral.amazon.com.mx",
    unitWeight: "kgs",
    unitWeight1: "kg",
    unitDimension: "cm",
    unitCubic: "Meter",
    location: "01020",
    locationName: "Toronto",
    shortcut: "mx",
    domainCamel: "camelcamelcamel.com",
    keepaId: 11,
    lang: "es_MX",
    crawlLang: "",
    enrollURL:
      "https://sellercentral.amazon.com.mx/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    listMerchant: {
      "Amazon Warehouse": "A3BWELMBWVCY9T",
      "Amazon.com.mx": "AVDBXBAVVSXLQ",
      "Amazon México": "AVDBXBAVVSXLQ",
      "Amazon Estados Unidos": "A3BWELMBWVCY9T"
    },
    urlLoginSellerCentral:
      "https://sellercentral.amazon.com.mx/ap/signin?openid.pape.max_auth_age=8600&openid.return_to=https://sellercentral.amazon.com/home&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.assoc_handle=sc_na_amazon_v2&openid.mode=checkid_setup&language=en_US&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&pageId=sc_na_amazon_v2&openid.ns=http://specs.openid.net/auth/2.0",
    locationStoreContext: "beauty",
    iso2: "MX",
    sizeTiers: ["Envelope", "Standard", "Oversize"],
    locale: "es-mx",
    currency: "MXN",
    weightUnit: "kilograms",
    weightUnitString: "kilograms",
    dimensionUnit: "cm",
    dimensionUnitString: "centimetres",
    defineCurrency: {
      decimal: ".",
      group: ","
    }
  },
  FR: {
    merchantId: "A1X6FK5RDHNB96",
    marketPlaceId: "A13V1IB3VIYZZH",
    formatCurrency: "EUR",
    sellerCentralUrl: "https://sellercentral.amazon.fr",
    iconMoney: "€",
    amzCurrency: "EUR",
    currency: "EUR",
    className: "gbp",
    marketplace: "FR",
    domain: "www.amazon.fr",
    domainSeller: "sellercentral.amazon.fr",
    unitWeight: "kgs",
    unitDimension: "cm",
    unitCubic: "",
    location: "75016",
    shortcut: "fr",
    domainCamel: "fr.camelcamelcamel.com",
    keepaId: 4,
    lang: "fr_FR",
    crawlLang: "en_GB",
    enrollURL:
      "https://sellercentral.amazon.fr/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    urlLoginSellerCentral: "https://sellercentral.amazon.fr",
    listMerchant: {
      "Amazon Warehouse": "A2CVHYRTWLQO9T",
      "Amazon.fr": "A1X6FK5RDHNB96",
      Amazon: "A1X6FK5RDHNB96"
    },
    locationStoreContext: "generic",
    iso2: "FR",
    sizeTiers: [
      "Small envelope",
      "Standard envelope",
      "Large envelope",
      "Extra large envelope",
      "Small parcel",
      "Standard parcel",
      "Small oversize",
      "Standard oversize",
      "Large oversize",
      "Special oversize"
    ],
    decimal: ",",
    locale: "fr-fr",
    inEurope: true,
    defineLanguage: {
      in: "in",
      "See Top": "See Top",
      "See top": "See top",
      iconShart: "#"
    },
    defineCurrency: {
      decimal: ".",
      group: ","
    },
    amzUrl: "https://www.amazon.fr"
  },
  ES: {
    merchantId: "A1AT7YVPFBWXBL",
    marketPlaceId: "A1RKKUPIHCS9HS",
    formatCurrency: "EUR",
    sellerCentralUrl: "https://sellercentral.amazon.es",
    iconMoney: "€",
    amzCurrency: "EUR",
    currency: "EUR",
    className: "gbp",
    marketplace: "ES",
    domain: "www.amazon.es",
    domainSeller: "sellercentral.amazon.es",
    unitWeight: "kgs",
    unitDimension: "cm",
    unitCubic: "",
    location: "28080",
    shortcut: "es",
    domainCamel: "es.camelcamelcamel.com",
    keepaId: 9,
    lang: "es_ES",
    crawlLang: "en_GB",
    enrollURL:
      "https://sellercentral.amazon.es/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    urlLoginSellerCentral: "https://sellercentral.amazon.es",
    listMerchant: {
      "Amazon Warehouse": "A6T89FGPU3U0Q",
      "Amazon.es": "A1AT7YVPFBWXBL",
      Amazon: "A1AT7YVPFBWXBL"
    },
    locationStoreContext: "generic",
    iso2: "ES",
    sizeTiers: [
      "Small envelope",
      "Standard envelope",
      "Large envelope",
      "Extra large envelope",
      "Small parcel",
      "Standard parcel",
      "Small oversize",
      "Standard oversize",
      "Large oversize",
      "Special oversize"
    ],
    decimal: ",",
    locale: "ca-es",
    inEurope: true,
    defineLanguage: {
      in: "en",
      "See Top": "Ver el Top",
      "See top": "Ver el top",
      iconShart: "nº"
    },
    defineCurrency: {
      decimal: ".",
      group: ","
    },
    amzUrl: "https://www.amazon.es"
  },
  IT: {
    merchantId: "A11IL2PNWYJU7H",
    marketPlaceId: "APJ6JRA9NG5V4",
    formatCurrency: "EUR",
    sellerCentralUrl: "https://sellercentral.amazon.it",
    iconMoney: "€",
    amzCurrency: "EUR",
    currency: "EUR",
    className: "gbp",
    marketplace: "IT",
    domain: "www.amazon.it",
    domainSeller: "sellercentral.amazon.it",
    unitWeight: "kgs",
    unitDimension: "cm",
    unitCubic: "",
    location: "20019",
    shortcut: "it",
    domainCamel: "it.camelcamelcamel.com",
    keepaId: 8,
    lang: "it_IT",
    crawlLang: "en_GB",
    enrollURL:
      "https://sellercentral.amazon.it/gp/ssof/workflow/upload/upload-uno-offer-enrollment.html/ref=ag_xx_cont_201706140",
    appURL: "https://app.asinzen.com",
    urlLoginSellerCentral: "https://sellercentral.amazon.it",
    listMerchant: {
      "Amazon Warehouse": "A1HO9729ND375Y",
      "Amazon.it": "A11IL2PNWYJU7H",
      "Amazon UK": "A3R2BN8GAL312Z",
      Amazon: "A11IL2PNWYJU7H"
    },
    locationStoreContext: "generic",
    iso2: "IT",
    sizeTiers: [
      "Small envelope",
      "Standard envelope",
      "Large envelope",
      "Extra large envelope",
      "Small parcel",
      "Standard parcel",
      "Small oversize",
      "Standard oversize",
      "Large oversize",
      "Special oversize"
    ],
    decimal: ",",
    locale: "it-it",
    inEurope: true,
    defineLanguage: {
      in: "in",
      "See Top": "See Top",
      "See top": "See top",
      iconShart: "#"
    },
    defineCurrency: {
      decimal: ".",
      group: ","
    },
    amzUrl: "https://www.amazon.it"
  }
};
