import { styled } from "@mui/material/styles";
import { Grid, Paper, Tab, Button, Drawer } from "@mui/material";

export const GridSubAccountWrapper = styled(Grid)(({ theme }) => ({
  padding: "0 20px",
  "&>div": {
    marginBottom: 10
  },
  "& .pricing-plan": {
    marginBottom: 40
  }
}));

export const StoreDownloadWrapper = styled("div")(({ theme }) => ({
  padding: 10
}));

export const StoreDownloadIcon = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  "& > a": {
    maxWidth: 150,
    marginRight: 10,
    marginTop: 5,
    display: "inline-flex",
    "& > img": {
      maxWidth: "100%"
    }
  }
}));

export const GridPluginWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap"
}));

export const GridPluginItem = styled(Grid)(({ theme }) => ({
  width: 450,
  display: "flex",
  marginLeft: 10,
  marginBottom: 10,
  "&>div": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& > .MuiCardContent-root": {
      flex: 1
    }
  }
}));

export const AzApiWrapper = styled("div")(({ theme }) => ({
  padding: "0 20px",
  "&>div": {
    marginBottom: 10
  },
  "& .pricing-plan": {
    marginBottom: 40
  },
  "& .plan-content": {
    fontWeight: "bold",
    color: "#000 !important"
  }
}));

export const TabGroup = styled(Paper)(({ theme }) => ({
  "& .MuiTab-textColorPrimary": {
    color: "#293990",
    paddingBottom: 0,
    paddingTop: 8,
    minHeight: 0
  },
  "& .MuiButtonBase-root": {
    minWidth: 250,
    minHeight: 72,
    [theme.breakpoints.down(800)]: {
      minWidth: "unset",
      fontSize: 10
    }
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#293990"
  },
  "& .Mui-selected": {
    color: "#293990"
  },
  "& .MuiTab-labelIcon": {},
  marginBottom: 24
}));

export const PlansMenuButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up(800)]: {
    display: "none"
  },
  maxWidth: 180
}));

export const PlansMenuMobileDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up(800)]: {
    display: "none"
  },
  "& .MuiDrawer-paper": {
    width: 240,
    overflow: "hidden",
    position: "absolute",
    border: "none"
  }
}));

export const PlansMenuDrawer = styled(Drawer)(({ theme }) => ({
  width: 240,
  flexShrink: 0,
  position: "relative",
  [theme.breakpoints.down(800)]: {
    display: "none"
  },
  "& .MuiDrawer-paper": {
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    border: "none"
  }
}));

export const CustomPricingTable = styled("div")(({ theme }) => ({
  flexGrow: 1,
  "& .pricing-table": {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
      margin: "0 auto"
    },
    "&.plugin-list": {
      justifyContent: "space-evenly",
      "&>div": {
        marginRight: 30
      },
      "& .plan-price-amount": {
        fontSize: "1.25rem !important"
      },
      "& .table thead tr:first-of-type th": {
        fontSize: "0.875rem"
      }
    },
    "& .pricing-plan": {
      maxWidth: 450,
      "& .plan-footer > button": {
        fontSize: 14
      }
    },
    "& .pricing-plan.is-features": {
      [theme.breakpoints.down(1440)]: {
        display: "none"
      }
    },
    "& .plan-item-mobile": {
      [theme.breakpoints.up(1440)]: {
        display: "none"
      }
    }
  }
}));
