import { styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";

const UserStyles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    [theme.breakpoints.down(800)]: {
      margin: 0,
      padding: 10,
      width: "unset"
    }
  },
  screen0: {
    [theme.breakpoints.down(960)]: {
      margin: 0,
      width: "unset",
      "& >.MuiGrid-root": {
        width: "80%"
      }
    },
    [theme.breakpoints.down(800)]: {
      margin: 0,
      width: "80%"
    },
    [theme.breakpoints.down(600)]: {
      margin: 0,
      width: "100%",
      "& >.MuiGrid-root": {
        width: "100%"
      }
    }
  },
  paper: {
    height: 140,
    width: "auto"
  },
  paperSignUp: {
    width: "40%",
    height: 140,
    marginLeft: "30%"
  },
  control: {
    padding: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing()
  },
  emailIcon: {
    margin: -7
  },
  signUpP: {
    display: "block"
  },
  pSignUp: {
    float: "right"
  },
  pWidth: {
    width: "50%"
  },
  signUpIntro: {
    color: "#1e5a82",
    fontWeight: "bold",
    fontSize: 14
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 7,
    marginLeft: -15
  },
  buttonProgress1: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -35,
    marginLeft: -15
  },
  formChooseTypeLabel: {
    padding: "5px 0px 5px 10px",
    alignItems: "flex-start",
    "& .MuiRadio-root": {
      padding: "3px 9px"
    }
  },
  buttonBack: {
    marginTop: 24,
    marginRight: 10,
    width: 80,
    [theme.breakpoints.down(800)]: {
      marginRight: "16px !important"
    }
  },
  spStatus: {
    display: "block", // Fix IE 11 issue.
    width: 640,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(800)]: {
      width: "auto",
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    }
  },
  paper2: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`
  },
  status: {
    fontSize: 16,
    "& > span": {
      textTransform: "capitalize"
    }
  },
  authorizeBtn: {
    marginTop: `${theme.spacing(2)}`,
    textAlign: "center",
    "& button": {
      minWidth: 100,
      marginTop: 10
    }
  },
  smsContainer: {
    [theme.breakpoints.down(960)]: {
      margin: 0,
      width: "80%"
    },
    [theme.breakpoints.down(800)]: {
      margin: 0,
      width: "unset"
    }
  },
  verification: {
    [theme.breakpoints.down(800)]: {
      margin: 0,
      width: "unset"
    }
  },
  verificationButton: {
    [theme.breakpoints.down(800)]: {
      marginTop: "0 !important"
    }
  },
  steper: {
    [theme.breakpoints.down(960)]: {
      width: "80%"
    },
    [theme.breakpoints.down(600)]: {
      width: "100%"
    }
  }
});

const UlStyled = styled("ol", {
  name: "AzUserStyleOl",
  slot: "azuserstyleol"
})(() => ({
  "& > li": {
    margin: "6px 0",
    fontSize: "16px"
  },
  paddingLeft: "16px"
}));

const LinkStyled = styled("a", {
  name: "AzUserStyleA",
  slot: "azuserstylea"
})(() => ({
  textDecoration: "none",
  color: "royalblue"
}));

export { UserStyles, UlStyled, LinkStyled };
