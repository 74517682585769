import React from "react";
import { AuthWrapper } from "components";
import { createRedux } from "redux/store";
import { Provider } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import { GoogleOAuthProvider } from "@react-oauth/google";

const store = createRedux();

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

theme = createTheme(theme, {
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#fff !important",
        color: "#000 !important",
        border: "1px solid #ccc",
        fontSize: 13
      }
    },
    MuiListItem: {
      root: {
        "& > a": {
          textDecoration: "none",
          color: "rgba(0, 0, 0, 0.87)"
        }
      }
    },
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "#004085 !important",
          color: "white !important"
        }
      }
    }
  },
  palette: {
    primary: {
      // main: "#f6740e",
      main: "#004085"
    },
    secondary: {
      main: "#f50057"
    },
    colorLogoPrimary: {
      main: "#004085"
    },
    muted: {
      main: "#00000061"
    },
    colorLogoSecondary: "#1e1e1e",
    colors: {
      white: "#FFFFFF",
      black: "#000000",
      red: "#F44336",
      pink: "#E91E63",
      purple: "#9C27B0",
      deepPurple: "#673AB7",
      indigo: "#3F51B5",
      blue: "#2196F3",
      lightBlue: "#03A9F4",
      deepBlue: "#2962FF",
      cyan: "#00BCD4",
      teal: "#009688",
      green: "#4CAF50",
      lightGreen: "#8BC34A",
      lime: "#CDDC39",
      yellow: "#FFEB3B",
      amber: "#FFC107",
      orange: "#FF9800",
      deepOrange: "#FF5722",
      brown: "#795548",
      grey: "#9E9E9E",
      blueGrey: "#607D8B",
      blueviolet: "#8a2be2"
    }
  }
});

const App = () => {
  // window.Beacon("init", "aea3762b-1c0f-4985-9a41-fd779bed1e03");
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
            <React.Fragment>
              <CssBaseline />
              <AuthWrapper />
            </React.Fragment>
          </GoogleOAuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
