import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { withStyles } from "@mui/styles";

const wtStyle = () => ({
  customTooltip: {
    backgroundColor: "rgb(31, 89, 130)",
    color: "white",
    fontSize: 13,
    maxWidth: 350
  }
});

class CustomTooltip extends React.Component {
  render() {
    return (
      <Tooltip
        disabled={this.props.disabled || false}
        classes={{ tooltip: this.props.classes.customTooltip }}
        title={this.props.title}
        placement={this.props.placement || "top"}
      >
        {this.props.node}
      </Tooltip>
    );
  }
}

export default withStyles(wtStyle)(CustomTooltip);
