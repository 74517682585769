import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Stack, Box, Modal } from "@mui/material";

import ToolsTable from "components/Table/ToolsTable";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "static/aggrid_cstm.css";
import GoogleSheets from "../../containers/app/asinzen-api/containers/ProductViewer/GoogleSheets";

const AgTable = ({ dataItems, headers = null, showAll = false, onImport, onReset, tableProps }) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [openSelectSheetModal, setOpenSelectSheetModal] = useState(false);
  const [sheetData, setSheetData] = useState();

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      filter: true
    }),
    []
  );

  // Example of consuming Grid Event
  const cellClickedListener = useCallback(event => {
    console.log("cellClicked", event);
  }, []);

  const onGridReady = useCallback(params => {
    if (headers) {
      setColumnDefs(headers);
    }

    setRowData(dataItems);
  }, []);

  const onFirstDataRendered = useCallback(params => {
    !showAll && gridRef.current?.api.sizeColumnsToFit();
  }, []);

  const exportToCsv = useCallback(() => {
    gridRef.current?.api.exportDataAsCsv();
  }, []);

  const exportToExcel = useCallback(() => {
    gridRef.current?.api.exportDataAsExcel();
  }, []);

  const exportToGoogleSheet = useCallback(() => {
    gridRef.current?.api.exportDataAsExcel();
  }, []);

  const handleOnExport = type => {
    if (type === "csv") {
      return exportToCsv();
    }

    if (type === "excel") {
      return exportToExcel();
    }

    setOpenSelectSheetModal(true);
  };

  const handleOnImport = () => {
    onImport(true);
  };

  const handleOnRefresh = useCallback(() => {
    gridRef.current?.api.refreshCells({ force: true });
  }, []);

  const handleOnDelete = useCallback(() => {
    const selectedRowData = gridRef.current?.api.getSelectedRows();
    gridRef.current?.api.applyTransaction({ remove: selectedRowData });
  }, []);

  return (
    <>
      <Stack direction="column" width="100%" spacing={1}>
        <Stack direction="row" justifyContent="space-between">
          <ToolsTable
            onExport={handleOnExport}
            onImport={onImport && handleOnImport}
            onRefresh={handleOnRefresh}
            onDelete={handleOnDelete}
          />
          {onReset && (
            <Button
              variant="contained"
              color="error"
              sx={{ height: 30, fontSize: 11, alignSelf: "end", marginRight: 10 }}
              onClick={onReset}
            >
              Reset data settings
            </Button>
          )}
        </Stack>
        <Stack className="ag-theme-alpine" style={{ height: "calc(100vh - 244.5px)" }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            onGridReady={onGridReady}
            onCellClicked={cellClickedListener}
            onFirstDataRendered={onFirstDataRendered}
            enableCellChangeFlash
            suppressRowClickSelection
            {...tableProps}
          />
        </Stack>
      </Stack>
      <Modal open={openSelectSheetModal}>
        <GoogleSheets openExportModal tableHeaders={headers} tableData={dataItems} />
      </Modal>
    </>
  );
};

export default AgTable;
