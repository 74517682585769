export const generateChildHeadersWithIndex = (titles, parent) =>
  Object.keys(titles).map((t, index) => ({
    field: `${parent}.${index}`,
    headerName: titles[t],
    filter: true,
    enableValue: false
  }));

export const generateChildHeadersWithFieldName = (titles, parent) =>
  Object.keys(titles).map((t, index) => ({
    field: `${parent}.${t}`,
    headerName: titles[t],
    filter: true,
    enableValue: false
  }));

export const getConfiguredFields = (data, parentField?) => {
  const fieldNames = [];
  const listKeys = Object.keys(data);

  listKeys.forEach(field => {
    if (!parentField) {
      fieldNames.push(field);
    } else {
      const fieldName = `${parentField}.${field}`;
      if (data[field] instanceof Array) {
        data[field].map(child => {
          if (typeof child !== "string") {
            const childFields = getConfiguredFields(child, fieldName);
            fieldNames.push(childFields);
          }
        });
      } else if (data[field] instanceof Object) {
        const childFields = getConfiguredFields(data[field], fieldName);
        fieldNames.push(childFields);
      }
    }
  });
  return fieldNames;
};

export const getEnabledFields = (list, fields) => {
  return list.map(col => !fields[col.field] && { ...col, enableValue: true });
};
